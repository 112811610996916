import {APPLICATION_SET_PASSWORD, ApplicationActionType} from "../types/applicationTypes";

export interface ApplicationState {
  passwordSet: boolean;
  isPasswordSet: () => boolean;
};


const getDefaultPasswordSetState = (): boolean => {
  return !!localStorage.getItem('passwordSet');
};

const initialState: ApplicationState = {
  passwordSet: getDefaultPasswordSetState(),
  isPasswordSet() {
    return this.passwordSet;
  }
};

export default function(state: ApplicationState = initialState, action: ApplicationActionType) {
  switch (action.type) {
    case APPLICATION_SET_PASSWORD:
      localStorage.setItem('passwordSet', 'true');
      return {
        ...state,
        passwordSet: true,
      }
    default:
      return state;
  }
}
