import React from 'react';
import ContainerSetItem from '../ContainerSetItem';

import img1 from '../../assets/img/set3_1.png';
import img2 from '../../assets/img/set3_2.png';
import img3 from '../../assets/img/set3_3.png';
import img4 from '../../assets/img/set3_4.png';
import img5 from '../../assets/img/set3_5.png';
import img6 from '../../assets/img/set3_6.png';
import img7 from '../../assets/img/set3_7.png';
import img8 from '../../assets/img/set3_8.png';
import img9 from '../../assets/img/set3_9.png';
import img10 from '../../assets/img/set3_10.png';
import img11 from '../../assets/img/set3_11.png';
import img12 from '../../assets/img/set3_12.png';
import img13 from '../../assets/img/set3_13.png';

const productPositionByType: {[key: string]: any} = {
  shower_cubicle: {img:img1, text:'img1', price:1230, width:'114px', height:'180px'},
  shower_faucet: {img:img2, text:'img1', price:1230, width:'72px', height:'180px'},
  mirror: {img:img3, text:'img1', price:1230, width:'84px', height:'134px'},
  ceil_lighting: {img:img4, text:'img1', price:1230, width:'89px', height:'89px'},
  wall_lighting: {img:img5, text:'img1', price:1230, width:'90px', height:'75px'},
  washbasin: {img:img6, text:'img1', price:1230, width:'114px', height:'76px'},
  washbasin_faucet: {img:img7, text:'img1', price:1230, width:'73px', height:'73px'},
  shower_base: {img:img8, text:'img1', price:1230, width:'105px', height:'88px'},
  heater: {img:img9, text:'img1', price:1230, width:'85px', height:'191px'},
  toilet: {img:img10, text:'img1', price:1230, width:'94px', height:'109px'},
  washbasin_cabinet: {img:img11, text:'img1', price:1230, width:'128px', height:'128px'},
  bathtub_faucet: {img:img12, text:'img1', price:1230, width:'89px', height:'89px'},
  bathtub: {img:img13, text:'img1', price:1230, width:'233px', height:'100px'},
};

interface ShowResultBathtubShowerNewParams {
  products: Array<any>;
  toggleProductTypeToKeep?: (a: string) => any;
  refreshProduct?: (a: string) => any;
}
const ShowResultBathtubShowerNew = ({products, toggleProductTypeToKeep, refreshProduct}: ShowResultBathtubShowerNewParams) => {
  const notBigSurfaceProductsOrder: Array<string> = [
    'shower_cubicle', 'shower_faucet', 'mirror', 'ceil_lighting', 'wall_lighting', 'washbasin',
    'washbasin_faucet', 'shower_base', 'heater', 'toilet', 'washbasin_cabinet', 'bathtub_faucet', 'bathtub'
  ];

  const productsByType: {[key: string]: {}} = {};
  products.forEach(product => {
    productsByType[product.type] = product;
  });

  let notBigSurfaceProducts: Array<any> = notBigSurfaceProductsOrder.map(productType => {
    const product: any = productsByType[productType];
    if (!product) {
      return null;
    }
    return Object.assign({}, productPositionByType[productType], {
      img: product.image_url,
      price: product.price,
      text: `${product.manufacturer && product.manufacturer} ${product.series && product.series}`,
      type: productType,
      locked: product.locked,
    });
  });

  notBigSurfaceProducts = notBigSurfaceProducts.filter(product => !!product);

  return (
    <ContainerSetItem
      items={notBigSurfaceProducts}
      type="set3"
      toggleProductTypeToKeep={toggleProductTypeToKeep}
      refreshProduct={refreshProduct}
    />
  );
};

export default ShowResultBathtubShowerNew;
