import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  max-width: 1030px;
  margin: 20px auto;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  a {
    font-weight: 500;
    font-style: normal;
    color: rgb(0,0,0);
    font-size: 12px;
    letter-spacing: 0px;
    text-decoration: none;
    display: flex;
    align-items: center;

    span {
      display: flex;
    }

    span:first-child {
        margin-right: 4px;
    }
    
    &:hover {
      .next, .prev {
        color: #ff4e00;
      }
      .svg {
        svg {
          fill: #ff4e00;
        }
      }
    }    
  }

  .empty {
    width: 42px;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-style: normal;
  color: #565656;
  font-size: 12px;

  p {
    margin: 4px 0;
  }
`;

const ProgressBox = styled.div`
  width:288px;
  background: #000;
  height:1px;
`;

const ProgressBar = styled.div`
  width:${({value}) => value === 1 ? '144px' : '288px'};
  background:rgb(86,86,86);
  height:3px;
  transform: translateY(-1px);
  border-radius:5px;
  transition: width 1s ease-in-out;
`;

const Pagination = ({ page, pages, onClickNext, onClickPrevious, prevPath, nextPath, disabled = false}) => {
    // const [value,setPage] = useState(parseInt(location.pathname.replace('/','')));
    //
    // const pageValue = () => {
    //   if (value === 6) return 1;
    //   if (value === 7) return 2;
    //   if (value === 8) return 3;
    // }

    // if(!(page[2] < value)){
    const {t} = useTranslation();
    return (
      <Wrapper>
        {page > 1
        ?
        (<Link to={prevPath} onClick={onClickPrevious} aria-disabled={!!disabled}>
          <span className="svg">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
          </span>
          <span className="prev">{t('prev')}</span>
        </Link>)
        : (<p className="empty"></p>)}

          <ProgressContainer>
            <p>{page}/2</p>
            <ProgressBox>
              <ProgressBar value={page}/>
            </ProgressBox>
          </ProgressContainer>
        {/*(<p className="empty"></p> */}
        {!!nextPath
          ?
          (<Link to={nextPath} onClick={onClickNext} aria-disabled={!!disabled}>
              <span className="next">{t('next')}</span>
              <span className="svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path
                  d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
              </span>
            </Link>
          )
          : <p className="empty"></p>
        }
      </Wrapper>
    )
    // } else {
    //   return null;
    // }
  };

  export default Pagination;
