import {Dispatch} from "redux";
import request from "superagent";

import {
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  START_LOG_IN,
  USER_FETCH_CURRENT_USER_ERROR,
  USER_FETCH_CURRENT_USER_IN_PROGRESS,
  USER_FETCH_CURRENT_USER_SUCCESS,
  USER_LOGOUT_ERROR,
  USER_LOGOUT_IN_PROGRESS,
  USER_LOGOUT_SUCCESS,
  USER_SIGN_UP_ERROR,
  USER_SIGN_UP_SUCCESS,
  USER_START_SIGN_UP,
  UserActionType,
} from "../types/userTypes";
import {ApiUser} from "../types/apiType";


const logInProgress = (): UserActionType => {
  return {
    type: START_LOG_IN,
    payload: {

    }
  }
};

const logInSuccess = (user: ApiUser): UserActionType => {
  return {
    type: LOG_IN_SUCCESS,
    payload: user,
  }
};

const logInError = (status?: number, body?: any, error?: any): UserActionType => {
  return {
    type: LOG_IN_ERROR,
    payload: {
      status,
      body,
      error
    }
  }
};

const signUpInProgress = (): UserActionType => {
  return {
    type: USER_START_SIGN_UP,
    payload: {

    }
  }
};

const signUpSuccess = (user: any): UserActionType => {
  return {
    type: USER_SIGN_UP_SUCCESS,
    payload: user,
  }
};

const signUpError = (status?: number, body?: any, error?: any): UserActionType => {
  return {
    type: USER_SIGN_UP_ERROR,
    payload: {
      status,
      body,
      error
    }
  }
};

const userFetchCurrentUserInProgress = (): UserActionType => {
  return {
    type: USER_FETCH_CURRENT_USER_IN_PROGRESS,
    payload: {

    }
  }
};

const userFetchCurrentUserSuccess = (user: any): UserActionType => {
  return {
    type: USER_FETCH_CURRENT_USER_SUCCESS,
    payload: user,
  }
};

const userFetchCurrentUserError = (status?: number, body?: any, error?: any): UserActionType => {
  return {
    type: USER_FETCH_CURRENT_USER_ERROR,
    payload: {
      status,
      body,
      error
    }
  }
};

const userLogoutInProgress = (): UserActionType => {
  return {
    type: USER_LOGOUT_IN_PROGRESS,
    payload: {

    }
  }
};

const userLogoutSuccess = (): UserActionType => {
  return {
    type: USER_LOGOUT_SUCCESS,
    payload: {},
  }
};

const userLogoutError = (status?: number, body?: any, error?: any): UserActionType => {
  return {
    type: USER_LOGOUT_ERROR,
    payload: {}
  }
};

export const logIn = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(logInProgress());
      const response: any = await request
        .post(`/v1/login`)
        .send({email, password});

      if (response.statusCode !== 200) {
        return dispatch(logInError(response.statusCode, response.body));
      }
      dispatch(logInSuccess(response.body.data));
    } catch (error) {
      // @ts-ignore
      if (error.status) {
        // @ts-ignore
        return dispatch(logInError(error.status));
      }
      dispatch(logInError(undefined, undefined, error));
    }
  };
};

export const signUp = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(signUpInProgress());
      const response: any = await request
        .post('/v1/users/signup')
        .send({email, password});

      if (response.statusCode > 299) {
        return dispatch(signUpError(response.statusCode, response.body));
      }
      dispatch(signUpSuccess(response.body.data));
    } catch (error) {
      // @ts-ignore
      if (error.status) {
        // @ts-ignore
        return dispatch(signUpError(error.status));
      }
      dispatch(signUpError(undefined, undefined, error));
    }
  };
};

export const fetchCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userFetchCurrentUserInProgress());
      const response: any = await request
        .get('/v1/users/current')

      if (response.statusCode > 299) {
        return dispatch(userFetchCurrentUserError(response.statusCode, response.body));
      }
      dispatch(userFetchCurrentUserSuccess(response.body.data));
    } catch (error) {
      // @ts-ignore
      if (error.status) {
        // @ts-ignore
        return dispatch(userFetchCurrentUserError(error.status));
      }
      dispatch(userFetchCurrentUserError(undefined, undefined, error));
    }
  };
}

export const logout = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userLogoutInProgress());
      const response: any = await request
        .get('/v1/logout')

      if (response.statusCode > 299) {
        return dispatch(userLogoutError(response.statusCode, response.body));
      }
      dispatch(userLogoutSuccess());
    } catch (error) {
      // @ts-ignore
      if (error.status) {
        // @ts-ignore
        return dispatch(userLogoutError(error.status));
      }
      dispatch(userLogoutError(undefined, undefined, error));
    }
  };
}
