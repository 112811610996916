import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border: 1px solid #020202;
  display: inline-flex;
  padding: 10px;

  span {
    background-color: #f7f6f2;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input {
    border: 1px solid transparent;
    border-color: transparent;
    border-style: solid;
    text-align: center;
    width: 80px;
    box-shadow: none;
    appearance: none;
    outline: none;
    font-size: 16px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const InputQuantity = ({quantity, changeValue, incrementQuantity, decrementQuantity }) => {
  return (
    <Wrapper>
      <span onClick={decrementQuantity}>-</span>
      <input type="number" value={quantity} onChange={changeValue}/>
      <span onClick={incrementQuantity}>+</span>
    </Wrapper>
  );
};

export default InputQuantity;
