import React from 'react';
import Tooltip from '../Tooltip';
import styled from 'styled-components';
import { styled as styledMui} from '@mui/material/styles';

// import Tooltip s from '@material-ui/core/Tooltip';

const Wrapper = styledMui('div')(({theme}) => ({
  display: 'flex',
  'flex-direction': 'column',
  'min-width': '230px',
  'max-width': '340px',
  [theme.breakpoints.down('sm')]: {
    'max-width': '100%',
  },
  'position': 'relative',
  'text-align': 'center',
  'max-height': '408px',
  '.wall_2': {
    transform: 'translate(-70px, -160px)'
  },

  '.wall_3': {
    transform: 'translate(-10px, -260px)',
  }
}));

// const Wrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     min-width: 230px;
//     max-width: 340px;
//     position: relative;
//     text-align: center;
//     max-height: 408px;
//
//     .wall_2 {
//         transform: translate(-70px, -160px);
//         // z-index: 1;
//     }
//
//     .wall_3 {
//         transform: translate(-10px, -260px);
//         // z-index: 0;
//     }
// `;

/*
    ContainerSetWall - komponent ktory jest odpowiedzialny za wyswietlanie koloru sciany, przyjmuje argument items
    ktory jest deklarowany w setpage1, setpage2,setpage3
*/

interface ContainerSetWall {
  items: Array<any>,
  toggleProductTypeToKeep?: (productType: string) => any;
  refreshProduct?: (productType: string) => any;
}

const ContainerSetWall = ({ items, toggleProductTypeToKeep, refreshProduct }: ContainerSetWall) => {
  return (
    <Wrapper>
      {items.map((item,index) => (
        <div key={index} className={`wall_${index+1}`}>
          <Tooltip
            description={item.text}
            price={item.price}
            type={item.type}
            locked={item.locked}
            toggleProductTypeToKeep={toggleProductTypeToKeep}
            refreshProduct={refreshProduct}
          >
            <img width={item.width} height={item.height} src={item.img} alt={item.text}/>
          </Tooltip>
        </div>
      ))}
    </Wrapper>
  )
}

export default ContainerSetWall;
