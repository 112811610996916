import {Dispatch} from "redux";
import request from "superagent";
import {
  PROJECT_CLEAR_NEW_PROJECT_DATA,
  PROJECT_CREATE_NEW_ERROR,
  PROJECT_CREATE_NEW_START,
  PROJECT_CREATE_NEW_SUCCESS,
  PROJECT_GET_ALL_ERROR,
  PROJECT_GET_ALL_START,
  PROJECT_GET_ALL_SUCCESS,
  PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS,
  PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS,
  PROJECT_GET_ONE_ERROR,
  PROJECT_GET_ONE_START,
  PROJECT_GET_ONE_SUCCESS,
  PROJECT_UPDATE_MOODBOARD_NO_RESULT,
  PROJECT_UPDATE_ONE_IN_PROGRESS,
  ProjectActionType
} from "../types/projectTypes";
import {MoodboardQuery} from "../../components/ChooseYourStyle";

const createNewStart = (): ProjectActionType => {
  return {
    type: PROJECT_CREATE_NEW_START,
    payload: {

    }
  }
};

const createNewDone = (body: any): ProjectActionType => {
  return {
    type: PROJECT_CREATE_NEW_SUCCESS,
    payload: body,
  }
};

const getOneStart = (id: string): ProjectActionType => {
  return {
    type: PROJECT_GET_ONE_START,
    payload: {
      id,
    }
  }
};

const getOneSuccess = (body: any): ProjectActionType => {
  return {
    type: PROJECT_GET_ONE_SUCCESS,
    payload: body,
  }
};

interface CreateNewError{
  status?: number;
  body?: any;
  error?: Error;
}

interface GetOneError extends CreateNewError{
  id: string;
}

const createNewError = ({status, body, error}: CreateNewError): ProjectActionType => {
  return {
    type: PROJECT_CREATE_NEW_ERROR,
    payload: {
      status,
      body,
      error,
    },
  }
};


const getOneError = ({ status, body, error, id }: GetOneError): ProjectActionType => {
  return {
    type: PROJECT_GET_ONE_ERROR,
    payload: {
      id,
      status,
      body,
      error,
    },
  }
};

const updateOneInProgress = (id: string): ProjectActionType => {
  return {
    type: PROJECT_UPDATE_ONE_IN_PROGRESS,
    payload: {
      id,
    }
  }
}

const getBathroomVisualizationsInProgress = (): ProjectActionType => {
  return {
    type: PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS,
    payload: {

    }
  }
}

const getBathroomVisualizationsSuccess = (body: GetBathroomVisualizationsResponse): ProjectActionType => {
  return {
    type: PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS,
    payload: {
      bathroomVisualizations: body.data,
    }
  }
}

const updateMoodboardNoResult = (id: string): ProjectActionType => {
  return {
    type: PROJECT_UPDATE_MOODBOARD_NO_RESULT,
    payload: {
      id,
    }
  }
}

export const clearNewProjectData = (): ProjectActionType => {
  return {
    type: PROJECT_CLEAR_NEW_PROJECT_DATA,
    payload: {}
  }
}

// const
const getProjectsStart = (): ProjectActionType => ({
  type: PROJECT_GET_ALL_START,
  payload: {},
});

const getProjectsSuccess = (body: any): ProjectActionType => ({
  type: PROJECT_GET_ALL_SUCCESS,
  payload: body,
});

const getProjectsError = (payload: CreateNewError): ProjectActionType => ({
  type: PROJECT_GET_ALL_ERROR,
  payload,
});


export const createProject = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(createNewStart());
      const response = await request
        .post('/v1/projects')
        .send({
          name: 'new bathroom',
          moodboard_params: params,
        });
      if (response.status !== 200) {
        return dispatch(createNewError(response));
      }
      return dispatch(createNewDone(response.body));
    } catch (error) {
      // @ts-ignore
      return dispatch(createNewError({error}));
    }
  };
};

export const createProjectUsingImage = (params: MoodboardQuery) => {
  const paramsInApiFormat = {
    bathroom_type: params.bathroomType,
    style_light_dark :  params.styleLightDark,
    style_cold_warm: params.styleColdWarm,
    bathtub: params.bathtub,
    shower: params.shower,
    style_uniform: params.styleUniform,
  };

  return async (dispatch: Dispatch) => {
    try {
      dispatch(createNewStart());
      const response = await request
        .post('/v1/projects')
        .send({
          name: 'new bathroom',
          moodboard_query: paramsInApiFormat,
        });
      if (response.status !== 200) {
        return dispatch(createNewError(response));
      }
      return dispatch(createNewDone(response.body));
    } catch (error) {
      // @ts-ignore
      return dispatch(createNewError({error}));
    }
  };
};

export const getProject = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getOneStart(id));
      const response = await request
        .get(`/v1/projects/${id}`);
      if (response.status !== 200) {
        return dispatch(getOneError({
          id,
          ...response,
        }))
      }
      return dispatch(getOneSuccess(response.body));
    } catch (error) {
      return dispatch(getOneError({
        id,
        // @ts-ignore
        error,
      }));
    }
  };
};


export const getProjects = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getProjectsStart());
      const response = await request
        .get('/v1/projects');
      if (response.status !== 200) {
        return dispatch(getProjectsError(response));
      }
      return dispatch(getProjectsSuccess(response.body));
    } catch (error) {
      // @ts-ignore
      return  dispatch(getProjectsError({error}));
    }
  }
}

export const updateMoodboard = (projectId: string, productTypesToKepp: string[]) => {
  return async (dispatch: Dispatch) => {
    try {
      // dispatch(getOneStart(id));
      dispatch(updateOneInProgress(projectId));
      const response = await request
        .post(`/v1/projects/${projectId}/moodboards/change`)
        .send({
          product_types: productTypesToKepp,
        });

      if (response.status == 200) {
        return getProject(projectId)(dispatch);
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// {
//   "style_light_dark": 3,
//   "style_cold_warm": 3,
//   "style_plain_contrast": 4,
//   "style_modern": 3,
//   "style_eclectic": 3,
//   "style_functional": 3,
//   "style_original": 3,
//   "budget": "low",
//   "size": "small",
//   "shower": true,
//   "bathtub": false
// }
export const generateNewMoodboard = (projectId: string, params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      // dispatch(getOneStart(id));
      dispatch(updateOneInProgress(projectId));
      const response = await request
        .post(`/v1/projects/${projectId}/moodboards`)
        .send(params);

      if (response.status == 200 && response.body.data.success) {
        return getProject(projectId)(dispatch);
      } else {
        return dispatch(updateMoodboardNoResult(projectId));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export const getBathroomVisualizations = () => {
  return async (dispatch: Dispatch) => {
    try {
      // dispatch(getOneStart(id));
      dispatch(getBathroomVisualizationsInProgress());
      const response = await request
        .get(`/v1/visualization_images/for_selection`);

      console.log('Response', response);
      if (response.status === 200) {
        return dispatch(getBathroomVisualizationsSuccess(response.body));
      }
      // if (response.status == 200 && response.body.data.success) {
      //   return getProject(projectId)(dispatch);
      // } else {
      //   return dispatch(updateMoodboardNoResult(projectId));
      // }
    } catch (error) {
      console.log(error);
    }
  };
}
// export const clearCreatedProject = () => {
//   return (dispatch: Dispatch) => {
//
//   }
// }
