import React from 'react';
import styled from 'styled-components';

const WrapperTitle = styled.h2`
    width: 100%;
    font-weight: 700;
    color : ${({ type }) => type === 'big' ? 'rgb(28,27,109)' : '#000'};
    font-size: ${({ type }) => type === 'big' ? '26px' : '14px'};
    text-align: center;
`;

const Title = ({ children, type }) => (<WrapperTitle type={type}>{children}</WrapperTitle>);

export default Title;
