import React, {Component} from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";

import {CalculatorProduct} from "../redux/types/calculatorTypes";
import {addProductToCalculator, initializeCalculator} from "../redux/actions/calculatorActions";

import PriceSetBox from './PriceSetBox';
import {WithTranslation, withTranslation} from "react-i18next";


// export default function Calculator() {
//   return (
//     <div>
//       <h3>Show Results</h3>
//     </div>
//   )
// }
// interface CalculatorProduct {
//   type: string,
//   price: number,
//   id: number
//   name: string
// }

interface CalculatorState {
  newProductName: string,
  newProductQuantity: string,
  newProductPrice: string,
}

interface CalculatorProps extends WithTranslation {
  products: Array<DbProduct>
  initializeCalculator: ({products, bathroomSize}: {products: Array<DbProduct>, bathroomSize: string}) => any,
  onlyProductsPrice: boolean,
  addProductToCalculator: (params: Partial<CalculatorProduct>) => any,
  calculatorItems: Array<CalculatorProduct>
  bathroomSize: string,
  totalPrice: number,
  productsPrice: number,
}


export class Calculator extends Component<CalculatorProps, CalculatorState> {
  constructor(props: any = {params: {}}) {
    super(props);
    // set default state
    this.state = this.getDefaultSate();

    this.getChangeHandler = this.getChangeHandler.bind(this);
    this.addCustomProduct = this.addCustomProduct.bind(this);
  }

  static defaultProps = {
    onlyProductsPrice: false,
  }

  onClickReload() {
    // console.log('On Click Reload!!');
    // return this.props.fetchResults(this.props.params);
  }

  getChangeHandler(key: keyof CalculatorState, { checkbox, booleanValue, numberValue }: {checkbox?: boolean, booleanValue?: boolean, numberValue?: boolean} = {}): (change: any) => void {
    return (event) => {
      // console.log(key, event.target.value)
      // const nextState: {[key: string]: any} = {};
      const nextState: CalculatorState = {...this.state};
      nextState[key] = event.target.value;
      this.setState(nextState);
    }
  }

  componentDidMount() {
    this.props.initializeCalculator({ products: this.props.products, bathroomSize: this.props.bathroomSize });
  }

  getDefaultSate(): CalculatorState {
    return {
      newProductName: '',
      newProductPrice: '0',
      newProductQuantity: '1',
    }
  }

  getNewItemTotalPrice() {
    if (this.state.newProductQuantity && this.state.newProductPrice) {
      return parseInt(this.state.newProductQuantity) * parseInt(this.state.newProductPrice);
    }
    return 0;
  }

  addCustomProduct() {
    this.props.addProductToCalculator({
      price: parseInt(this.state.newProductPrice) || 0,
      name: this.state.newProductName,
      quantity: parseInt(this.state.newProductQuantity) || 1,
    })
    this.setState(this.getDefaultSate());
  }

  render() {
    const {t} = this.props;
    const calculatorLabel = this.props.onlyProductsPrice ? 'products_set_price' : 'set_price';
    return  (
      <PriceSetBox text={t(calculatorLabel)} price={this.props.onlyProductsPrice ? this.props.productsPrice : this.props.totalPrice}/>
    );
  }
}

function mapStateToProps(state: {calculator: any}) {
  const { calculator } = state;
  return {
    calculatorItems: calculator.getCalculatorItems(),
    totalPrice: calculator.getTotalPrice(),
    productsPrice: calculator.getProductsPrice(),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initializeCalculator: ({products, bathroomSize}: {products: Array<DbProduct>, bathroomSize: string}) => dispatch(initializeCalculator({products, bathroomSize})),
  addProductToCalculator: (params: Pick<CalculatorProduct, 'price' | 'quantity' | 'name'>) => dispatch(addProductToCalculator(params)),
  // fetchResults: (params: object) => dispatch<any>(fetchResults(params)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Calculator));
