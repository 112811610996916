export const FETCH_SAVED_RESULT_IN_PROGRESS = "FETCH_SAVED_RESULT_IN_PROGRESS";
export const FETCH_SAVED_RESULT_DONE = "FETCH_SAVED_RESULT_DONE";
export const TOGGLE_PRODUCT_TYPES_TO_KEEP = 'TOGGLE_PRODUCT_TYPES_TO_KEEP';
export const CHANGE_PRODUCT_TYPES_TO_KEEP = 'CHANGE_PRODUCT_TYPES_TO_KEEP';

interface Action { }
interface SerchResultDefaultAction extends Action {
  type: typeof FETCH_SAVED_RESULT_IN_PROGRESS,
}

export interface SearchResultDoneAction extends Action {
  type: typeof FETCH_SAVED_RESULT_DONE,
  payload: {
    id: number | string,
    params: object,
    products: Array<object>,
    visualization_image_url: string,
  }
}

export interface ToggleProductTypeToKeepAction extends Action {
  type: typeof TOGGLE_PRODUCT_TYPES_TO_KEEP,
  payload: {
    productType: string,
  }
}

export interface ChangeProductTypesToKeepAction extends Action{
  type: typeof CHANGE_PRODUCT_TYPES_TO_KEEP,
  payload: {
    productTypes: Array<string>
  }
}

export type SearchActionType = SerchResultDefaultAction
  | SearchResultDoneAction
  | ToggleProductTypeToKeepAction
  | ChangeProductTypesToKeepAction;
