import {
  ADD_PRODUCT_TO_CALCULATOR,
  CALCULATOR_TOGGLE_PRODUCT_CHECK,
  CalculatorActionType,
  CalculatorProduct,
  CHANGE_PRODUCT_QUANTITY,
  INITIALIZE_CALCULATOR,
  UPDATE_PRODUCTS_IN_CALCULATOR,
} from "../types/calculatorTypes";

const defaultProductQuantity: {[type: string]: {[fit: string]: number}} = require('./../../config/defaultQuantity.json');
const lightingTypeQuantity: {[type: string]: {[fit: string]: number}} = require('./../../config/defaultQuantity.json');
const constantProductPrice: {[type: string]: {[fit: string]: number}} = require('./../../config/constantProductPrice.json');

const DEFAULT_CONSTANT_PRODUCTS: Array<{type: string, checked: boolean}> = [
  { type: 'labor', checked: true },
  { type: 'materials', checked: true },
  { type: 'container', checked: true },
  { type: 'door', checked: false },
  { type: 'electrician', checked: false },
  { type: 'plumber', checked: false },
];

const DEFAULT_BATHROOM_SIZE = 3;
function getBathroomFitFromSize(size: number): string {
  if (size <= 4) {
    return 'small';
  } else if (size <= 7) {
    return 'medium';
  }
  return 'big;'
};

export interface State {
  dbProducts: Array<DbProduct>,
  bathroomSizeInM2: number,
  products: Array<CalculatorProduct>,
  constantProducts: Array<CalculatorProduct>,
  userAddedProducts: Array<CalculatorProduct>,
  getCalculatorItems: (notEditable?: boolean) => Array<CalculatorProduct>
  bathroomSize: string,
  getTotalPrice: (notEditable?: boolean) => number
  getProductsPrice: () => number
};

const initialState = {
  dbProducts: [],
  bathroomSizeInM2: 3,
  products: [],
  constantProducts: [],
  userAddedProducts: [],
  getCalculatorItems(notEditable=false) {
    if (notEditable) {
      return this.products;
    }
    return this.products.concat(this.constantProducts, this.userAddedProducts);
    // return this.dbProducts.map(product => {
    //   return Object.assign({}, product, {quantity: 1})
    // });
  },

  getTotalPrice(notEditable = false) {
    let totalPrice = 0;
    this.getCalculatorItems(notEditable).filter((product: CalculatorProduct) => product.checked).forEach((item: CalculatorProduct) => {
      if (item.quantity)
        totalPrice += item.quantity * item.price;
    });
    return totalPrice;
    // return this.getCalculatorItems().reduce((prev: number, item: CalculatorProduct): number => (prev + item.price * item.quantity), 0);
  },
  bathroomSize: '',
  getProductsPrice() {
    let totalPrice = 0;
    this.products.forEach((item: CalculatorProduct) => {
      if (item.quantity)
        totalPrice += item.quantity * item.price;
    });
    return totalPrice;
  }
  // searchResultProductsById: {},
  // searchResultParamsById: {},
  // inProgress: false,
};


function coerceDbProductToCalculatorProduct(dbProduct: DbProduct): CalculatorProduct {
  return {
    type: dbProduct.type,
    price: dbProduct.price,
    id: dbProduct.id,
    name: dbProduct.name,
    quantity: 1,
    calculator_item_type: 'db',
    checked: true,
  }
}

function isLighting(productType: string): boolean {
  return ['ceil_lighting', 'wall_lighting'].includes(productType);
}

function getDefaultProductQuantity({productType, bathroomSize, lightingType}: { productType: string, bathroomSize: string, lightingType?: string }): number {
  if (lightingType && isLighting(productType)) {
    return lightingTypeQuantity[lightingType] && lightingTypeQuantity[lightingType][bathroomSize] ? lightingTypeQuantity[lightingType][bathroomSize] : 1;
  } else if (defaultProductQuantity[productType] && defaultProductQuantity[productType][bathroomSize]) {
    return defaultProductQuantity[productType][bathroomSize];
  }
  return 1;
}

function getConstantItemPrice({productType, bathroomSize}: { productType: string, bathroomSize: string }) {
  return constantProductPrice[productType] && constantProductPrice[productType][bathroomSize] ? constantProductPrice[productType][bathroomSize] : 1;
}

function getDefaultConstantProduct({bathroomSize}: {bathroomSize: string}): Array<CalculatorProduct> {
  return DEFAULT_CONSTANT_PRODUCTS.map(({type, checked}) => (
    {
      type,
      checked,
      price: getConstantItemPrice({
        bathroomSize,
        productType: type,
      }),
      id: `custom_${Math.random()}`,
      name: '',
      quantity: 1,
      calculator_item_type: 'constant',
    }
  ));
}

function toggleProductCheck({id}: {id: number | string}, state: State): State {
  const nextState = {...state};
  const calculatorProducts = nextState.getCalculatorItems();
  const calculatorProduct = calculatorProducts.find(item => item.id === id);
  console.log(calculatorProduct);
  if (calculatorProduct) {
    calculatorProduct.checked = !calculatorProduct.checked;
  }
  return nextState;
}

export default function(state: State = initialState, action: CalculatorActionType) {
  const nextState = {...state};
  switch (action.type) {
    case INITIALIZE_CALCULATOR:
      if(action.payload.bathroomSizeInM2) {
        nextState.bathroomSizeInM2 = action.payload.bathroomSizeInM2;
      }
      nextState.bathroomSize = getBathroomFitFromSize(nextState.bathroomSizeInM2);
      nextState.dbProducts = action.payload.products;
      nextState.products = action.payload.products.map(coerceDbProductToCalculatorProduct);
      nextState.products = nextState.products.map(product => (
        {
          ...product,
          quantity: getDefaultProductQuantity({
            productType: product.type,
            bathroomSize: action.payload.bathroomSize,
            lightingType: product.lighting_type,
          }),
        }
      ));
      if (action.payload.editable) {
        nextState.constantProducts = getDefaultConstantProduct({
          bathroomSize: action.payload.bathroomSize,
        });
      }
      return nextState;
    case ADD_PRODUCT_TO_CALCULATOR:
      nextState.userAddedProducts.push({
        calculator_item_type: 'user',
        type: 'user',
        id: `custom_${Math.random()}`,
        ...action.payload,
        checked: true,
      });

      return nextState;
    case CHANGE_PRODUCT_QUANTITY:
      const {id, quantity } = action.payload;
      const items = nextState.getCalculatorItems();
      const item = items.find(item => item.id === id);
      if (item) {
        item.quantity = quantity;
      }
      return nextState;
    case CALCULATOR_TOGGLE_PRODUCT_CHECK:
      return toggleProductCheck(action.payload, state);
    case UPDATE_PRODUCTS_IN_CALCULATOR:
      if (action.payload.bathroomSizeInM2) {
        nextState.bathroomSizeInM2 = action.payload.bathroomSizeInM2;
        nextState.bathroomSize = getBathroomFitFromSize(nextState.bathroomSizeInM2);
      }
      nextState.dbProducts = action.payload.products;
      nextState.products = action.payload.products.map(coerceDbProductToCalculatorProduct);
      nextState.products = nextState.products.map(product => (
        {
          ...product,
          quantity: getDefaultProductQuantity({
            productType: product.type,
            bathroomSize: nextState.bathroomSize,
            lightingType: product.lighting_type,
          }),
        }
      ));
      return nextState;
    default:
      return nextState;
  }
}
