export const GO_TO_NEXT_STEP = "USER_FORM_ACTION.GO_TO_NEXT_STEP";
export const GO_TO_PREV_STEP = "USER_FORM_ACTION.GO_TO_PREV_STEP";
export const USER_FORM_CLEAR = "USER_FORM_CLEAR";

export interface UserForm {
  styleLightDark: number,
  styleColdWarm: number,
  stylePlainContrast: number,
  styleModern: number,
  styleEclectic: number,
  styleFunctional: number,
  styleOriginal: number,
  budget: string,
  size: string,
  shower: boolean,
  bathtub: boolean,
  boiler: boolean,
  geyser: boolean,
  window: boolean,
  washingMachine: boolean,
  attic: boolean,
  earlyBird: number,
  danceKing: number,
  swissPrecision: number,
  romanticArtist: number,
  proudAsPeacock: number,
  oasisOfCalm: number,
  bathtubShowerSet: boolean,
}

export interface UserFormApi {
  style_light_dark: number,
  style_cold_warm: number,
  style_plain_contrast: number,
  style_modern: number,
  style_eclectic: number,
  style_functional: number,
  style_original: number,
  budget: string,
  size: string,
  shower: boolean,
  bathtub: boolean,
  boiler: boolean,
  geyser: boolean,
  window: boolean,
  washing_machine: boolean,
  attic: boolean,
  early_bird: number,
  dance_king: number,
  swiss_precision: number,
  romantic_artist: number,
  proud_as_peacock: number,
  oasis_of_calm: number,
}

interface Action { }
// interface SerchResultDefaultAction extends Action {
//   type: typeof FETCH_SAVED_RESULT_IN_PROGRESS,
// }

export interface GoToNextStepAction extends Action {
  type: typeof GO_TO_NEXT_STEP,
  payload: {
    userForm: UserForm
  }
}

export interface GoToPrevStepAction extends Action {
  type: typeof GO_TO_PREV_STEP,
  payload: {
    userForm: UserForm
  }
}

export interface UserFormClear extends Action{
  type: typeof USER_FORM_CLEAR,
  payload: any
}

export type UserFormActionType = GoToNextStepAction | GoToPrevStepAction | UserFormClear;
