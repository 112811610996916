// import {ApiUser} from "./apiType";

export const APPLICATION_SET_PASSWORD = 'APPLICATION_SET_PASSWORD';

interface Action { }

export interface ApplicationSetPasswordAction extends Action {
  type: typeof APPLICATION_SET_PASSWORD,
  payload: {
  }
}
//
export type ApplicationActionType = ApplicationSetPasswordAction;
