import React, {Component} from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {withRouter} from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';

import Grid from '@mui/material/Grid';

import {fetchSavedResult, toggleProductTypeToKeep } from "../../redux/actions/savedResultActions";

import {WithTranslation, withTranslation} from "react-i18next";
import { withStyles } from '@mui/styles';

import {ProjectState} from "../../redux/reducers/projectReducer";
import {clearNewProjectData, generateNewMoodboard, getProject, updateMoodboard} from "../../redux/actions/projectActions";
import {setPassword} from "../../redux/actions/applicationAction";
import logo from "../../assets/img/logo.png";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import ShoppingList from "../ShoppingList";

const styles = {
  backdrop: {
    zIndex: 9999999,
    color: '#fff',
  },
};

interface ShowResultState {
  drawerIsOpen: boolean;
  styleLightDark: number;
  styleColdWarm: number;
  styleUniformContrast: number;
  priceRange: Array<number>;
  sizeRange: Array<number>;
  bathroomSize: number;
  bathtub: boolean;
  shower: boolean;
  functionality: boolean;
  design: boolean;
  modern: boolean;
  backdropOpen: boolean;
}

interface DbProductToModify extends DbProduct{
  locked: boolean;
}

interface ShowResultProps extends WithTranslation {
  id: string;
  dataFetched?: boolean;
  params?: {
    style_light_dark_type: string;
    style_cold_warm_type: string;
    shower: boolean;
    bathtub: boolean;
    fit: string;
    bathroom_type: number;
    style_unique_type: string;
    style_functional_type: string;
    style_stylized_type: boolean;
    uniform: boolean;
    style_eclectic: boolean;
  },
  products: Array<DbProduct> | null;
  fetchInProgress?: boolean;
  visualizationImageUrl?: string | null;
  // // redirectToShowProducts?: boolean,
  // fetchResults: (params: any) => any,
  fetchSavedResult: (id: number | string ) => any;
  getProject: (id: string) => any;
  productTypesToKeep: Array<string>;
  toggleProductTypeToKeep: (productType: string ) => any
  clearNewProjectData: () => any
  updateProductsInMoodboard: (id: string, productTypesToKeep: Array<string>, upperPrice: number|null) => any
  generateNewMoodboard: (id: string, params: any) => any
  userQuery?: DbMoodboardUserQuery
  classes: any
  requestInProgress: boolean
  totalPrice: number | null
  productsPrice: number | null
  updateMoodboardNoResult: boolean
  setPassword: () => (any);
}

const Wrapper = styled('div')(({theme}) => ({
  "max-width": "1025px",
  "min-height": "551px",
  "width": "100%",
  "height": "100%",
  "margin": "0 auto",
  "padding": "24px 0",
}));


const SpinnerWrapper = styled('div')(({theme}) => ({
  "max-width": "1025px",
  "min-height": "551px",
  "width": "100%",
  "height": "100%",
  "display": "flex",
  "align-items": "center",
  "justify-content": "center",
}));

const LogoBox = styled('div')(({theme}) => ({
  "display": "flex",
  "flex-direction": "column",
  "align-items": "flex-end",
  p: {
    "font-weight": 500,
    "font-style": "normal",
    "color": "#ff4e00",
    "margin": "-3px 0 0 0",
  }
}));

const ShopLogoBox = styled('div')(({theme}) => ({
  "width": "160px",
  "height": "160px",
  "border": "1px solid #000",
  "display": "flex",
  "flex-direction": "column",
  "align-items": "center",
  "justify-content": "center",
}));

const AdditionalInfo = styled('div')(({theme}) => ({
  color: "rgb(28,27,109)",
  "p": {
    "marginTop": "16px",
  },
  a: {
    "color": "rgb(28,27,109)",
    "fontWeight": "bold",
  }
}));

export class ShowSavedResult extends Component<ShowResultProps, ShowResultState> {
  constructor(props: any = {params: {}}) {
    super(props);
    // set default state
    const defaultState: ShowResultState = {
      drawerIsOpen: false,
      priceRange: [0, 50000],
      styleLightDark: 3,
      styleColdWarm: 3,
      styleUniformContrast: 3,
      sizeRange: [1, 12],
      bathroomSize: 3,
      bathtub: false,
      shower: false,
      functionality: false,
      design: false,
      modern: false,
      backdropOpen: false,
    }

    // console.log('Props', props);
    this.state = defaultState;
    this.onClickReload = this.onClickReload.bind(this);
    this.toggleProductToKeepChange = this.toggleProductToKeepChange.bind(this);
    this.updateProductsInMoodboard = this.updateProductsInMoodboard.bind(this);
    this.refreshProduct = this.refreshProduct.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handlePriceRangeChange = this.handlePriceRangeChange.bind(this);
    this.handleSizeRangeChange = this.handleSizeRangeChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    this.generateNewMoodboard = this.generateNewMoodboard.bind(this);
  }

  componentDidMount() {
    this.props.setPassword();
    this.props.getProject(this.props.id);
    this.props.clearNewProjectData();
  }

  componentDidUpdate(prevProps: Readonly<ShowResultProps>, prevState: Readonly<ShowResultState>, snapshot?: any) {
    if (this.props.userQuery !== prevProps.userQuery) {
      console.log('New UserQuery', this.props.userQuery);
      const nextState: Partial<ShowResultState> = {};
      if (this.props.userQuery) {
        nextState.styleLightDark = this.props.userQuery.style_light_dark;
        nextState.styleColdWarm = this.props.userQuery.style_cold_warm;
        nextState.styleUniformContrast = this.props.userQuery.style_plain_contrast;
        nextState.bathtub = this.props.userQuery.bathtub;
        nextState.shower = this.props.userQuery.shower;
        nextState.priceRange = this.state.priceRange;
        if (this.props.userQuery.size_in_m2) {
          nextState.bathroomSize = this.props.userQuery.size_in_m2;
        }
        if (this.props.userQuery.budget_min !== undefined) {
          nextState.priceRange[0] = this.props.userQuery.budget_min;
        }

        if (this.props.userQuery.budget_max !== undefined) {
          nextState.priceRange[1] = this.props.userQuery.budget_max;
        }
      }

      this.setState({
        ...this.state,
        ...nextState,
      });
    }
  }

  // componentWillUnmount() {
  //   this.props.clearNewProjectData();
  // }

  onClickReload() {
    // console.log('On Click Reload!!');
    // return this.props.fetchResults(this.props.params);
  }

  getDecorateProducts(): Array<DbProductToModify> {
    return (this.props.products as DbProduct[]).map(product => ({
      ...product,
      locked: this.props.productTypesToKeep.includes(product.type)
    }));
  }

  toggleProductToKeepChange(productType: string) {
    return this.props.toggleProductTypeToKeep(productType);
  }

  refreshProduct(productType: string) {
    const productTypesToKeep = (this.props.products as DbProduct[]).filter(product => {
      return product.type !== productType;
    }).map(product => product.type);
    return this.props.updateProductsInMoodboard(
      this.props.id as string,
      productTypesToKeep,
      99999,
    );
  }
  updateProductsInMoodboard() {
    return this.props.updateProductsInMoodboard(
      this.props.id as string,
      this.props.productTypesToKeep,
      99999,
    );
  }

  toggleDrawer() {
    this.setState({
      drawerIsOpen: !this.state.drawerIsOpen,
    })
  }

  handlePriceRangeChange(newPriceRange: Array<number>) {
    this.setState({
      priceRange: newPriceRange,
    });
  }

  handleSizeRangeChange(newSizeRange: Array<number>) {
    this.setState({
      sizeRange: newSizeRange,
    });
  }

  handleCheckboxChange(key: 'bathtub' | 'shower' | 'functionality' | 'design' | 'modern') {
    this.setState({
      ...this.state,
      [key]: !this.state[key],
    });
  }

  handleNumberInputChange(key: 'styleLightDark' | 'styleColdWarm' | 'styleUniformContrast' | 'bathroomSize') {
    const multiply = key === 'bathroomSize' ? 1 : -1;
    return (event: React.ChangeEvent<{}>, value: number | number[]) => {
      this.setState({
        ...this.state,
        [key]: multiply * (value as number),
      });
    }
    // this.setState({
    //   ...this.state,
    //   [key]: !this.state[key],
    // });
  }

  generateNewMoodboard() {
    this.props.generateNewMoodboard(this.props.id as string, {
      style_light_dark: this.state.styleLightDark,
      style_cold_warm: this.state.styleColdWarm,
      style_plain_contrast: this.state.styleUniformContrast,
      budget_min: this.state.priceRange[0],
      budget_max: this.state.priceRange[1],
      size_in_m2: this.state.bathroomSize,
      // "style_modern": 3,
      // "style_eclectic": 3,
      // "style_functional": 3,
      // "style_original": 3,
      // "budget": "low",
      // "size": "small",
      shower: this.state.shower,
      bathtub: this.state.bathtub,
    });
    this.setState({
      backdropOpen: true,
    })
  }

  render() {
    const {t} = this.props;
    if ((this.props.fetchInProgress || !this.props.dataFetched) || !this.props.params || !this.props.products) {
      return (
        <Wrapper>
          <SpinnerWrapper>
            <div>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </SpinnerWrapper>
        </Wrapper>
      );
    }

    return (
      <Grid container justifyContent="center">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          // alignItems="stretch"
          style={{height: '100%'}}
          width="1024px"
          mt={5}
        >
          <Grid container
            direction="row"
            justifyContent="flex-start"
            // alignItems="stretch"
          >
            <Grid item xs={2}>
              <ShopLogoBox>
                <p>Logo</p>
                <p>Sklepu</p>
              </ShopLogoBox>
            </Grid>

            <Grid item xs={4}>
              <p><strong>Dostawca:</strong></p>
              <p>SKLEP Z WYPOSAŻENIEM ŁAZIENEK</p>
              <Box mt={4}></Box>
              <p><strong>Kontakt: JAN KOWALSKI</strong></p>
              <p><strong>Numer rachunku do płatności:</strong></p>
              <p><strong>PL 00 0000 0000 0000 0000</strong></p>
            </Grid>

            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Grid xs={3}>
                  <LogoBox>
                    <a href="//tadom.pl"><img src={logo} alt={'tadom'}/></a>
                    <p>moodboard</p>
                  </LogoBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container
                direction="row"
                justifyContent="flex-end"
          // alignItems="stretch"
          >
            <Grid item style={{"color": "rgb(28,27,109)"}}>
              <p><strong>Data utworzenia {new Date().toISOString()}</strong></p>
              <p><strong>Termin ważności oferty: 7 dni</strong></p>
            </Grid>
          </Grid>

          <Grid container
                direction="row"
                justifyContent="center"
                mt={6}
            // alignItems="stretch"
          >
            <Grid item style={{"color": "rgb(28,27,109)", "fontSize": "24px"}}>
              <p><strong>OFERTA ZAKUPOWA MUMER 2022/1</strong></p>
            </Grid>
          </Grid>

          <Grid container
                direction="row"
                justifyContent="center"
            // alignItems="stretch"
          >
            <Grid item xs={12}>
              <ShoppingList products={this.props.products} bathroomSizeInM2={this.props.userQuery?.size_in_m2} notEditable={true}></ShoppingList>
            </Grid>
          </Grid>
          <Grid container
                direction="row"
                justifyContent="center"
            // alignItems="stretch"
          >
            <Grid item xs={12} style={{"backgroundColor": "#ff4e00", "color": "#fff"}} p={1}>
              Zamów produkty u dostawcy oraz dokonaj płatności na wskazany rachunek.
            </Grid>
          </Grid>

          <Grid container
                direction="row"
                justifyContent="center"
            // alignItems="stretch"
          >
            <Grid item xs={12} mt={4}>
              <AdditionalInfo>
                <p>Dostępność produktów jest aktualna jedynie na moment, w którym wygenerowana została niniejsza oferta.</p>
                <p>Niniejsza oferta nie stanowi rezerwacji produktów w magazynie, a dostępność oferowanych materiałów może się zmienić.</p>
                <p>Warunki transportu są każdorozowo ustalane z dostawcą. Zdjęcia produktów mają charakter poglądowy.</p>
                <p>Powyższa oferta nie stanowi oferty handlowej w rozumieniu art. 66 §1 kodeksu cywilnego oraz innych właściwych przepisów prawnych.</p>
                <p>W przypadku pytań lub wątpliwości skontaktuj się z nami <a href="mailto:kontakt@estimote.com">kontakt@tadon.pl</a></p>
              </AdditionalInfo>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state: {searchResult: any, foundProducts: any, projects: ProjectState, calculator: any}, ownProps: {match: {params: {id: string}}}) {
  const uuid: RegExp = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;
  console.log('--------UPDATE');
  const { searchResult,  projects , calculator } = state;
  const id: string = ownProps.match.params.id;
  // console.log('Project', projects);
  const projectResponse = projects.projectResponsesById[id];
  // let products: Array<DbProduct> = [];
  // if(projectResponse) {
  //   products = projectResponse.data.moodboard.products;
  // }
  // const products = searchResult.searchResultProductsById[id];
  return {
    id,
    dataFetched: !!projectResponse,
    params: projects.getProjectMoodboardParams(id),
    products: projects.getProjectProducts(id),
    visualizationImageUrl: projects.getProjectMoodboardVisualizationImageUrl(id),
    fetchInProgress: false,
    productTypesToKeep: searchResult.productTypesToKeep,
    userQuery: projects.getProjectMoodboardUserQuery(id),
    requestInProgress: projects.isRequestForOneProjectInProgress(id),
    totalPrice: calculator.getTotalPrice(),
    productsPrice: calculator.getProductsPrice(),
    updateMoodboardNoResult: projects.updateMoodboardNoResult,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSavedResult: (id: number | string) => dispatch<any>(fetchSavedResult(id)),
  toggleProductTypeToKeep(productType: string) {
    return dispatch<any>(toggleProductTypeToKeep(productType));
  },
  updateProductsInMoodboard(id: string, productTypesToKeep: Array<string>, upperPrice: number | null) {
    return dispatch<any>(updateMoodboard(id, productTypesToKeep));
  },
  generateNewMoodboard(id: string, params: any) {
    return dispatch<any>(generateNewMoodboard(id, params));
  },
  getProject(id: string) {
    return dispatch<any>(getProject(id))
  },
  clearNewProjectData() {
    return dispatch<any>(clearNewProjectData());
  },
  setPassword() {
    return dispatch(setPassword());
  }
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ShowSavedResult))));
