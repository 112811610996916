import React from "react";
import styled from "styled-components";

interface InputWithLabelProps {
  label: string;
  name: string;
  type: string;
  value: string;
  onChange: (value: string) => void
  invalid: boolean;
}


const InputWithLabelBox = styled.div`
  label {
    font-size: 14px;   
    display: block;
  }

  input {
    width: 100%; 
    height: 50px;
    padding: 10px;
    &.invalid {
      border-color: #ff4d00;   
    }
  }  
`;

export default function InputWithLabel(props: InputWithLabelProps): any{
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value)
  };

  return (
    <InputWithLabelBox>
      <label className="">
        {props.label}
      </label>
      <input className={props.invalid ? 'invalid' : undefined} name={props.name} type={props.type} value={props.value} onChange={onChange}></input>
    </InputWithLabelBox>
  )
}
