import React from 'react';
import ContainerSetItem from '../ContainerSetItem';

import img1 from '../../assets/img/set2_1.png';
import img2 from '../../assets/img/set2_2.png';
import img3 from '../../assets/img/set2_3.png';
import img4 from '../../assets/img/set2_4.png';
import img5 from '../../assets/img/set2_5.png';
import img6 from '../../assets/img/set2_6.png';
import img7 from '../../assets/img/set2_7.png';
import img8 from '../../assets/img/set2_8.png';
import img9 from '../../assets/img/set2_9.png';
import img10 from '../../assets/img/set2_10.png';
import img11 from '../../assets/img/set2_11.png';

const productPositionByType: {[key: string]: any} = {
  washbasin: {img:img1, text:'img1', price:1230, width:'132px', height:'76px'},
  shower_base: {img:img2, text:'img1', price:1230, width:'105px', height:'88px'},
  washbasin_faucet: {img:img3, text:'img1', price:1230, width:'73px', height:'73px'},
  shower_faucet: {img:img4, text:'img1', price:1230, width:'72px', height:'180px'},
  shower_cubicle: {img:img5, text:'img1', price:1230, width:'119px', height:'188px'},
  heater: {img:img6, text:'img1', price:1230, width:'104px', height:'191px'},
  wall_lighting: {img:img7, text:'img1', price:1230, width:'90px', height:'60px'},
  mirror: {img:img8, text:'img1', price:1230, width:'84px', height:'134px'},
  washbasin_cabinet: {img:img9, text:'img1', price:1230, width:'128px', height:'128px'},
  toilet: {img:img10, text:'img1', price:1230, width:'94px', height:'109px'},
  ceil_lighting: {img:img11, text:'img1', price:1230, width:'89px', height:'89px'}
};

interface ShowResultShowerNewParams {
  products: Array<any>;
  toggleProductTypeToKeep?: (a: string) => any;
  refreshProduct?: (a: string) => any;
}

const ShowResultShowerNew = ({products, toggleProductTypeToKeep, refreshProduct}: ShowResultShowerNewParams) => {
  const notBigSurfaceProductsOrder: Array<string> = [
    'washbasin', 'shower_base', 'washbasin_faucet', 'shower_faucet', 'shower_cubicle', 'heater',
    'wall_lighting', 'mirror', 'washbasin_cabinet', 'toilet', 'ceil_lighting'
  ];

  const productsByType: {[key: string]: {}} = {};
  products.forEach(product => {
    productsByType[product.type] = product;
  });

  let notBigSurfaceProducts: Array<any> = notBigSurfaceProductsOrder.map(productType => {
    const product: any = productsByType[productType];
    if (!product) {
      return null;
    }
    return Object.assign({}, productPositionByType[productType], {
      img: product.image_url,
      price: product.price,
      text: `${product.manufacturer && product.manufacturer} ${product.series && product.series}`,
      locked: product.locked,
      type: productType,
    });
  });

  notBigSurfaceProducts = notBigSurfaceProducts.filter(product => !!product);
  return (
    <ContainerSetItem
      items={notBigSurfaceProducts}
      type="set2"
      toggleProductTypeToKeep={toggleProductTypeToKeep}
      refreshProduct={refreshProduct}
    />
  );
};

export default ShowResultShowerNew;
