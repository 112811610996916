import React from 'react';
import styled from 'styled-components';
import GoogleButton from 'react-google-button'


import ButtonSet from "../ButtonSet";
import {Dispatch} from "redux";
import {fetchCurrentUser, logIn} from "../../redux/actions/userActions";
import {ProjectState} from "../../redux/reducers/projectReducer";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {UserReducerState} from "../../redux/reducers/userReducer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {withStyles} from "@mui/styles";
import InputWithLabel from "../InputWithLabel";
import {ApiUser} from "../../redux/types/apiType";
import {Link, Redirect} from "react-router-dom";

interface LoginPagePageProps extends WithTranslation {
  logIn: (email: string, password: string) => any;
  fetchUser: () => any;
  logInInProgress: boolean;
  classes: any;
  logInError: ErrorResponse | null,
  logInErrorStatus: number | null,
  user?: ApiUser | null,
}

interface LoginPagePageState {
  email: string;
  password: string;
}

const Wrapper = styled.div`
  width: 490px;
  background-color: #fff;
  padding: 30px 40px 60px 40px;  
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 18px; 
    margin-bottom: 0;
  }
     
  .emailBox {
    margin-top: 30px; 
  }
  
  .passwordBox {
    margin-top: 22px; 
  }
  
  .errorBox {
    margin-top: 22px;
    font-size: 12px;
    color: #ff4d00; 
  }
  .button {
    margin-top: 22px;
  }
  
  .socialButtons {
    margin-top: 10px; 
  }
  
  .footerInfo {
    margin-top: 10px; 
    font-size: 11px;
    a {
      color: #ff4e00;
      text-decoration: underline; 
    }
  }
`;

class LoginPage extends React.Component<LoginPagePageProps, LoginPagePageState> {
  constructor(props: any = {params: {}}) {
    super(props);
    this.state = {
      email: '',
      password: '',
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.logIn = this.logIn.bind(this);
  }

  handleEmailChange(value: string) {
    this.setState({
      email: value,
    });
  }

  handlePasswordChange(value: string) {
    this.setState({
      password: value,
    });
  }

  logIn() {
    this.props.logIn(this.state.email, this.state.password);
  }

  hasLoginError(): boolean {
    return !!this.props.logInError;
  }

  getErrorMessage(): string{
    if (!this.props.logInErrorStatus || this.props.logInErrorStatus > 499) {
      return 'Nieznany błąd. Spróbuj ponownie za chwile.';
    }

    return 'Wprowadzony email lub hasło są niepoprawne';
  }

  componentDidMount() {
    if (!this.props.user) {
      return this.props.fetchUser();
    }
  }

  render() {
    // if ()
    if (this.props.user) {
      console.log('User');
      return <Redirect to="/"></Redirect>
    }
    return (
      <Wrapper>
        <h3>Zaloguj się</h3>
        <div className="emailBox">
          <InputWithLabel
            name="email"
            label="Email"
            type="text"
            value={this.state.email}
            onChange={this.handleEmailChange}
            invalid={this.hasLoginError()}
          />
        </div>
        <div className="passwordBox">
          <InputWithLabel
            name="password"
            label="Hasło"
            type="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
            invalid={this.hasLoginError()}
          />
        </div>
        {this.hasLoginError() && <div className="errorBox">{this.getErrorMessage()}</div>}
        <div className="button">
          <ButtonSet onClick={this.logIn} type="secondary" width="100%" height="50px"><span>Zaloguj się do Tadom</span></ButtonSet>
        </div>
        <div className="footerInfo">
          Lub zaloguj się za pomocą
        </div>
        <div className="socialButtons">
          <GoogleButton
            onClick={() => { window.location.href = "http://localhost:4000/auth/google" }}
          />
        </div>
        <div className="footerInfo">
          Nie masz jeszcze konta? <Link to="/signup">Zarejestruj się</Link> - usługa Tadom jest bezpłatna!
        </div>
        <Backdrop className={this.props.classes.backdrop} open={this.props.logInInProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Wrapper>
    )
  }
}

function mapStateToProps(state: {user: UserReducerState, projects: ProjectState }) {
  const { user } = state;
  return {
    logInInProgress: user.logInProgress,
    logInError: user.logInError,
    logInErrorStatus: user.getLogInErrorStatusCode(),
    user: user.user,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logIn(email: string, password: string) {
    return  dispatch<any>(logIn(email, password));
  },
  fetchUser() {
    return dispatch<any>(fetchCurrentUser());
  }
});

const styles = {
  backdrop: {
    zIndex: 9999999,
    color: '#fff',
  },
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(LoginPage)));
