import React from 'react';
import styled from 'styled-components';

import ButtonSet from "../ButtonSet";
import {Dispatch} from "redux";
import { signUp} from "../../redux/actions/userActions";
import {ProjectState} from "../../redux/reducers/projectReducer";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {UserReducerState} from "../../redux/reducers/userReducer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {withStyles} from "@mui/styles";
import InputWithLabel from "../InputWithLabel";
import ValidationUtils from "../../utils/validationUtils";
import {Link} from "react-router-dom";


interface SignUpPageProps extends WithTranslation {
  signUp: (email: string, password: string) => any;
  signUpInProgress: boolean;
  classes: any;
  signUpError: ErrorResponse | null,
  signUpErrorStatus: number | null,
  signUpSuccess: boolean;
}

interface SignUpPageState {
  email: string;
  password: string;
  password2: string;
  dirty: boolean;
}

const Wrapper = styled.div`
  width: 490px;
  background-color: #fff;
  padding: 30px 40px 60px 40px;  
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 18px; 
    margin-bottom: 0;
  }
     
  .emailBox {
    margin-top: 30px; 
  }
  
  .passwordBox {
    margin-top: 22px; 
  }
  
  .errorBox {
    margin-top: 22px;
    font-size: 12px;
    color: #ff4d00; 
  }
  .button {
    margin-top: 22px;
  }
  
  .footerInfo {
    margin-top: 10px; 
    font-size: 11px;
    a {
      color: #ff4e00;
      text-decoration: underline; 
    }
  }  
`;

class SignupPage extends React.Component<SignUpPageProps, SignUpPageState> {
  constructor(props: any = {params: {}}) {
    super(props);
    this.state = {
      email: '',
      password: '',
      password2: '',
      dirty: false,
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePassword2Change = this.handlePassword2Change.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  handleEmailChange(value: string) {
    this.setState({
      email: value,
    });
  }

  handlePasswordChange(value: string) {
    this.setState({
      password: value,
    });
  }

  handlePassword2Change(value: string) {
    this.setState({
      password2: value,
    });
  }

  signUp() {
    console.log('Form is Valid', this.isFormValid());
    if (this.isFormValid()) {
      this.props.signUp(this.state.email, this.state.password);
    }
    this.setState({
      dirty: true,
    });
  }

  hasSignUpError(): boolean {
    return this.state.dirty && !this.isFormValid() || !!this.props.signUpError;
  }

  getErrorMessage(): string{
    if (!this.isFormValid()) {
      return 'Formularz zawiera niepoprawne dane';
    } else if (!this.props.signUpErrorStatus || this.props.signUpErrorStatus > 499) {
      return 'Nieznany błąd. Spróbuj ponownie za chwile.';
    }

    return 'Wprowadzony email lub hasło są niepoprawne';
  }

  isEmailValid(): boolean{
    return ValidationUtils.validateEmail(this.state.email);
  }

  isPasswordValid(): boolean {
    return ValidationUtils.validatePasswordLength(this.state.password)
      && ValidationUtils.validatePasswordLength(this.state.password2)
      && ValidationUtils.validateIfPasswordIsTheSame(this.state.password, this.state.password2);
  }

  isFormValid(): boolean {
    return this.isEmailValid() && this.isPasswordValid();
  }

  render() {
    if (this.props.signUpSuccess) {
      return (
        <Wrapper>
          <div className="button">
            <Link to="/login">
              <ButtonSet onClick={() => {}} type="secondary" width="100%" height="50px"><span>Konto utworzone. Przejdź do strony logowania.</span></ButtonSet>
            </Link>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <h3>Zarejestruj się w Tadom</h3>
        <div className="emailBox">
          <InputWithLabel
            name="email"
            label="Email"
            type="text"
            value={this.state.email}
            onChange={this.handleEmailChange}
            invalid={this.state.dirty && (this.hasSignUpError() || !this.isEmailValid())}
          />
        </div>
        <div className="passwordBox">
          <InputWithLabel
            name="password"
            label="Hasło"
            type="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
            invalid={this.state.dirty && (this.hasSignUpError() || !this.isPasswordValid())}
          />
        </div>
        <div className="passwordBox">
          <InputWithLabel
            name="password"
            label="Powtórz hasło"
            type="password"
            value={this.state.password2}
            onChange={this.handlePassword2Change}
            invalid={this.state.dirty && (this.hasSignUpError() || !this.isPasswordValid())}
          />
        </div>
        {this.hasSignUpError() && <div className="errorBox">{this.getErrorMessage()}</div>}
        <div className="button">
          <ButtonSet onClick={this.signUp} type="secondary" width="100%" height="50px"><span>Zarejestruj się w Tadom</span></ButtonSet>
        </div>
        <div className="footerInfo">
          Masz już konto? <Link to="/login">Zaloguj się</Link> - usługa Tadom jest bezpłatna!
        </div>
        <Backdrop className={this.props.classes.backdrop} open={this.props.signUpInProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Wrapper>
    )
  }
}

function mapStateToProps(state: {user: UserReducerState, projects: ProjectState }) {
  const { user } = state;
  return {
    signUpInProgress: user.signUpInProgress,
    signUpError: user.signUpError,
    signUpErrorStatus: user.getSignUpErrorStatusCode(),
    signUpSuccess: !!user.signUpSuccess,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signUp(email: string, password: string) {
    return  dispatch<any>(signUp(email, password));
  },
});

const styles = {
  backdrop: {
    zIndex: 9999999,
    color: '#fff',
  },
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(SignupPage)));
