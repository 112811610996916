import i18 from '../i18n';

const plnToReal = 1.09;
export function adjustPriceToMarket(price: number): number {
  if (i18.language === 'pt') {
    return price * plnToReal;
  }
  return price;
}

export function getCurrencySuffix(): string {
  if (i18.language === 'pt') {
    return 'R$';
  }
  return 'zł';
}
