import { ADD_TODO } from "../actionTypes";

const initialState = {
  allIds: [],
  byIds: {},
  counter: 0,
};

interface Action {
  payload: object,
  type: string,
}

interface TodoAction extends  Action{
  payload: {
    id: number,
    content?: string,
  }
}

export default function(state = initialState, action: TodoAction) {
  console.log('Action--', action);
  switch (action.type) {
    case ADD_TODO: {
      console.log('Action--', action);
      const { id, content } = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        counter: [...state.allIds, id].length,
        byIds: {
          ...state.byIds,
          [id]: {
            content,
            completed: false
          }
        }
      };
    }
    // case TOGGLE_TODO: {
    //   const { id } = action.payload;
    //   return {
    //     ...state,
    //     byIds: {
    //       ...state.byIds,
    //       [id]: {
    //         ...state.byIds[id],
    //         completed: !state.byIds[id].completed
    //       }
    //     }
    //   };
    // }
    // case INCREMENT_COUNTER: {
    //   return {
    //     ...state,
    //     counter: state.counter++,
    //   }
    // }
    default:
      return state;
  }
}
