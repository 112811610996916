import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import icon from '../../assets/img/icon_lamp.svg';
import styled from 'styled-components';
import ButtonSet from '../ButtonSet';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const WrapperLink = styled.div`
    display:flex;
    margin-top: 16px;

    p {
        font-size: 9px;
        max-width: 344px;
        letter-spacing: 0.5px;
        margin-left: 6px;

        a {
            color: #000;
            &:hover {
                font-weight: 500;
            }            
        }
    }
    p.feedback {
      font-size: 14px;
      font-weight: bold;
      max-width: 500px;
    }
    a {
      color: #000;
      &:hover {
        font-weight: 500;
    }
`;

const WrapperButton = styled.div`
    display: flex;
    justify-content: space-between;
`;
/*
ButtonSetBox - kontyner na buttony w zestawach oraz na stopke
*/
const ButtonSetBox = ({onClickReload = () => {}, onClickShare= () => {}, renderButtons = true, linkToDownload = ''} = {}) => {
    const { t, i18n } = useTranslation();
    return (
        <Wrapper>
            {renderButtons &&
              <WrapperButton>
                <ButtonSet onClick={onClickReload}>{t('change_set')}</ButtonSet>
                <ButtonSet type="secondary" onClick={onClickShare}>
                  <a style={{color: "#fff"}} href={linkToDownload}>{t('this_is_perfect')}</a>
                </ButtonSet>
              </WrapperButton>
            }
            <WrapperLink>
                <img src={icon} alt="icon_lamp" width='14px'/>
                <p>{t('projectPage:anotherMoodboard1')} <Link to="/">{t('projectPage:anotherMoodboardLink')}</Link>{t('projectPage:anotherMoodboard2')}</p>
            </WrapperLink>
        </Wrapper>
    )
}

export default ButtonSetBox;
