import React, {Component} from "react";
import {Dispatch} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {UserReducerState} from "../../redux/reducers/userReducer";
import styled from "styled-components";
import {fetchCurrentUser, logout} from "../../redux/actions/userActions";
import {ApiUser} from "../../redux/types/apiType";

import logoutIcon from './logout.png';

interface LoginInfoProps extends WithTranslation{
  logout: () => any
  fetchUser: () => any;
  logInInProgress: boolean;
  logInError: ErrorResponse | null,
  logInErrorStatus: number | null,
  user?: ApiUser | null,

}

interface LoginInfoState {

}

const Wrapper = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  span {
     display: block;
     &.header {
      font-size: 8px;         
      text-align: right;
     }
     &:nth-child(2) {
      font-size: 12px;
     }
     
  }
  
  .login {
      font-size: 12px;
      color: #000;
  }
  
  a {
    img.logoutIcon {
      width: 32px;
      height: 32px; 
    }
    &:hover {    
      cursor: pointer;
      
      span {
        color: #ff4e00;
        text-decoration: none;
        cursor: pointer;
      }
    }
     
    
  } 
`;

class LoginInfo extends Component<LoginInfoProps, LoginInfoState> {
  constructor(props: any = {}) {
    super(props);
    this.state = {};
    this.logout = this.logout.bind(this);
  }

  logout(e: any) {
    e.preventDefault();
    return this.props.logout();
  }

  componentDidMount() {
    if (!this.props.user) {
      return this.props.fetchUser();
    }
  }

  render() {
    if (!this.props.user) {
      return (
        <Wrapper>
          <Link to="/login"><span className="login">Zaloguj się</span></Link>
        </Wrapper>
      )
    };
    return (
      <Wrapper>
        <div className="logged">
          <span className="header">Zalogowano jako</span>
          <span>{this.props.user.email} </span>
        </div>
        <a onClick={this.logout}><img className="logoutIcon" src={logoutIcon} /></a>
      </Wrapper>
    );
  }
}

function mapStateToProps(state: {user: UserReducerState}) {
  const { user } = state;
  // const products = searchResult.searchResultProductsById[id];
  return {
    logInInProgress: user.logInProgress,
    logInError: user.logInError,
    logInErrorStatus: user.getLogInErrorStatusCode(),
    user: user.user,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch<any>(logout()),
  fetchUser() {
    return dispatch<any>(fetchCurrentUser());
  },
  // fetchSavedResult: (id: number | string) => dispatch<any>(fetchSavedResult(id)),
  // toggleProductTypeToKeep(productType: string) {
  //   return dispatch<any>(toggleProductTypeToKeep(productType));
  // },
  // updateProductsInMoodboard(id: string, productTypesToKeep: Array<string>, upperPrice: number | null) {
  //   return dispatch<any>(updateMoodboard(id, productTypesToKeep));
  // },
  // generateNewMoodboard(id: string, params: any) {
  //   return dispatch<any>(generateNewMoodboard(id, params));
  // },
  // getProject(id: string) {
  //   return dispatch<any>(getProject(id))
  // }
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginInfo));
