import React from 'react';
import styled from 'styled-components';

import {Dispatch} from "redux";
import {ProjectState} from "../../redux/reducers/projectReducer";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {UserReducerState} from "../../redux/reducers/userReducer";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import {getProjects} from "../../redux/actions/projectActions";

interface ProjectsPageProps extends WithTranslation {
  getProjects: () => any;
  projects: Project[],
}

interface ProjectsPageState {
  // email: string;
  // password: string;
}

const Wrapper = styled.div`
  width: 490px;
  background-color: #fff;
  padding: 30px 40px 60px 40px;  
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 18px; 
    margin-bottom: 0;
  }
     
  .emailBox {
    margin-top: 30px; 
  }
  
  .passwordBox {
    margin-top: 22px; 
  }
  
  .errorBox {
    margin-top: 22px;
    font-size: 12px;
    color: #ff4d00; 
  }
  .button {
    margin-top: 22px;
  }
  
  .footerInfo {
    margin-top: 10px; 
    font-size: 11px;
    a {
      color: #ff4e00;
      text-decoration: underline; 
    }
  }
`;

class ProjectsPage extends React.Component<ProjectsPageProps, ProjectsPageState> {
  constructor(props: any = {params: {}}) {
    super(props);
    this.state = {
      // email: '',
      // password: '',
    }

  }

  componentDidMount() {
    this.props.getProjects();
    // if (!this.props.user) {
    //   return this.props.fetchUser();
    // }
  }

  render() {
    // if ()
    return (
      <>
        <h3>Projects </h3>
        {/*<Box width={'100%'}>*/}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {this.props.projects.map(project => (
            <Grid
              item
              xs={3}
            >
             <Card>
               <Grid
                 container
                 direction="row"
                 justifyContent="flex-start"
                 alignItems="flex-start"
               >
                 <Grid
                  item
                  xs={8}
                 >
                   <CardMedia
                     component="img"
                     alt="Contemplative Reptile"
                     style={{width: '100%'}}
                     // height="300"
                     // width={300}
                     image={project.moodboard.visualization_image_url as string}
                     title="Contemplative Reptile"
                   />
                 </Grid>
                 <Grid item xs={4}>
                   <CardContent>
                     <Typography gutterBottom variant="h5" component="h2">
                       {project.name}
                     </Typography>
                     <Typography variant="body2" color="textSecondary" component="p">
                       Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                       across all continents except Antarctica
                     </Typography>
                   </CardContent>
                   <CardActions>

                   </CardActions>
                 </Grid>
               </Grid>
             </Card>
            </Grid>
          ))}
        </Grid>
        {/*  <Card>*/}
        {/*    <CardActionArea>*/}
        {/*      <CardContent>*/}
        {/*        <Typography gutterBottom variant="h5" component="h2">*/}
        {/*          Lizard*/}
        {/*        </Typography>*/}
        {/*        <Typography variant="body2" color="textSecondary" component="p">*/}
        {/*          Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging*/}
        {/*          across all continents except Antarctica*/}
        {/*        </Typography>*/}
        {/*      </CardContent>*/}
        {/*    </CardActionArea>*/}
        {/*  </Card>*/}
        {/*</Box>*/}
        {/*<Card></Card>*/}
        {/*<Card></Card>*/}
      </>
    )
  }
}

function mapStateToProps(state: {user: UserReducerState, projects: ProjectState }) {
  const { user, projects } = state;
  return {
    projects: projects.getProjects(),
    // logInInProgress: user.logInProgress,
    // logInError: user.logInError,
    // logInErrorStatus: user.getLogInErrorStatusCode(),
    // user: user.user,
  };
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProjects: () => dispatch<any>(getProjects()),
});

// const styles = {
//   backdrop: {
//     zIndex: 9999999,
//     color: '#fff',
//   },
// };


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectsPage));
