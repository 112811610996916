import {Dispatch} from "redux";
import request from "superagent";


import {
  CHANGE_PRODUCT_TYPES_TO_KEEP,
  ChangeProductTypesToKeepAction,
  FETCH_SAVED_RESULT_DONE,
  FETCH_SAVED_RESULT_IN_PROGRESS,
  SearchActionType,
  SearchResultDoneAction,
  TOGGLE_PRODUCT_TYPES_TO_KEEP,
  ToggleProductTypeToKeepAction,
} from "../types/serchResultTypes";

export const fetchSavedResultInProgress = (): SearchActionType => {
  return {
    type: FETCH_SAVED_RESULT_IN_PROGRESS,
    // payload: {},
  }
};

const fetchSavedResultDone = ({ id, uuid, params, products, visualization_image_url, uuidUsed }: {id: number, uuid: string, params: object, products: Array<object>, visualization_image_url: string, uuidUsed: boolean}): SearchResultDoneAction => {
  return {
    type: FETCH_SAVED_RESULT_DONE,
    payload: {
      id: uuidUsed ? uuid : id,
      params,
      products,
      visualization_image_url,
    }
  }
};

export const toggleProductTypeToKeep = (productType: string): ToggleProductTypeToKeepAction => {
  return {
    type: TOGGLE_PRODUCT_TYPES_TO_KEEP,
    payload: {
      productType
    }
  }
}

export const changeProductTypesToKeep = (productTypes: Array<string>): ChangeProductTypesToKeepAction => {
  return {
    type: CHANGE_PRODUCT_TYPES_TO_KEEP,
    payload: {
      productTypes,
    }
  }
}
// export const

export const fetchSavedResult = (id: number | string) => {
  return async (dispatch: Dispatch) => {
    const uuid: RegExp = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;
    dispatch(fetchSavedResultInProgress());
    const response: any = await request
      .get(`/v1/search_results/${id}`);

    console.log('Result', response);
    dispatch(fetchSavedResultDone({
      ...response.body.data,
      uuidUsed: uuid.test(id.toString()),
    }));
    // dispatch(fetchResultsDone({
    //   body: response.body,
    //   params,
    // }));
    // history.push('/results');
    // browserHistory.push('/results')
  };
};

export const updateMoodboard = (id: string, productTypesToKeep: Array<string>, upperPrice: number|null) => {
  return async (dispatch: Dispatch) => {
    const response = await request
      .post(`v1/moodboards/${id}/change`)
      .send({
        product_types: productTypesToKeep,
        upper_price: upperPrice,
      });
    if (response.status === 200) {
      return fetchSavedResult(id)(dispatch);
    }
  }
}
