import React from 'react';
import icon_repeat from '../../assets/img/repeat.svg';
import styled from 'styled-components';

const Button = styled.button`
    display: flex;
    justify-content: center;
    font-size: 12px;
    outline: none;
    background: ${({type}) => type ==='secondary' ? '#ff4e00' : '#fff'};
    color: ${({type}) => type ==='secondary' ? '#fff' : '#000'};
    font-weight: 400;
    padding: 11px 20px;
    align-items: center;
    border: 0;
    border:${({type}) => type ==='secondary' ? '1px solid rgb(224,193,100)' : '1px solid #000'};
    cursor: pointer;
    width: ${props => props.width || "173px"};
    height: ${props => props.height || "auto"};
    transition: all .2s;

    &:hover {
        background: ${({type}) => type ==='secondary' ? 'rgb(204,101,86)' : '#fff'};
        border: ${({type}) => type ==='secondary' ? '1px solid rgb(204,101,86)' : '2px solid #000'};
        padding: ${({type}) => type ==='secondary' ? '11px 20px' : '9px 18px'};
    }


    img {
        width: 12px;
        margin-right: 4px;
    }
`;

/*
    ButtonSet - buttony w zestawach
    przyjmuje dwa argumenty
    children - tekst w srodku buttona
    type - typ, jezeli ma typ nie jest secondary zawiera ikone oraz inne style a dokladnie style przycisku drugiego z zestawow
*/
const ButtonSet = ({ children, type, onClick, width, height} = {}) => (<Button type={type} onClick={onClick} width={width} height={height}>{type !== 'secondary' ? <img src={icon_repeat} alt="repeat" /> : ''} {children}</Button>);

export default ButtonSet;
