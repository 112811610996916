const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ValidationUtils {

  static validatePasswordLength(password: string, length = 8): boolean {
    return password.length >= length;
  }

  static validateIfPasswordIsTheSame(password: string, password2: string): boolean {
    return password === password2;
  }

  static validateEmail(email: string): boolean {
    return EMAIL_REGEX.test(email);
  }
}

export default ValidationUtils;
