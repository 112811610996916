import React, {Component} from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Redirect, withRouter} from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

import ShowResultBathtubNew from './ShowResultBathtubNew'
import ShowResultShowerNew from './ShowResultShowerNew';
import ShowResultBathtubShowerNew from './ShowResultBathtubShowerNew';
import ShowResultVisualizationImage from './ShowResultVisualizationImage';
import ShowResultBigSurfaceProducts from './ShowResultBigSurfaceProducts';
import ButtonSetBox from './ButtonSetBox';
import FeedbackBox from './FeedbackBox'
import ShoppingList from "./ShoppingList";
import Drawer from "@mui/material/Drawer"
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled as styledMui} from '@mui/material/styles';
import HorizontalSlider from "./HorizontalSlider";
import HorizontalSliderRange from "./HorizontalSliderRange";

import {fetchSavedResult, toggleProductTypeToKeep } from "../redux/actions/savedResultActions";

import rolloutIcon from '../assets/img/rollout.png';
import {WithTranslation, withTranslation} from "react-i18next";
import {SliderOrientation} from "@reach/slider";
import ButtonSet from "./ButtonSet";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from '@mui/styles';

import {UserForm} from "../redux/types/userFormTypes";

import {ProjectState} from "../redux/reducers/projectReducer";
import {getCurrencySuffix} from '../utils/currencyUtils';
import {clearNewProjectData, generateNewMoodboard, getProject, updateMoodboard} from "../redux/actions/projectActions";
import {ProjectActionType} from "../redux/types/projectTypes";
import {Calculator} from "./Calculator";
import CalculatorTotalPrice from "./CalculatorTotalPrice";
import PriceSetBox from "./PriceSetBox";
import {setPassword} from "../redux/actions/applicationAction";
import Carousel from "react-material-ui-carousel";
// export default function Calculator() {
//   return (
//     <div>
//       <h3>Show Results</h3>
//     </div>
//   )
// }

const styles = {
  backdrop: {
    zIndex: 9999999,
    color: '#fff',
  },
};

interface ShowResultState {
  drawerIsOpen: boolean;
  styleLightDark: number;
  styleColdWarm: number;
  styleUniformContrast: number;
  priceRange: Array<number>;
  sizeRange: Array<number>;
  bathroomSize: number;
  bathtub: boolean;
  shower: boolean;
  functionality: boolean;
  design: boolean;
  modern: boolean;
  backdropOpen: boolean;
}

interface DbProductToModify extends DbProduct{
  locked: boolean;
}

interface ShowResultProps extends WithTranslation {
  id: string;
  dataFetched?: boolean;
  params?: {
    style_light_dark_type: string;
    style_cold_warm_type: string;
    shower: boolean;
    bathtub: boolean;
    fit: string;
    bathroom_type: number;
    style_unique_type: string;
    style_functional_type: string;
    style_stylized_type: boolean;
    uniform: boolean;
    style_eclectic: boolean;
  },
  products: Array<DbProduct> | null;
  fetchInProgress?: boolean;
  visualizationImageUrl?: string | null;
  // // redirectToShowProducts?: boolean,
  // fetchResults: (params: any) => any,
  fetchSavedResult: (id: number | string ) => any;
  getProject: (id: string) => any;
  productTypesToKeep: Array<string>;
  toggleProductTypeToKeep: (productType: string ) => any
  clearNewProjectData: () => any
  updateProductsInMoodboard: (id: string, productTypesToKeep: Array<string>, upperPrice: number|null) => any
  generateNewMoodboard: (id: string, params: any) => any
  userQuery?: DbMoodboardUserQuery
  classes: any
  requestInProgress: boolean
  totalPrice: number | null
  productsPrice: number | null
  updateMoodboardNoResult: boolean
  setPassword: () => (any);
}

const Wrapper = styled.div`
    max-width: 1025px;
    //min-width: 1025px;
    min-height: 551px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 24px 0;
`;

const SpinnerWrapper = styled.div`
  max-width: 1025px;
  //min-width: 1025px;
  min-height: 551px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxS = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BoxBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: flex-start;
`;

const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: flex-start;
`

const DrawerButtonBox = styled.div<{ open: boolean }>`
  position: fixed;
  left: ${props => props.open ? "480px" : "0px"};
  top: 20px;
  z-index: 2000;
  &:hover {
    cursor: pointer; 
  }
  // &.isOpen {
  //   left: 480px; 
  // }
`

const MoodboardParams = styledMui('div')(({theme}) => ({
  width: '480px', // 480
  'flex-grow': 1,
  // height: ${props => `${props.windowInnerHeight}px`};
  // min-height: 100vh;
  // min-height: 100vh;
  display: 'flex',
  'flex-direction': 'column',
  'background-color': 'rgb(242, 240, 237)',
  // [theme.breakpoints.down('xs')]: {
  //   backgroundColor: '#000',
  // },
  [theme.breakpoints.up('sm')]: {
    minHeight: '100%',
  },
  // [theme.breakpoints.down('xs')]: {
  //   width: '390px',
  // },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  'padding-top': '15px',
  'align-items': 'center',
  '.closeWrapper': {
    'align-self': 'flex-start',
    'display': 'flex',
    'flex-direction': 'row',
    'justify-content': 'flex-start',
    'margin-left': '20px',
    'a': {
      'font-size': '24px',
    },
    'a:hover': {
      'cursor': 'pointer',
    }
  },
  '.content': {
    display: 'flex',
    'flex-direction': 'column',
    width: 'calc(100% - 40px)',
    'align-items': 'center',
    '.slider': {
      'width': '100%',
      'margin-top': '15px',
      '&:first-of-type': {
        'margin-top': '10px',
      },
      '&.smallMarginTop': {
        'margin-top': '5px',
      }
    }
  },
  'h3': {
    'font-size': '18px',
    'font-weight': 'bold',
    'margin-top': '6px',
  },

  'h4': {
    'margin-top': '10px',
    'font-size': '16px',
  },

  'h5': {
    'margin-top': '10px',
  },
  '.checkboxes': {
    '.MuiCheckbox-root': {
      color: 'rgb(224,193,100) !important',
    },
    label: {
      'span:nth-child(2)': {
        'font-size': '12px',
        'font-weight': '500',
      }
    }
  }
}));

//
//   .content {
//     display: flex;
//     flex-direction: column;
//     width: 360px; // 420
//     align-items: center;
//     .slider {
//       width: 100%;
//       margin-top: 15px;
//       &:first-of-type {
//         margin-top: 10px;
//       }
//       &.smallMarginTop {
//         margin-top: 5px;
//       }
//     }
//   }
//   h3 {
//     font-size: 18px;
//     font-weight: bold;
//     margin-top: 6px;
//   }
//
//   h4 {
//     margin-top: 10px;
//     font-size: 16px;
//   }
//
//   h5 {
//     margin-top: 10px;
//   }
//   .checkboxes {
//     .MuiCheckbox-root {
//         color: rgb(224,193,100) !important;
//     }
//     label {
//       span:nth-child(2) {
//         font-size: 12px;
//         font-weight: 500;
//       }
//     }
//
//   }
// ;

export class ShowSavedResult extends Component<ShowResultProps, ShowResultState> {
  constructor(props: any = {params: {}}) {
    super(props);
    // set default state
    const defaultState: ShowResultState = {
      drawerIsOpen: false,
      priceRange: [0, 50000],
      styleLightDark: 3,
      styleColdWarm: 3,
      styleUniformContrast: 3,
      sizeRange: [1, 12],
      bathroomSize: 3,
      bathtub: false,
      shower: false,
      functionality: false,
      design: false,
      modern: false,
      backdropOpen: false,
    }

    // console.log('Props', props);
    this.state = defaultState;
    this.onClickReload = this.onClickReload.bind(this);
    this.toggleProductToKeepChange = this.toggleProductToKeepChange.bind(this);
    this.updateProductsInMoodboard = this.updateProductsInMoodboard.bind(this);
    this.refreshProduct = this.refreshProduct.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handlePriceRangeChange = this.handlePriceRangeChange.bind(this);
    this.handleSizeRangeChange = this.handleSizeRangeChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    this.generateNewMoodboard = this.generateNewMoodboard.bind(this);
  }

  componentDidMount() {
    this.props.setPassword();
    this.props.getProject(this.props.id);
    this.props.clearNewProjectData();
  }

  componentDidUpdate(prevProps: Readonly<ShowResultProps>, prevState: Readonly<ShowResultState>, snapshot?: any) {
    if (this.props.userQuery !== prevProps.userQuery) {
      console.log('New UserQuery', this.props.userQuery);
      const nextState: Partial<ShowResultState> = {};
      if (this.props.userQuery) {
        nextState.styleLightDark = this.props.userQuery.style_light_dark;
        nextState.styleColdWarm = this.props.userQuery.style_cold_warm;
        nextState.styleUniformContrast = this.props.userQuery.style_plain_contrast;
        nextState.bathtub = this.props.userQuery.bathtub;
        nextState.shower = this.props.userQuery.shower;
        nextState.priceRange = this.state.priceRange;
        if (this.props.userQuery.size_in_m2) {
          nextState.bathroomSize = this.props.userQuery.size_in_m2;
        }
        if (this.props.userQuery.budget_min !== undefined) {
          nextState.priceRange[0] = this.props.userQuery.budget_min;
        }

        if (this.props.userQuery.budget_max !== undefined) {
          nextState.priceRange[1] = this.props.userQuery.budget_max;
        }
      }

      this.setState({
        ...this.state,
        ...nextState,
      });
    }
  }

  // componentWillUnmount() {
  //   this.props.clearNewProjectData();
  // }

  onClickReload() {
    // console.log('On Click Reload!!');
    // return this.props.fetchResults(this.props.params);
  }

  getDecorateProducts(): Array<DbProductToModify> {
    return (this.props.products as DbProduct[]).map(product => ({
      ...product,
      locked: this.props.productTypesToKeep.includes(product.type)
    }));
  }

  toggleProductToKeepChange(productType: string) {
    return this.props.toggleProductTypeToKeep(productType);
  }

  refreshProduct(productType: string) {
    const productTypesToKeep = (this.props.products as DbProduct[]).filter(product => {
      return product.type !== productType;
    }).map(product => product.type);
    return this.props.updateProductsInMoodboard(
      this.props.id as string,
      productTypesToKeep,
      99999,
    );
  }
  updateProductsInMoodboard() {
    return this.props.updateProductsInMoodboard(
      this.props.id as string,
      this.props.productTypesToKeep,
      99999,
    );
  }

  toggleDrawer() {
    this.setState({
      drawerIsOpen: !this.state.drawerIsOpen,
    })
  }

  handlePriceRangeChange(newPriceRange: Array<number>) {
    this.setState({
      priceRange: newPriceRange,
    });
  }

  handleSizeRangeChange(newSizeRange: Array<number>) {
    this.setState({
      sizeRange: newSizeRange,
    });
  }

  handleCheckboxChange(key: 'bathtub' | 'shower' | 'functionality' | 'design' | 'modern') {
    this.setState({
      ...this.state,
      [key]: !this.state[key],
    });
  }

  handleNumberInputChange(key: 'styleLightDark' | 'styleColdWarm' | 'styleUniformContrast' | 'bathroomSize') {
    const multiply = key === 'bathroomSize' ? 1 : -1;
    return (event: React.ChangeEvent<{}>, value: number | number[]) => {
      this.setState({
        ...this.state,
        [key]: multiply * (value as number),
      });
    }
    // this.setState({
    //   ...this.state,
    //   [key]: !this.state[key],
    // });
  }

  generateNewMoodboard() {
    this.props.generateNewMoodboard(this.props.id as string, {
      style_light_dark: this.state.styleLightDark,
      style_cold_warm: this.state.styleColdWarm,
      style_plain_contrast: this.state.styleUniformContrast,
      budget_min: this.state.priceRange[0],
      budget_max: this.state.priceRange[1],
      size_in_m2: this.state.bathroomSize,
      // "style_modern": 3,
      // "style_eclectic": 3,
      // "style_functional": 3,
      // "style_original": 3,
      // "budget": "low",
      // "size": "small",
      shower: this.state.shower,
      bathtub: this.state.bathtub,
    });
    this.setState({
      backdropOpen: true,
    })
  }

  downloadOffer() {

  }

  render() {
    const {t} = this.props;
    if ((this.props.fetchInProgress || !this.props.dataFetched) || !this.props.params || !this.props.products) {
      return (
        <Wrapper>
          <SpinnerWrapper>
            <div>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </SpinnerWrapper>
        </Wrapper>
      );
    }

    let component;
    if (this.props.params.shower && this.props.params.bathtub) {
      component = <ShowResultBathtubShowerNew products={this.getDecorateProducts()} toggleProductTypeToKeep={this.toggleProductToKeepChange} refreshProduct={this.refreshProduct}/>
    } else if (this.props.params.shower === false) {
      component = <ShowResultBathtubNew products={this.getDecorateProducts()} toggleProductTypeToKeep={this.toggleProductToKeepChange} refreshProduct={this.refreshProduct}/>;
    } else {
      component = <ShowResultShowerNew products={this.getDecorateProducts()} toggleProductTypeToKeep={this.toggleProductToKeepChange} refreshProduct={this.refreshProduct}/>;
    }

    return (
      <Container>
        <Wrapper>
          <Box sx={{ display: { xs: 'none', sm: 'node', md: 'block' } }}>
            <BoxS>
              {component}
              <ShowResultBigSurfaceProducts
                products={this.getDecorateProducts()}
                toggleProductTypeToKeep={this.toggleProductToKeepChange}
                refreshProduct={this.refreshProduct}
              />
              <ShowResultVisualizationImage visualizationImageUrl={this.props.visualizationImageUrl!} />
            </BoxS>
            {/*<BoxS>*/}
            {/*  <button>Reload Products</button>*/}
            {/*</BoxS>*/}
            <BoxButtons>
              <PriceSetBox text={t('projectPage:productsPrice')} price={this.props.productsPrice}></PriceSetBox>
              <ButtonSetBox
                renderButtons={true}
                onClickReload={this.updateProductsInMoodboard}
                linkToDownload = {`${process.env.REACT_APP_API_HOST}/v1/projects/${this.props.id}/generate`}
              />
            </BoxButtons>
            <BoxBottom>
              {/*<Calculator products={this.props.products} bathroomSize={this.props.params.fit} />*/}
              <ShoppingList products={this.props.products} bathroomSizeInM2={this.props.userQuery?.size_in_m2}></ShoppingList>
            </BoxBottom>
          </Box>

          <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
            <BoxS>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}  sx={{
                  "align-self": "center",
                }}>
                  <Carousel height={520} autoPlay={false}>
                    {
                      [
                        <Box sx={{"transform": "scale(0.8)"}}>{component}</Box>,
                        <ShowResultBigSurfaceProducts
                          products={this.getDecorateProducts()}
                          toggleProductTypeToKeep={this.toggleProductToKeepChange}
                          refreshProduct={this.refreshProduct}
                        />,
                        <ShowResultVisualizationImage visualizationImageUrl={this.props.visualizationImageUrl!} small={true}/>
                      ]
                    }
                  </Carousel>
                </Grid>
              </Grid>
            </BoxS>
            <Box mt={4}></Box>
            <PriceSetBox text={t('projectPage:productsPrice')} price={this.props.productsPrice}></PriceSetBox>
            <Box mt={4}></Box>
            <ButtonSetBox
              renderButtons={true}
              onClickReload={this.updateProductsInMoodboard}
            />
            {/*</BoxButtons>*/}
            <BoxBottom>
              {/*<Calculator products={this.props.products} bathroomSize={this.props.params.fit} />*/}
              <ShoppingList products={this.props.products} bathroomSizeInM2={this.props.userQuery?.size_in_m2}></ShoppingList>
            </BoxBottom>
          </Box>
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <a href={`${process.env.REACT_APP_API_HOST}/v1/projects/${this.props.id}/generate`}>
                  {/* @ts-ignore */}
                  <ButtonSet type="secondary">{t('projectPage:downloadOffer')}</ButtonSet>
                </a>
              </Grid>
            </Grid>
          </Box>
          <FeedbackBox></FeedbackBox>

          <DrawerButtonBox {...{open: this.state.drawerIsOpen}}>
            <a onClick={this.toggleDrawer}>
              <img src={rolloutIcon}/>
            </a>
          </DrawerButtonBox>
          <Drawer open={this.state.drawerIsOpen} anchor="left">
            <MoodboardParams>
              <div className="closeWrapper">
                <a onClick={this.toggleDrawer}>
                  X
                </a>
              </div>
              <div className="content">
                {this.props.updateMoodboardNoResult && <h3>Nie można znaleźć nowego moodboardu dla podanych parametrów</h3>}
                <h3>{t('changeDrawer:changeSettings')}</h3>
                <h4>{t('changeDrawer:materialsAndColors')}</h4>
                <div className="slider">
                  <HorizontalSlider
                    min={-5}
                    max={-1}
                    step={2}
                    orientation={SliderOrientation.Horizontal}
                    topDescription={this.props.t('light')}
                    centerDescription={this.props.t('neutral')}
                    bottomDescription={this.props.t('dark')}
                    value={-1 * this.state.styleLightDark}
                    onChange={this.handleNumberInputChange('styleLightDark')}
                  />
                </div>
                <div className="slider">
                  <HorizontalSlider
                    min={-5}
                    max={-1}
                    step={2}
                    orientation={SliderOrientation.Horizontal}
                    topDescription={this.props.t('warm')}
                    centerDescription={this.props.t('neutral')}
                    bottomDescription={this.props.t('cold')}
                    value={-1 * this.state.styleColdWarm}
                    onChange={this.handleNumberInputChange('styleColdWarm')}
                  />
                </div>
                <div className="slider">
                  <HorizontalSlider
                      min={-5}
                      max={-1}
                      step={2}
                      orientation={SliderOrientation.Horizontal}
                      topDescription={this.props.t('uniform')}
                      bottomDescription={this.props.t('contrast')}
                      value={-1 * this.state.styleUniformContrast}
                      onChange={this.handleNumberInputChange('styleUniformContrast')}
                    />
                </div>

                <h4>{t('changeDrawer:bathroomParameters')}</h4>
                <h5>{t('changeDrawer:budget')}</h5>
                <div className="slider smallMarginTop">
                  <HorizontalSliderRange
                    min={0}
                    max={50000}
                    step={1}
                    // orientation={SliderOrientation.Horizontal}
                    topDescription={`0 ${getCurrencySuffix()}`}
                    bottomDescription={`50000 ${getCurrencySuffix()}`}
                    value={this.state.priceRange}
                    onChange={this.handlePriceRangeChange}
                  />
                </div>
                <h5>{t('changeDrawer:size')}</h5>
                <div className="slider smallMarginTop">
                  {/*<HorizontalSliderRange*/}
                  {/*  min={0}*/}
                  {/*  max={12}*/}
                  {/*  step={0.5}*/}
                  {/*  // orientation={SliderOrientation.Horizontal}*/}
                  {/*  topDescription="1.0 m2"*/}
                  {/*  bottomDescription="12.0 m2"*/}
                  {/*  defaultValue={this.state.sizeRange}*/}
                  {/*  onChange={this.handleSizeRangeChange}*/}
                  {/*/>*/}
                  <HorizontalSlider
                    min={0}
                    max={12}
                    step={0.5}
                    orientation={SliderOrientation.Horizontal}
                    topDescription="1.0 m2"
                    bottomDescription="12.0 m2"
                    value={this.state.bathroomSize}
                    onChange={this.handleNumberInputChange('bathroomSize')}
                    valueLabelDisplay="auto"
                    marks={[{value: 0, label: "0m2"}, {value: 6, label: "6m2"}, {value: 12, label: "12m2"}]}
                  />
                </div>
                <h5>{t('changeDrawer:equipment')}</h5>
                <div className="checkboxes">
                  <FormControlLabel
                    key="bathroom"
                    checked={this.state.bathtub}
                    control={<Checkbox />}
                    label={t('changeDrawer:bathtub')}
                    labelPlacement="end"
                    // label={`${item.name} ${item.desc ? item.desc : ''}`}
                    // labelPlacement={item.name}
                    onChange={() => (this.handleCheckboxChange('bathtub'))}
                  />

                  <FormControlLabel
                    key="shower"
                    checked={this.state.shower}
                    control={<Checkbox />}
                    label={t('changeDrawer:shower')}
                    labelPlacement="end"
                    // label={`${item.name} ${item.desc ? item.desc : ''}`}
                    // labelPlacement={item.name}
                    onChange={() => (this.handleCheckboxChange('shower'))}
                  />
                </div>
                <h5>{t('changeDrawer:additionalFeatures')}:</h5>
                <div className="checkboxes">
                  <FormControlLabel
                    key="functionality"
                    checked={this.state.functionality}
                    control={<Checkbox />}
                    label={t('changeDrawer:functionality')}
                    labelPlacement="end"
                    // label={`${item.name} ${item.desc ? item.desc : ''}`}
                    // labelPlacement={item.name}
                    onChange={() => (this.handleCheckboxChange('functionality'))}
                  />

                  <FormControlLabel
                    key="design"
                    checked={this.state.design}
                    control={<Checkbox />}
                    label={t('changeDrawer:design')}
                    labelPlacement="end"
                    // label={`${item.name} ${item.desc ? item.desc : ''}`}
                    // labelPlacement={item.name}
                    onChange={() => (this.handleCheckboxChange('design'))}
                  />

                  <FormControlLabel
                    key="modern"
                    checked={this.state.modern}
                    control={<Checkbox />}
                    label={t('changeDrawer:modernity')}
                    labelPlacement="end"
                    // label={`${item.name} ${item.desc ? item.desc : ''}`}
                    // labelPlacement={item.name}
                    onChange={() => (this.handleCheckboxChange('modern'))}
                  />
                </div>
                {/* @ts-ignore */}
                <ButtonSet type="secondary" onClick={this.generateNewMoodboard}>{t('changeDrawer:change')}</ButtonSet>
              </div>
            </MoodboardParams>
          </Drawer>
          <Backdrop className={this.props.classes.backdrop} open={this.props.requestInProgress}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Wrapper>
      </Container>
    );
  }
}

function mapStateToProps(state: {searchResult: any, foundProducts: any, projects: ProjectState, calculator: any}, ownProps: {match: {params: {id: string}}}) {
  const uuid: RegExp = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;
  console.log('--------UPDATE');
  const { searchResult,  projects , calculator } = state;
  const id: string = ownProps.match.params.id;
  // console.log('Project', projects);
  const projectResponse = projects.projectResponsesById[id];
  // let products: Array<DbProduct> = [];
  // if(projectResponse) {
  //   products = projectResponse.data.moodboard.products;
  // }
  // const products = searchResult.searchResultProductsById[id];
  return {
    id,
    dataFetched: !!projectResponse,
    params: projects.getProjectMoodboardParams(id),
    products: projects.getProjectProducts(id),
    visualizationImageUrl: projects.getProjectMoodboardVisualizationImageUrl(id),
    fetchInProgress: false,
    productTypesToKeep: searchResult.productTypesToKeep,
    userQuery: projects.getProjectMoodboardUserQuery(id),
    requestInProgress: projects.isRequestForOneProjectInProgress(id),
    totalPrice: calculator.getTotalPrice(),
    productsPrice: calculator.getProductsPrice(),
    updateMoodboardNoResult: projects.updateMoodboardNoResult,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSavedResult: (id: number | string) => dispatch<any>(fetchSavedResult(id)),
  toggleProductTypeToKeep(productType: string) {
    return dispatch<any>(toggleProductTypeToKeep(productType));
  },
  updateProductsInMoodboard(id: string, productTypesToKeep: Array<string>, upperPrice: number | null) {
    return dispatch<any>(updateMoodboard(id, productTypesToKeep));
  },
  generateNewMoodboard(id: string, params: any) {
    return dispatch<any>(generateNewMoodboard(id, params));
  },
  getProject(id: string) {
    return dispatch<any>(getProject(id))
  },
  clearNewProjectData() {
    return dispatch<any>(clearNewProjectData());
  },
  setPassword() {
    return dispatch(setPassword());
  }
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ShowSavedResult))));
