import React, {Component} from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import { withRouter } from 'react-router-dom';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

import ShowResultBathtubNew from './ShowResultBathtubNew'
import ShowResultShowerNew from './ShowResultShowerNew';
import ShowResultBathtubShowerNew from './ShowResultBathtubShowerNew';
import ShowResultVisualizationImage from './ShowResultVisualizationImage';
import ShowResultBigSurfaceProducts from './ShowResultBigSurfaceProducts';
import Calculator from './Calculator';
import FeedbackBox from './FeedbackBox'
import ShoppingList from "./ShoppingList";

import {
  changeProductTypesToKeep,
  fetchSavedResult,
  toggleProductTypeToKeep,
  updateMoodboard
} from "../redux/actions/savedResultActions";

interface ShowResultState {
  moodBoardMaxPrice: string;
  productTypesToKeep: Array<string>;
}

interface ShowResultProps {
  id: number | string,
  dataFetched?: boolean,
  params: {
    style_light_dark_type: string
    style_cold_warm_type: string,
    shower: boolean,
    bathtub: boolean,
    fit: string,
    bathroom_type: number,
    style_unique_type: string,
    style_functional_type: string,
    style_stylized_type: boolean,
    uniform: boolean,
    style_eclectic: boolean,
  },
  products: Array<DbProduct>,
  fetchInProgress?: boolean,
  visualizationImageUrl?: string,
  // // redirectToShowProducts?: boolean,
  // fetchResults: (params: any) => any,
  fetchSavedResult: (id: number | string ) => any
  toggleProductTypeToKeep: (productType: string ) => any
  updateProductsInMoodboard: (id: string, productTypesToKeep: Array<string>, upperPrice: number|null) => any
  changeProductTypesToKeep: (productTypes: Array<string>) => any
  productTypesToKeep: Array<string>

}

const Wrapper = styled.div`
    max-width: 1025px;
    min-width: 1025px;
    min-height: 551px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 24px 0;
`;

const SpinnerWrapper = styled.div`
  max-width: 1025px;
  min-width: 1025px;
  min-height: 551px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BoxBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: flex-start;
`;



class ShowMoodboardInternal extends Component<ShowResultProps, ShowResultState> {
  constructor(props: any = {params: {}}) {
    super(props);
    // set default state
    this.state = {
      moodBoardMaxPrice: '20000',
      productTypesToKeep: [],
    };
    this.onClickReload = this.onClickReload.bind(this);
    this.handleProductToKeepChange = this.handleProductToKeepChange.bind(this);
    this.updateProductsInMooadboard = this.updateProductsInMooadboard.bind(this);
    this.handleSelectUnSelectProductTypesToKeep = this.handleSelectUnSelectProductTypesToKeep.bind(this);
    this.changeMoodBoardMaxPrice = this.changeMoodBoardMaxPrice.bind(this);
  }

  componentDidMount() {
    this.props.fetchSavedResult(this.props.id);
  }

  onClickReload() {
    // console.log('On Click Reload!!');
    // return this.props.fetchResults(this.props.params);
  }

  updateProductsInMooadboard() {
    const moodBoardMaxPrice = Number.isNaN(parseInt(this.state.moodBoardMaxPrice, 10))
      ? null
      : parseInt(this.state.moodBoardMaxPrice, 10);
    // if (parseInt(this.state.moodBoardMaxPrice))
    return this.props.updateProductsInMoodboard(
      this.props.id as string,
      this.props.productTypesToKeep,
      moodBoardMaxPrice,
    );
  }
  handleProductToKeepChange(e: React.ChangeEvent<HTMLInputElement>) {
    return this.props.toggleProductTypeToKeep(e.target.name);
  }

  isProductTypeToKeepChecked(productType: string): boolean {
    return this.props.productTypesToKeep.includes(productType);
  }

  handleSelectUnSelectProductTypesToKeep() {
    const everyProductTypesIsSelected: boolean = this.props.products.every(product => {
      return this.props.productTypesToKeep.includes(product.type);
    });
    if (everyProductTypesIsSelected) {
      return this.props.changeProductTypesToKeep([]);
    } else {
      return this.props.changeProductTypesToKeep(
        this.props.products.map(product => product.type)
      );
    }
  }

  changeMoodBoardMaxPrice(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      ...this.state,
      moodBoardMaxPrice: e.target.value,
    });
  }

  render() {
    if (this.props.fetchInProgress || !this.props.dataFetched) {
      return (
        <Wrapper>
          <SpinnerWrapper>
            <div>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </SpinnerWrapper>
        </Wrapper>
      );
    }

    let component;
    if (this.props.params.shower && this.props.params.bathtub) {
      component = <ShowResultBathtubShowerNew products={this.props.products}/>
    } else if (this.props.params.shower === false) {
      component = <ShowResultBathtubNew products={this.props.products} />;
    } else {
      component = <ShowResultShowerNew products={this.props.products}/>;
    }

    return (
      <Wrapper>
        <Box>
          <h1>Internal</h1>
          {component}
          <ShowResultBigSurfaceProducts products={this.props.products} />
          <ShowResultVisualizationImage visualizationImageUrl={this.props.visualizationImageUrl!} />
        </Box>
        <div>
          <div>
            <Calculator products = {this.props.products} bathroomSize={this.props.params.fit} onlyProductsPrice={true}/>
          </div>
          <Button color="secondary" onClick={this.handleSelectUnSelectProductTypesToKeep}>
            Select/UnSelect Product Types To Keep
          </Button>
          <FormGroup>
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox checked={true} onChange={this.handleProductToKeepChange} name="floor" />}*/}
            {/*  label="Gilad Gray"*/}
            {/*/>*/}
            {this.props.products.map(product => (
              <FormControlLabel
                control={<Checkbox checked={this.isProductTypeToKeepChecked(product.type)} onChange={this.handleProductToKeepChange} name={product.type} />}
                label={product.type}
              />
            ))}
            <TextField id="standard-basic" label="Cena(max)" value={this.state.moodBoardMaxPrice} onChange={this.changeMoodBoardMaxPrice}/>
          </FormGroup>
          {/*<br />*/}
          <Button color="primary" onClick={this.updateProductsInMooadboard}>
            Update Products Moodboard
          </Button>
        </div>
        <BoxBottom>
          {/*<Calculator products={this.props.products} bathroomSize={this.props.params.fit} />*/}
          {/*<ButtonSetBox renderButtons={false} />*/}
          <ShoppingList products={this.props.products} bathroomSizeInM2={3}></ShoppingList>
        </BoxBottom>
        <FeedbackBox></FeedbackBox>
      </Wrapper>
    );
  }
}

function mapStateToProps(state: {searchResult: any, foundProducts: any}, ownProps: {match: {params: {id: string}}}) {
  const uuid: RegExp = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;
  const { searchResult } = state;
  const id: number | string = uuid.test(ownProps.match.params.id)
    ? ownProps.match.params.id
    : parseInt(ownProps.match.params.id);
  const products = searchResult.searchResultProductsById[id];
  return {
    id,
    dataFetched: !!products,
    params: searchResult.searchResultParamsById[id],
    products: searchResult.searchResultProductsById[id],
    visualizationImageUrl: searchResult.searchResultVisualizationImageUrlById[id],
    fetchInProgress: searchResult.inProgress,
    productTypesToKeep: searchResult.productTypesToKeep,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSavedResult: (id: number | string) => dispatch<any>(fetchSavedResult(id)),
  toggleProductTypeToKeep(productType: string) {
   return dispatch<any>(toggleProductTypeToKeep(productType));
  },
  updateProductsInMoodboard(id: string, productTypesToKeep: Array<string>, upperPrice: number|null) {
    return dispatch<any>(updateMoodboard(id, productTypesToKeep, upperPrice))
  },
  changeProductTypesToKeep(productTypes: Array<string>) {
    return dispatch<any>(changeProductTypesToKeep(productTypes));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowMoodboardInternal));
