import React, {Component} from 'react';
import styled from 'styled-components';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {fetchResults} from "../redux/actions";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Link, Redirect} from 'react-router-dom';

const Wrapper = styled.div`
    background-color: rgb(247, 246, 242);
    max-width: 1025px;
    min-width: 1025px;
    min-height: 551px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 24px;
`;

interface FormState {
  styleDarkLight?: string,
  styleColdWarm?: string,
  styleUnique?: string,
  styleUniqueEnabled?: boolean,
  styleStylized?: boolean,
  styleStylizedEnabled?: boolean,
  styleFunctional?: string,
  styleFunctionalEnabled?: boolean,
  styleEclectic?: boolean,
  styleEclecticEnabled?: boolean,
  styleUniform?: boolean,
  styleUniformEnabled?: boolean,
  shower?: boolean,
  bathtub?: boolean,
  bathroomSize?: string,
  bathroomType?: number,
  priceRange?: string,
  priceRangeEnabled?: boolean,
}

interface FormProps {
  fetchInProgress?: boolean,
  redirectToShowProducts?: boolean,
  fetchResults: (params: any) => any,
}

class SearchForm extends Component<FormProps, FormState> {
  constructor(props: any) {
    super(props);
    // "style_light_dark_type": "neutral",
    //   "style_cold_warm_type": "neutral",
    //   "style_unique_type": "low",
    //   "style_functional_type": "low",
    //   "style_stylized_type": false,
    //   "style_eclectic": false,
    //   "uniform": false,
    //   "shower": false,
    //   "bathroom_type": 8
    this.state = {
      styleDarkLight: 'neutral',
      styleColdWarm: 'neutral',
      styleUnique: 'low',
      styleUniqueEnabled: false,
      styleStylized: false,
      styleStylizedEnabled: false,
      styleFunctional: 'low',
      styleFunctionalEnabled: false,
      styleEclectic: false,
      styleEclecticEnabled: false,
      styleUniform: false,
      styleUniformEnabled: true,
      shower: false,
      bathtub: true,
      bathroomSize: 'small',
      bathroomType: 7,
      priceRange: 'cheap',
      priceRangeEnabled: false,
    };
    this.getChangeHandler = this.getChangeHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.search = this.search.bind(this);
  }

  getChangeHandler(key: keyof FormState, { checkbox, booleanValue, numberValue }: {checkbox?: boolean, booleanValue?: boolean, numberValue?: boolean} = {}): (change: any) => void {
    return (event) => {
      const nextState: {[key: string]: any} = {};
      if (checkbox) {
        nextState[key] = !this.state[key];
      } else if(booleanValue){
        nextState[key] = JSON.parse(event.target.value);
      } else if(numberValue){
        nextState[key] = parseInt(event.target.value);
      } else {
        nextState[key] = event.target.value ;
      }
      this.setState(nextState);
    }
  }

  changeHandler(event: any) {
    this.setState({styleDarkLight: event.target.value})
  }

  search() {// console.log(this);
    const params: object = {
      style_light_dark_type: this.state.styleDarkLight,
      style_cold_warm_type: this.state.styleColdWarm,
      shower: this.state.shower,
      bathtub: this.state.bathtub,
      fit: this.state.bathroomSize,
      bathroom_type: this.state.bathroomType,
      ...(this.state.styleUniqueEnabled && {style_unique_type: this.state.styleUnique}),
      ...(this.state.styleFunctionalEnabled && {style_functional_type: this.state.styleFunctional}),
      ...(this.state.styleStylizedEnabled && {style_stylized_type: this.state.styleStylized}),
      ...(this.state.styleUniformEnabled && {uniform: this.state.styleUniform}),
      ...(this.state.styleEclecticEnabled && {style_eclectic: this.state.styleEclectic}),
      ...(this.state.priceRangeEnabled && {price_range: this.state.priceRange}),
    };

    this.props.fetchResults(params);
  }

  render() {
    if (this.props.fetchInProgress) {
      return (<Redirect to="/results" />)
    }
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Form L</h2>
                <Form>
                  <Form.Group controlId="formBasicEmail" as={Row}>
                    <Col sm="1"/>
                    <Form.Label column sm="3">Style Light/Dark Type</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={this.state.styleDarkLight} onChange={this.getChangeHandler('styleDarkLight')}>
                        <option>light</option>
                        <option>neutral</option>
                        <option>dark</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" as={Row}>
                    <Col sm="1"/>
                    <Form.Label column sm="3">Style Cold/Warm Type</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={this.state.styleColdWarm} onChange={this.getChangeHandler('styleColdWarm')}>
                        <option>cold</option>
                        <option>neutral</option>
                        <option>warm</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formStyleUnique" as={Row}>
                    <Col sm="1">
                      <Form.Check inline label="" type="checkbox" checked={this.state.styleUniqueEnabled} onChange={this.getChangeHandler('styleUniqueEnabled', {checkbox: true})} />
                    </Col>
                    <Form.Label column sm="3">Style Unique</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={this.state.styleUnique} onChange={this.getChangeHandler('styleUnique')}>
                        <option>low</option>
                        <option>medium</option>
                        <option>high</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formStyleStylized" as={Row}>
                    <Col sm="1">
                      <Form.Check inline label="" type="checkbox" checked={this.state.styleStylizedEnabled} onChange={this.getChangeHandler('styleStylizedEnabled', {checkbox: true})} />
                    </Col>
                    <Form.Label column sm="3">Style Stylized</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={JSON.stringify(this.state.styleStylized)} onChange={this.getChangeHandler('styleStylized', {booleanValue: true})}>
                        <option>false</option>
                        <option>true</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formStyleFunctional" as={Row}>
                    <Col sm="1">
                      <Form.Check inline label="" type="checkbox" checked={this.state.styleFunctionalEnabled} onChange={this.getChangeHandler('styleFunctionalEnabled', {checkbox: true})} />
                    </Col>
                    <Form.Label column sm="3">Style functional</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={this.state.styleFunctional} onChange={this.getChangeHandler('styleFunctional')}>
                        <option>low</option>
                        <option>medium</option>
                        <option>high</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formEclectic" as={Row}>
                    <Col sm="1">
                      <Form.Check inline label="" type="checkbox" checked={this.state.styleEclecticEnabled} onChange={this.getChangeHandler('styleEclecticEnabled', {checkbox: true})} />
                    </Col>
                    <Form.Label column sm="3">Style eclectic</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={JSON.stringify(this.state.styleEclectic)} onChange={this.getChangeHandler('styleEclectic', {booleanValue: true})}>
                        <option>false</option>
                        <option>true</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formUniform" as={Row}>
                    <Col sm="1">
                      <Form.Check inline label="" type="checkbox" checked={this.state.styleUniformEnabled} onChange={this.getChangeHandler('styleUniformEnabled', {checkbox: true})} />
                    </Col>
                    <Form.Label column sm="3">Style uniform</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={JSON.stringify(this.state.styleUniform)} onChange={this.getChangeHandler('styleUniform', {booleanValue: true})}>
                        <option>false</option>
                        <option>true</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formShower" as={Row}>
                    <Col sm="1" />
                    <Form.Label column sm="3">Shower</Form.Label>
                    <Col sm="3">
                      <Form.Check inline label="Bathtub" type="checkbox" checked={this.state.bathtub} onChange={this.getChangeHandler('bathtub', {checkbox: true})} />
                      <Form.Check inline label="Shower" type="checkbox" checked={this.state.shower} onChange={this.getChangeHandler('shower', {checkbox: true})} />
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" as={Row}>
                    <Col sm="1"/>
                    <Form.Label column sm="3">Bathroom size:</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={this.state.bathroomSize} onChange={this.getChangeHandler('bathroomSize')}>
                        <option>small</option>
                        <option>medium</option>
                        <option>big</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formBathroomType" as={Row}>
                    <Col sm="1"/>
                    <Form.Label column sm="3">Bathroom type:</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={JSON.stringify(this.state.bathroomType)} onChange={this.getChangeHandler('bathroomType', {numberValue: true})}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="priceRange" as={Row}>
                    <Col sm="1">
                      <Form.Check inline label="" type="checkbox" checked={this.state.priceRangeEnabled} onChange={this.getChangeHandler('priceRangeEnabled', {checkbox: true})} />
                    </Col>
                    <Form.Label column sm="3">Price Range</Form.Label>
                    <Col sm="3">
                      <Form.Control as="select" value={this.state.priceRange} onChange={this.getChangeHandler('priceRange', {})}>
                        <option>cheap</option>
                        <option>medium</option>
                        <option>expensive</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Form>
                <Link to="/search">
                  <Button variant="primary" size="lg" active onClick={this.search}>Search</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}

function mapStateToProps(state: {foundProducts: any}) {
  const { foundProducts } = state;
  return {
    fetchInProgress: foundProducts.inProgress,
    redirectToShowProducts: foundProducts.redirectToShowProducts,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchResults: (params: object) => dispatch<any>(fetchResults(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
