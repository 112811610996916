import {ApiUser} from "./apiType";

export const START_LOG_IN = 'USER_START_LOG_IN';
export const LOG_IN_SUCCESS = 'USER_LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'USER_LOG_IN_ERROR';
export const USER_START_SIGN_UP = 'USER_START_SIGN_UP';
export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
export const USER_SIGN_UP_ERROR = 'USER_SIGN_UP_ERROR';
export const USER_FETCH_CURRENT_USER_IN_PROGRESS = 'USER_FETCH_CURRENT_USER_IN_PROGRESS';
export const USER_FETCH_CURRENT_USER_ERROR = 'USER_FETCH_CURRENT_USER_ERROR';
export const USER_FETCH_CURRENT_USER_SUCCESS = 'USER_FETCH_CURRENT_USER_SUCCESS';
export const USER_LOGOUT_IN_PROGRESS = 'USER_LOGOUT_IN_PROGRESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';


interface Action { }

export interface StartLogInUserAction extends Action {
  type: typeof START_LOG_IN,
  payload: {
  }
}
//
export interface SuccessLogInUserAction extends Action {
  type: typeof LOG_IN_SUCCESS,
  payload: ApiUser
}

export interface ErrorLogInUserAction extends Action {
  type: typeof LOG_IN_ERROR,
  payload: ErrorResponse
}

export interface UserStartSignUpAction extends Action {
  type: typeof USER_START_SIGN_UP,
  payload: {
  }
}
//
export interface UserSuccessSignUpAction extends Action {
  type: typeof USER_SIGN_UP_SUCCESS,
  payload: any
}

export interface UserErrorSignUpAction extends Action {
  type: typeof USER_SIGN_UP_ERROR,
  payload: ErrorResponse
}

export interface UserFetchCurrentUserInProgress extends Action {
  type: typeof USER_FETCH_CURRENT_USER_IN_PROGRESS,
  payload: {
  }
}
//
export interface UserFetchCurrentUserSuccess extends Action {
  type: typeof USER_FETCH_CURRENT_USER_SUCCESS,
  payload: ApiUser,
}

export interface UserFetchCurrentUserError extends Action {
  type: typeof USER_FETCH_CURRENT_USER_ERROR,
  payload: ErrorResponse
}

export interface UserLogoutInProgress extends Action {
  type: typeof USER_LOGOUT_IN_PROGRESS,
  payload: {

  }
}

export interface UserLogoutError extends Action {
  type: typeof USER_LOGOUT_ERROR,
  payload: {

  }
}

export interface UserLogoutSuccess extends Action {
  type: typeof USER_LOGOUT_SUCCESS,
  payload: {

  }
}


export type UserActionType = StartLogInUserAction | SuccessLogInUserAction | ErrorLogInUserAction
  | UserStartSignUpAction | UserSuccessSignUpAction | UserErrorSignUpAction
  | UserFetchCurrentUserInProgress | UserFetchCurrentUserSuccess | UserFetchCurrentUserError
  | UserLogoutInProgress | UserLogoutError | UserLogoutSuccess;
