import React from 'react';
import { useTranslation } from 'react-i18next';

import ContainerSetImg from '../ContainerSetImg';

const ShowResultVisualizationImage = ({visualizationImageUrl, small}: {visualizationImageUrl: string, small?: boolean}) => {
  const {t} = useTranslation('projectPage');
  let width = '273px';
  if (small) {
    width = '100%';
  }
  return (
    <ContainerSetImg picture={visualizationImageUrl} width={width} height='408px' alt="picture1" text={t('exampleVisualization')} />
  );
};

export default ShowResultVisualizationImage;
