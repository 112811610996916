import {APPLICATION_SET_PASSWORD} from "../types/applicationTypes";

export const setPassword = () => (
  {
    type: APPLICATION_SET_PASSWORD,
    payload: {
    }
  }
)

