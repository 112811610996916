import React, {Component} from 'react';
import styled from "styled-components";
import {
  Route,
  RouteProps,
} from "react-router-dom";


import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoginInfo from "../LoginInfo";


const MainWrapper = styled.div`
    display: flex;
    background: #F2F0ED;
    height: 100vh;
    width: 100vw;
`;

const Menu = styled.div`
  background: #333;
  height: 100vh;
  width: 100%;
`

const Header = styled.div`
  background: #eee;
  height: 70px;
  width: 100%;
  padding: 10px 20px;
  // height: 100vh;
  // width: 100%;
`


const Content = styled.div`
  background: yellow;
  overflow: auto;
  height: calc(100% - 70px);
  padding: 20px;
`
interface AppLayoutProps extends RouteProps{
  component: any,
}

export default function AppLayout({component: Component, ...rest}: AppLayoutProps) {
  return (
    <Route exact {...rest} render ={matchProps => (
      <Box overflow="hidden" height="100%">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{height: '100%'}}
        >
          {/*<Grid item xs={1}>*/}
          <Box width="260px" height="100%" overflow="hidden">
            <Menu>Menu</Menu>
          </Box>
          {/*</Grid>*/}
          {/*<Grid item xs={11}>*/}
          <Box flex="1" height="100%" overflow="hidden">
            <Header>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{height: '100%'}}
              >
                <Grid item>
                  <LoginInfo></LoginInfo>
                </Grid>
              </Grid>
            </Header>

            <Content>
              <Component {...matchProps} ></Component>
              {/*<Component {...matchProps} ></Component>*/}
              {/*<Component {...matchProps} ></Component>*/}
              {/*<Component {...matchProps} ></Component>*/}
              {/*<Component {...matchProps} ></Component>*/}
              {/*<Component {...matchProps} ></Component>*/}
            </Content>
          </Box>
            {/*<Header>*/}
            {/*    */}
            {/*</Header>*/}
            {/*<Grid container direction="column" justify="flex-start">*/}
            {/*  <Grid>*/}
            {/*    <Header>*/}
            {/*      <Box height="70px" width="100%">*/}
            {/*      </Box>*/}
            {/*    </Header>*/}
            {/*  </Grid>*/}
            {/*  <Grid></Grid>*/}
            {/*</Grid>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    )}></Route>
  )
}
