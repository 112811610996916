import React from 'react';
import styled from "styled-components";
import {
  Route,
  RouteProps,
} from "react-router-dom";


import Grid from "@mui/material/Grid";



const MainWrapper = styled.div`
    display: flex;
    background: #F2F0ED;
    height: 100vh;
    width: 100vw;
`;

interface LoginLayoutProps extends RouteProps{
  component: any,
}

export default function LoginLayout({component: Component, ...rest}: LoginLayoutProps) {
  return (
    <Route exact {...rest} render ={matchProps => (
      <MainWrapper>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Component {...matchProps} />

        </Grid>
      </MainWrapper>
    )}></Route>
  )
}
