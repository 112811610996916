import { combineReducers } from "redux";
import todos from "./todos";
import foundProducts from "./foundProducts"
import searchResult from "./searchResultReducer"
import calculator from "./calculatorReducer"
import userForm from "./userFormReducer"
import projects from './projectReducer';
import user from './userReducer';
import application from './applicationReducer';

export default combineReducers({ todos, foundProducts, searchResult, calculator, userForm, projects, user, application });
