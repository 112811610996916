import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ImageTitle from '../ImageTitle';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.width}%;

    fieldset {
        div {
            display: flex;
            flex-direction: column;

            label {
	              margin-top: 8px;
                margin-bottom: 8px;            
                span:nth-child(2) {
                    font-size: 12px;
                    font-weight: 500;
                }

                .MuiRadio-root {
                    color: rgb(224,193,100) !important;
                }
            }
        }
    }
`;

/*
RadioBathRoomParameterItem - ten komponent odpowiada za pojednynczy container na stronie drugiej zawiera on
zdjecie oraz radiobuttony,
argumenty: argumenty leca z komponentu BathroomParametersPage i mamy je tutaj nastepujace
img - zdjecie kazdego boxu
name - nazwa kazdego boxu
inputs - sa to radio buttony
*/

const  RadioBathRoomParameterItem = ({ img, name, inputs, onChange, value, width = 33}) =>  {
  const handleChange = event => {
    // setValue(event.target.value);
    return onChange(event.target.value);
  };

  return (
      <Wrapper width={width}>
        <ImageTitle img={img} text={name}/>
        <FormControl component="fieldset">
            <RadioGroup aria-label="position" name="position" value={value} onChange={handleChange} row>
                {inputs.map(item =>
                    <FormControlLabel
                        key={item.name}
                        value={item.value}
                        control={<Radio/>}
                        label={<div>{item.name}<span>{item.desc}</span></div>}
                        // labelPlacement={item.name}
                    />
                )}
            </RadioGroup>
        </FormControl>
      </Wrapper>
  );
}

export default RadioBathRoomParameterItem;
