import React from 'react';
import Title from '../Title';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const ImgWrapper = styled.img`
    width: ${({ width }) =>  width || '152px' };
    height: 70px;
    margin-bottom: 30px;
`;

/*
ImageTitle sa uzywane w pierwszej oraz drugiej stronie, ten komponent odpowiada za image z opisem
*/

const ImageTitle = ({img, text, alt, width}) => {
    return (
        <Wrapper>
            <ImgWrapper width={width} src={img} alt={alt}/>
            <Title>{text}</Title>
        </Wrapper>
    )
}

export default ImageTitle;
