import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {formatPrice} from "../../utils/formatters";


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    
    p {
        margin:0;
        font-weight: bold;
    }
    p:first-child {
        font-size: 14px;
    }
    p:nth-child(2) {
        font-size: 48px;
        span {
          font-size: 9px;        
        }
    }
    
    
    p:nth-child(3), p:last-child {
      font-size: 9px;
      margin: 2px 4px;    
      font-weight: normal;
    }
`;

/*
    PriceSetBox - komponent odpowiedzialny za wyswietlanie ceny zestawu jest deklarowany w setpage1,setpage2,setpage3
    tam mozemy wrzucic argumenty do niego
*/

const PriceSetBox = ({ text, price}) => {
  const { t, i18n } = useTranslation();
  return (
      <Wrapper>
          <p>{text}</p>
          <p>{formatPrice(price)} </p>
          <p>{t('projectPage:estimatePriceCopy')}</p>
          <p>{t('projectPage:estimatePriceCopy2')}</p>
      </Wrapper>
  )
}

export default PriceSetBox;
