import React from 'react';
import { SliderOrientation} from "@reach/slider";
import Slider from "@mui/material/Slider";
import "@reach/slider/styles.css";
import styled from 'styled-components';



const BoxSlider = styled.div<{orientation: SliderOrientation}>`

    display: flex;
    // flex-direction: ${( { orientation }) => orientation === 'vertical' ? 'column' : 'row'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 108px;
    width: 100%;

    [data-reach-slider] {
      width: 100%;
      padding: 10px 0 0 0;
    }
    [data-reach-slider-track] {
        border-radius: 1px;
        background: #000;
        width:${( { orientation }) => orientation === 'vertical' ? '1px' : '100%'};
        height:${( { orientation }) => orientation === 'vertical' ? '100%' : '1px'};
    }
    [data-reach-slider-handle] {
        width: 14px;
        height: 14px;
        border: 2px solid rgb(224,193,100);
        background: rgb(224,193,100);
        outline: none;
        cursor: pointer;
	       &:hover {
            box-shadow: 0px 0px 0px 9px rgba(0,0,0,0.04);
        }
        &:active {
            box-shadow: 0px 0px 0px 9px rgba(0,0,0,0.04);
            cursor: pointer;
        }        
    }
    [data-reach-slider-track-highlight] {
        background: #000;
    }
    [data-reach-slider-orientation="horizontal"] {
        padding-bottom: 20px;
    }
    
    .description {
      display: flex;
      justify-content: space-between;  
      width: 100%;
    }
    span {
        font-size: 12px;
        text-transform: uppercase;
        // min-width: ${( { orientation }) => orientation ==='horizontal' && '160px'};
        // &:first-child {
        //     text-align: ${( { orientation }) => orientation ==='horizontal' && 'right'};
        // }
      .bottom {
          height: 18px;
      }        
    }
    .MuiSlider-track {
      background-color: rgb(224,193,100);  
    }
    .MuiSlider-thumb {
      background-color: rgb(224,193,100);
    }
    
    .MuiSlider-rail {
      background-color: #000;
      opacity: 1;
      height: 1px; 
    }
    
    .MuiSlider-valueLabel {
      span:first-child {    
        background-color: rgb(224,193,100);
      }  
    }    
`;

/*
    SliderRange - komponent ktory wyswietla slidery -range
    topDescription - gorna wartosc - nazwa
    bottomDescription - dolna wartosc nazwa
    min - minimalna wartosc liczbowa slidera
    max - maxymalna wartosc liczowa slidera
    defaultvalue - miejsce w ktorym aktualnie znajduje sie koleczko slidera
    step - o ile koleczko ma przechodzic wartosci
*/

interface SliderRangeProps {
  orientation: SliderOrientation;
  topDescription: string;
  centerDescription?: string;
  bottomDescription: string;
  min: number;
  max: number;
  step: number;
  // defaultValue: number;
  value: number | number[];
  onChange: (a: any, value: number | number[]) => any
  valueLabelDisplay?: 'on' | 'auto' | 'off';
  marks?: Array<any>;
}

const HorizontalSlider = ({ orientation, topDescription, centerDescription, bottomDescription, min, max, step, value, onChange, valueLabelDisplay, marks }: SliderRangeProps) => {
  return (
    <BoxSlider orientation={orientation}>
      <div className="description">
        {topDescription && <span>{topDescription}</span>}
        {centerDescription && <span>{centerDescription}</span>}
        {bottomDescription && <span className="bottom">{bottomDescription}</span>}
      </div>
      {/*<Slider min={min} max={max} step={step} orientation={orientation} handleAlignment='center' defaultValue={defaultValue} onChange={onChange} />*/}
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        valueLabelDisplay={valueLabelDisplay}
        marks={marks}
      />
    </BoxSlider>
  )
}

export default HorizontalSlider;
