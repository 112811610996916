import React, {Component} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import { Redirect, withRouter} from 'react-router-dom';
import styled from 'styled-components';

import Spinner from 'react-bootstrap/Spinner';
import ShowResultBathtubNew from './ShowResultBathtubNew'
import ShowResultShowerNew from './ShowResultShowerNew';
import ShowResultBathtubShowerNew from './ShowResultBathtubShowerNew';
import Calculator from './Calculator';
import ShoppingList from "./ShoppingList";
import ButtonSetBox from './ButtonSetBox';

import {fetchResults} from "../redux/actions";
import FeedbackBox from "./FeedbackBox";

interface ShowResultState {
  history: any,
}

interface ShowResultProps extends WithTranslation{
  dataFetched?: boolean,
  params: {
    style_light_dark_type: string
    style_cold_warm_type: string,
    shower: boolean,
    bathtub: boolean,
    fit: string,
    size: string,
    bathroom_type: number,
    style_unique_type: string,
    style_functional_type: string,
    style_stylized_type: boolean,
    uniform: boolean,
    style_eclectic: boolean,
  },
  products: Array<DbProduct>,
  fetchInProgress?: boolean,
  // redirectToShowProducts?: boolean,
  fetchResults: (params: any) => any,
  searchResultId?: number,
  searchResultUuid?: number,
  visualizationImageUrl?: string,
  history: any,
}

const Wrapper = styled.div`
    max-width: 1025px;
    min-width: 1025px;
    min-height: 551px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 24px 0;
`;

const Box = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SpinnerWrapper = styled.div`
  max-width: 1025px;
  min-width: 1025px;
  min-height: 551px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: flex-start;
`;

export class ShowResult extends Component<ShowResultProps, ShowResultState> {
  constructor(props: any = {params: {}}) {
    super(props);
    this.onClickReload = this.onClickReload.bind(this);
    this.onClickShare = this.onClickShare.bind(this);
  }

  onClickReload() {
    return this.props.fetchResults(this.props.params);
  }

  onClickShare() {
    // return (<Redirect to="/"/>);
    this.props.history.push(`/results/${this.props.searchResultUuid}`);
  }

  render() {
    if (!this.props.dataFetched && !this.props.fetchInProgress) {
      return (<Redirect to="/"/>)
    }

    let component;
    if (this.props.fetchInProgress) {
      component = (
        <SpinnerWrapper>
          <div>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </SpinnerWrapper>
      );
    } else if (!this.props.products || !this.props.products.length) {
      component = <div><h2>Results not found</h2></div>;
    } else if (this.props.params.shower && this.props.params.bathtub) {
      component = <ShowResultBathtubShowerNew products={this.props.products}/>
    } else if (this.props.params.shower === false) {
      component = <ShowResultBathtubNew products={this.props.products} />;
    } else {
      component = <ShowResultShowerNew products={this.props.products}/>;
    }

    return (
      <Wrapper>
        {!this.props.fetchInProgress
          ?
          <Redirect to={`results/${this.props.searchResultUuid}`}/>
          // <Box>
          //   {component}
          //   <ShowResultBigSurfaceProducts products={this.props.products}/>
          //   <ShowResultVisualizationImage visualizationImageUrl={this.props.visualizationImageUrl}/>
          // </Box>
          :
          <Box>
            {component}
          </Box>
        }
        {!this.props.fetchInProgress &&
          <BoxBottom>
            < Calculator products = {this.props.products} bathroomSize={this.props.params.size} onlyProductsPrice={true}/>
            <ButtonSetBox onClickReload={this.onClickReload} onClickShare={this.onClickShare}/>
          </BoxBottom>
        }
        {!this.props.fetchInProgress && <ShoppingList products={this.props.products}></ShoppingList>}
        {!this.props.fetchInProgress && <FeedbackBox />}
        {!this.props.fetchInProgress && <Calculator
            products={this.props.products}
            bathroomSize={this.props.params.size}
          />
        }
      </Wrapper>
    );
  }
}

function mapStateToProps(state: {foundProducts: any}) {
  const { foundProducts } = state;
  return {
    dataFetched: foundProducts.dataFetched,
    params: foundProducts.params,
    products: foundProducts.products,
    fetchInProgress: foundProducts.inProgress,
    searchResultId: foundProducts.getSearchResultId(),
    searchResultUuid: foundProducts.getSearchResultUuid(),
    visualizationImageUrl: foundProducts.visualizationImageUrl,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchResults: (params: object) => dispatch<any>(fetchResults(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShowResult)));
