import React from 'react';
import {
  Route,
  RouteProps
} from "react-router-dom";


interface WrapperProps extends RouteProps {
  component: any,
}

const NoLayout = ({ component: Component, ...rest }: WrapperProps) => {
  return (
    <Route exact {...rest} render={(matchProps) => (
      <Component {...matchProps}></Component>
    )}></Route>
  )
};


export default NoLayout;
