import React, {useState, SyntheticEvent} from 'react';
import request from 'superagent';

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

interface UseFeedback {
  email: string,
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void,
  name: string,
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void,
  feedback: string,
  onChangeFeedback: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  sendFeedback: (e: SyntheticEvent) => void,
  feedbackSent: boolean,
  feedbackSentError: boolean,
  isFormValid: boolean,
  emailIsDirty: boolean,
  nameIsDirty: boolean,
  feedbackIsDirty: boolean,
}

interface RequestResponse {
  status: number,
  body?: any,
}
function isRequestResponse(error: any): error is RequestResponse {
  return error.status && error.body;
}

async function saveFeedback(name: string, email: string, feedback: string): Promise<RequestResponse> {
  try {
    const response = await request
      .post('/v1/user_feedback')
      .send({name, email, feedback});
    return response;
  } catch (error) {
    if (isRequestResponse(error)) {
      return error;
    }
    return {
      status: 500,
    }
  }
}

const useFeedback = (): UseFeedback => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackSentError, setFeedbackSentError] = useState(false);
  const [emailIsDirty, setEmailDirty] = useState(false);
  const [nameIsDirty, setNameDirty] = useState(false);
  const [feedbackIsDirty, setFeedbackDirty] = useState(false);

  return {
    email,
    onChangeEmail(e) {
      // emailIsDirty = true;
      setEmailDirty(true);
      setEmail(e.currentTarget.value);
    },
    name,
    onChangeName(e) {
      setNameDirty(true);
      setName(e.currentTarget.value);
    },
    feedback,
    onChangeFeedback(e) {
      // feedbackIsDirty = true;
      setFeedbackDirty(true);
      setFeedback(e.currentTarget.value);
    },
    async sendFeedback(e) {
      e.preventDefault();
      if (!(EMAIL_REGEX.test(email) && !!name && !!feedback)) {
        setEmailDirty(true);
        setNameDirty(true);
        setFeedbackDirty(true);
        return;
      }
      const response = await saveFeedback(name, email, feedback);
      if (response.status === 201) {
        setFeedbackSent(true);
      } else {
        setFeedbackSent(false);
        setFeedbackSentError(true);
      }
    },
    feedbackSent,
    feedbackSentError,
    isFormValid: EMAIL_REGEX.test(email) && !!name && !!feedback,
    nameIsDirty,
    feedbackIsDirty,
    emailIsDirty,
  }
};

export default useFeedback;

