import {
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  START_LOG_IN,
  USER_FETCH_CURRENT_USER_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_SIGN_UP_ERROR,
  USER_SIGN_UP_SUCCESS,
  USER_START_SIGN_UP,
  UserActionType
} from "../types/userTypes";
import {ApiUser} from "../types/apiType";

export interface UserReducerState {
  user?: ApiUser,
  logInProgress: boolean,
  logInError: ErrorResponse | null,
  logInErrorStatus?: number,
  getLogInErrorStatusCode: () => number | null,
  hasLogInError: () => boolean,
  signUpInProgress: boolean,
  signUpError: ErrorResponse | null,
  signUpErrorStatus?: number,
  getSignUpErrorStatusCode: () => number | null,
  hasSignUpError: () => boolean,
  signUpSuccess?: boolean | null,
};


const initialState: UserReducerState = {
  user: undefined,
  logInProgress: false,
  logInError: null,
  signUpInProgress: false,
  signUpError: null,
  // logInError: null,
  hasLogInError() {
    return  !!this.logInError;
  },

  getLogInErrorStatusCode() {
    if (!this.logInError?.status) {
      return null;
    }
    return this.logInError.status;
  },

  hasSignUpError() {
    return !!this.signUpError;
  },

  getSignUpErrorStatusCode() {
    if (!this.signUpError?.status) {
      return null;
    }
    return this.signUpError.status;

  }
};


export default function(state: UserReducerState = initialState, action: UserActionType) {
  switch (action.type) {
    case START_LOG_IN:
      return {
        ...state,
        logInProgress: true
      }

    case LOG_IN_ERROR:
      return {
        ...state,
        logInProgress: false,
        logInError: action.payload
      }

    case LOG_IN_SUCCESS:
      return {
        ...state,
        logInProgress: false,
        logInError: null,
        user: action.payload,
      }
    case USER_START_SIGN_UP:
      return {
        ...state,
        signUpInProgress: true
      }

    case USER_SIGN_UP_ERROR:
      return {
        ...state,
        signUpInProgress: false,
        signUpError: action.payload
      }

    case USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpInProgress: false,
        signUpError: null,
        signUpSuccess: true,
      }
    case USER_FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
      }
    default:
      return state;
  }
}
