import React from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';

import Title from '../Title';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {goToNextStep} from "../../redux/actions/userFormActions";

import {UserForm} from "../../redux/types/userFormTypes";
// import {Grid, Box } from "@material-ui/core";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import { Paper, Button } from '@mui/material'
import {createProjectUsingImage, getBathroomVisualizations} from "../../redux/actions/projectActions";
import {ProjectState} from "../../redux/reducers/projectReducer";
import {Redirect} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Pagination from '../Pagination';
import {withStyles} from "@mui/styles";

const WrapperWithPagination = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
    background-color: rgb(247, 246, 242);
    max-width: 1030px;
    //min-width: 1030px;
    min-height: 551px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 14px 0 24px 0;
`;

const styles = {
  // backdrop: {
  //   zIndex: 9999999,
  //   color: '#fff',
  // },
};
/*
BathroomAppearance - pierwsza strona z range slider
*/


interface ChooseYourStyleProps extends WithTranslation {
  userForm: UserForm,
  createProject: (params: MoodboardQuery) => (any),
  fetchBathroomVisualizations: () => (any),
  createdProjectId?: string | null;
  createdProjectInProgress?: boolean;
  shouldBathroomVisualizationBeFetched: boolean;
  bathroomVisualizations: BathroomVisualization[]
}

interface ChooseYourStyleState {
  userForm: UserForm
  moodboardQuery: MoodboardQuery | null;
}

// 1	dark	warm	false	true	true
export type MoodboardQuery = {
  bathroomType: number;
  styleLightDark: string;
  styleColdWarm: string;
  bathtub: boolean;
  shower: boolean;
  styleUniform: boolean;
}

type MoodboardChoice = {
  imageUrl: string;
  query: MoodboardQuery;
  // chooseMoodboard: (moodboardQuery: MoodboardQuery) => any;
}

type MoodboardChoiceProps = MoodboardChoice & {chooseMoodboard: (moodboardQuery: MoodboardQuery) => any};

const SpinnerWrapper = styled.div`
  width: 100%!important;
  height: 100%!important;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MoodBoardChoiceWrapper = styled.div`
  a:hover {
    cursor: pointer;
  }
`;

const MoodBoardChoice = (options: MoodboardChoiceProps) => {
  const onClick = () => {
    options.chooseMoodboard(options.query);
  };

  return (
    <MoodBoardChoiceWrapper>
      <a onClick={onClick}>
        <img src={options.imageUrl} width="100%"/>
      </a>
    </MoodBoardChoiceWrapper>
  );
};


const OverlayWrapper = styled.div`
  position: fixed;
  //display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;  
  display: block;
` ;

const ItemWprapper = styled.div`
  margin-top: 34px;
`;

class ChooseYourStyle extends React.Component<ChooseYourStyleProps, ChooseYourStyleState> {
  constructor(props: any = {params: {}}) {
    super(props);
    this.state = {
      userForm: Object.assign({}, props.userForm),
      moodboardQuery: null,
    };
    this.onClickNext = this.onClickNext.bind(this);
    this.chooseMoodboard = this.chooseMoodboard.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldBathroomVisualizationBeFetched) {
      this.props.fetchBathroomVisualizations();
    }
  }

  getOnSliderChange(key: keyof UserForm) {
    return (newValue: number) => {
      const nextUserForm: UserForm = this.state.userForm;
      (nextUserForm[key] as number) = newValue;
      this.setState({
        userForm: nextUserForm,
      })
    }
  }

  onClickNext() {
    // this.props.goToNextStep({userForm: this.state.userForm})
    // console.log('Next Step');
    return '/step2';
  }

  chooseMoodboard(moodboardQuery: MoodboardQuery) {
    // console.log('Choose---', moodboardQuery);
    this.setState({
      moodboardQuery,
    });

    return this.props.createProject(moodboardQuery);
  }

  getMooodboardChoices(): MoodboardChoice[] {
    return this.props.bathroomVisualizations.map(visualization => {
      return {
        imageUrl: visualization.image_url,
        query: {
          bathroomType: visualization.bathroom_type,
          styleLightDark: visualization.style_light_dark_type,
          styleColdWarm: visualization.style_cold_warm_type,
          bathtub: visualization.bathtub,
          shower: visualization.shower,
          styleUniform: visualization.uniform,
        }
      }
    });
  }

  render() {
    const {t} = this.props;
    if (this.props.createdProjectId) {
      return <Redirect to={`/results/${this.props.createdProjectId}`} />;
    }

    if (!this.props.userForm.bathtubShowerSet) {
      return <Redirect to='/' />;
    }

    return (
      // <WrapperWithPagination>
      <Container>
        <Wrapper>
          {(this.props.createdProjectInProgress) &&
            <OverlayWrapper>
              <SpinnerWrapper>
                <div>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  {/*<CircularProgress></CircularProgress>*/}
                </div>
              </SpinnerWrapper>
            </OverlayWrapper>
          }
          <Box mt={1}></Box>
          <Title type='big'>{t('Choose Your Style')}</Title>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <ItemWprapper>
              <Grid container spacing={2}>
                {this.getMooodboardChoices().map((choice) => (<Grid item md={2}><MoodBoardChoice {...choice} chooseMoodboard={this.chooseMoodboard}></MoodBoardChoice></Grid>))}
              </Grid>
            </ItemWprapper>
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
            <Box mt={1}></Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Carousel height={350}>
                  {this.getMooodboardChoices().map((choice) => (
                    <MoodBoardChoice {...choice} chooseMoodboard={this.chooseMoodboard}></MoodBoardChoice>
                    // <Paper>
                    //   <h2>TESt</h2>
                    //   <p>Legia</p>
                    //
                    //   <Button className="CheckButton">
                    //     Check it out!
                    //   </Button>
                    // </Paper>
                  ))}
                </Carousel>
              </Grid>
            {/*<ItemWprapper>*/}
            {/*  <Grid container spacing={2}>*/}
            {/*    {this.getMooodboardChoices().map((choice) => (<Grid item lg={2}><MoodBoardChoice {...choice} chooseMoodboard={this.chooseMoodboard}></MoodBoardChoice></Grid>))}*/}
            {/*  </Grid>*/}
            {/*</ItemWprapper>*/}
            </Grid>
          </Box>
        </Wrapper>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <Pagination
            page={2}
            pages={2}
            prevPath="/"
            nextPath={null}
            onClickNext={this.onClickNext}
            onClickPrevious={() => {}}
            disabled={false}
          />
        </Box>
      </Container>
      // </WrapperWithPagination>

    )
  }
}

function mapStateToProps(state: {userForm: any, projects: ProjectState}) {
  const { userForm, projects } = state;
  return {
    userForm: userForm.userForm,
    createdProjectId: projects.getNewProjectId(),
    createdProjectInProgress: projects.createInProgress,
    shouldBathroomVisualizationBeFetched: projects.shouldBathroomVisualizationBeFetched(),
    bathroomVisualizations: projects.getBathroomVisualizations({
      bathtub: userForm.userForm.bathtub,
      shower: userForm.userForm.shower,
    }),
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToNextStep: ({userForm}: { userForm: UserForm }) => dispatch(goToNextStep({userForm})),
  createProject(params: MoodboardQuery) {
    return dispatch<any>(createProjectUsingImage(params));
  },
  fetchBathroomVisualizations: () => dispatch<any>(getBathroomVisualizations()),
});

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ShowSavedResult))))V;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ChooseYourStyle)));
