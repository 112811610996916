import React from 'react';
import Slider from '@mui/material/Slider';
import "@reach/slider/styles.css";
import styled from 'styled-components';



const BoxSlider = styled.div<{}>`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 108px;
    width: 100%;

    [data-reach-slider] {
      width: 100%;
      padding: 10px 0 0 0;
    }
    [data-reach-slider-track] {
        border-radius: 1px;
        background: #000;
    }
    [data-reach-slider-handle] {
        width: 14px;
        height: 14px;
        border: 2px solid rgb(224,193,100);
        background: rgb(224,193,100);
        outline: none;
        cursor: pointer;
	       &:hover {
            box-shadow: 0px 0px 0px 9px rgba(0,0,0,0.04);
        }
        &:active {
            box-shadow: 0px 0px 0px 9px rgba(0,0,0,0.04);
            cursor: pointer;
        }        
    }
    [data-reach-slider-track-highlight] {
        background: #000;
    }
    [data-reach-slider-orientation="horizontal"] {
        padding-bottom: 20px;
    }
    
    .description {
      display: flex;
      justify-content: space-between;  
      width: 100%;
    }
    span {
        font-size: 12px;
        text-transform: uppercase;
      .bottom {
          height: 18px;
      }        
    }
    
    .MuiSlider-track {
      background-color: rgb(224,193,100);  
    }
    .MuiSlider-thumb {
      background-color: rgb(224,193,100);
    }
    
    .MuiSlider-rail {
      background-color: #000;
      opacity: 1;
      height: 1px; 
    }
    
    .MuiSlider-valueLabel {
      span:first-child {    
        background-color: rgb(224,193,100);
      }  
    }
    
`;

/*
    SliderRange - komponent ktory wyswietla slidery -range
    topDescription - gorna wartosc - nazwa
    bottomDescription - dolna wartosc nazwa
    min - minimalna wartosc liczbowa slidera
    max - maxymalna wartosc liczowa slidera
    defaultvalue - miejsce w ktorym aktualnie znajduje sie koleczko slidera
    step - o ile koleczko ma przechodzic wartosci
*/

interface SliderRangeProps {
  // orientation: SliderOrientation;
  topDescription: string;
  centerDescription?: string;
  bottomDescription: string;
  min: number;
  max: number;
  step: number;
  value: number[];
  onChange: (a: any) => any
}

const HorizontalSliderRange = ({ topDescription, centerDescription, bottomDescription, min, max, step, value, onChange }: SliderRangeProps) => {
  const handlePriceRange = (e: any, newValue: Array<number>|number) => {
    return onChange(newValue);
  }

  return (
    <BoxSlider>
      <div className="description">
        {topDescription && <span>{topDescription}</span>}
        {centerDescription && <span>{centerDescription}</span>}
        {bottomDescription && <span className="bottom">{bottomDescription}</span>}
      </div>
      <Slider
        value={value}
        onChange={handlePriceRange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        min={min}
        max={max}
        step={step}
        // getAriaValueText={valuetext}
      />
      {/*<Slider min={min} max={max} step={step} orientation={orientation} handleAlignment='center' defaultValue={defaultValue} onChange={onChange} />*/}
    </BoxSlider>
  )
}

export default HorizontalSliderRange;
