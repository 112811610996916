import {Dispatch} from "redux";
import request from "superagent";


import { ADD_TODO, TOGGLE_TODO, INCREMENT_COUNTER, FETCH_RESULTS_IN_PROGRESS, FETCH_RESULTS_DONE } from "./actionTypes";

let nextTodoId = 0;

export const addTodo = () => ({
  type: ADD_TODO,
  payload: {
    id: ++nextTodoId,
  }
});

export const toggleTodo = (id: number)=> ({
  type: TOGGLE_TODO,
  payload: { id }
});

export const incrementCounter = () => ({
  type: INCREMENT_COUNTER,
  payload: {},
});

export const fetchResultsInProgress = () => {
  return {
    type: FETCH_RESULTS_IN_PROGRESS,
    payload: {},
  }
};

export const fetchResultsDone = ({body, params}: {body: any, params: any}) => (
  {
    type: FETCH_RESULTS_DONE,
    payload: {
      body,
      params,
    }
  }
);

export const fetchResults = (params: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchResultsInProgress());
    const response: any = await request
      .post('/v1/products/find')
      .send(params);

    // console.log('Result', response);

    dispatch(fetchResultsDone({
      body: response.body,
      params,
    }));
  };
};
