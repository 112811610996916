import React, {Component} from "react";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { HashLink } from 'react-router-hash-link';
import { withTranslation, WithTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


import Arrow from "../../assets/img/arrow.svg";

import produkt_1 from "../../assets/img/produkt_1.png";
import door from "../../assets/img/door_.png";
import labor from "../../assets/img/labor.png";
import materials from "../../assets/img/materials.png";
import container from "../../assets/img/container.png";
import electrician from "../../assets/img/electrician.png";
import plumber from "../../assets/img/plumber.png";

import InputQuantity from "../InputQuantity";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {
  changeProductQuantity,
  initializeCalculator,
  toggleProductCheck,
  updatProductsInCalculator
} from "../../redux/actions/calculatorActions";
import {CalculatorProduct} from "../../redux/types/calculatorTypes";
import { formatPrice } from '../../utils/formatters';

const Wrapper = styled.div`
  background-color: #fff;
  max-width: 1276px;
  width: 100%;
  height: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 46px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface ItemProps {
  checked: boolean
}

const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: ${({ checked }) => (checked ? `#000` : "#9f9f9f")};

  .MuiCheckbox-root {
    color: ${({ checked }) =>
      checked ? "#ff4e00ff" : "#9f9f9f"} !important;
  }

  .MuiSvgIcon-root {
    font-size: 2.2rem !important;
  }
`;

const BoxS = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 150px;

  img {
    max-width: 54px;
    max-height: 54px;
    margin: 27px 0 27px 48px;
  }
`;

const BoxImageSmall = styled.div`
  img {
    max-width: 54px;
    max-height: 54px;
    margin: 0 0 27px 20px;
  }
`
const BoxInformation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 250px;

  p {
    font-weight: 600;
    line-height: 0.8;
  }

  span {
    font-size: 14px;
  }

  a {
    color: #858585;
    font-size: 14px;
    margin-top: 18px;
  }
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid black;
  padding: 20px 0; line-height: 1.2;
  p {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:first-child {
      max-width: 150px;
      width: 100%;
      text-align: left;
      flex: 1 1 150px;
    }
    &:nth-of-type(2) {
      max-width: 250px;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      flex: 1 1 250px;
    }
    &:nth-of-type(3) {
      max-width: 125px;
      width: 100%;      
      flex: 1 1 125px;
      justify-content: center;
    }
    &:nth-of-type(4) {
      max-width: 200px;
      width: 100%;
    ///background: rgb(224, 193, 100);
      flex: 1 1 200px;
      justify-content: center;
    }
    &:nth-of-type(5) {
      max-width: 200px;
      width: 100%;
      flex: 1 1 200px;
      justify-content: center;
    }
    &:nth-of-type(6) {
      max-width: 100px;
      width: 100%;
      flex: 1 1 100px;
      justify-content: center;
    }    
    span {
      max-width: 53%;
      margin: 0 auto;
      text-align: center;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin: 0;
      }
    }
  }
`;

const CodeBox = styled.div`
  flex: 0 1 125px;
  text-align: center;
`;

const PriceBox = styled.div`
  flex: 0 1 200px;
  text-align: center;  white-space: nowrap;
`;

const PriceBoxSummary = styled.div`
  flex: 0 1 100px;
  text-align: center;  white-space: nowrap;

`;

const QuantityBox = styled.div`
  flex: 0 1 200px;
  text-align: center;
`;

const Summary = styled.div`
  display: flex;
  border-top: 1px solid black;
  padding: 20px 0;
  width: 100%;

  div {
    flex: 1 1 50%;

    &:first-child {
      display: flex;
      align-items: center;

      span {
        font-size: 46px;
        font-weight: 700;
        margin-right: 24px;
      }
    }

    &:last-child {
      text-align: right;

      p {
        &:first-child {
          font-size: 24px;
        }
      }
    }
  }
`;

const H1 = styled.h1`
  color: #1c1b6d;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 80px;
`;


const PriceSummary = styled.div`
  white-space: nowrap;
`;

// function formatPrice(price: number): string {
//   return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(price)
// }

// const ShoppingList = () => {
interface ShoppingListProps extends WithTranslation {
  products: Array<DbProduct>
  calculatorProducts: Array<CalculatorProduct>
  totalPrice: number,
  bathroomSizeInM2?: number,
  initializeCalculator: (a: any) => any

  updateProductsInCalculator: (a: any) => any
  changeProductQuantity: ({id, quantity}: {id: number | string, quantity: number}) => any
  toggleProductCheck: ({ id }: { id: number | string }) => any
  notEditable?: boolean;
}

interface ShoppingListState {

}

class ShoppingList extends Component<ShoppingListProps, ShoppingListState>{
  static defaultProps = {
    bathroomSizeInM2: 3,
    notEditable: true,
  };

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initializeCalculator({
      products: this.props.products,
      bathroomSize: 'small',
      bathroomSizeInM2: this.props.bathroomSizeInM2,
    });
  }

  componentDidUpdate(prevProps:any) {
    let shouldUpdate = false;
    if (this.props.products.length !== prevProps.products.length) {
      shouldUpdate = true;
    }

    const newProductIds = this.props.products.map(product => product.id).sort().join(',');
    const prevProductIds = (prevProps.products as DbProduct[]).map(product => product.id).sort().join(',');
    console.log('--', newProductIds, prevProductIds);
    if (newProductIds !== prevProductIds) {
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      this.props.updateProductsInCalculator({
        products: this.props.products,
        bathroomSizeInM2: this.props.bathroomSizeInM2,

        // bathroomSize: 'small'
      });
    }
  }

  getProductCategory(product: CalculatorProduct): string {
    const translatedCategory = this.props.t(`productTypes:${product.type}`);
    // const commonCategory = this.props.t('productTypes:common');
    return translatedCategory[0].toUpperCase() + translatedCategory.slice(1);
  }

  getProductImage(product: CalculatorProduct): any {
    const dbProduct = this.props.products.find(dbProduct => (dbProduct.type === product.type));
    if (dbProduct) {
      return dbProduct.image_url;
    } else if (product.type === "door") {
      return door;
    } else if (product.type === "labor") {
      return labor;
    } else if (product.type === "materials") {
      return materials;
    } else if (product.type === "container") {
      return container;
    } else if (product.type === "electrician") {
      return electrician;
    } else if (product.type === "plumber") {
      return plumber;
    }

    return produkt_1;
  }

  getUnit(product: CalculatorProduct): string|any {
    const { t } = this.props;
    if (['floor', 'wet_wall', 'dry_wall'].includes(product.type)) {
      return (
        <>
          m<sup>2</sup>
        </>
      );
    }
    return t('projectPage:shoppingListPcs');
  }

  getFunctionToChangeProductQuantity(product: CalculatorProduct): any {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      return this.props.changeProductQuantity({
        id: product.id || 'ee',
        quantity: parseInt(event.target.value)
      })
    }
  }

  getStep(product: CalculatorProduct): number {
    if (['floor', 'dry_wall', 'wet_wall'].includes(product.type)) {
      return 0.1;
    }
    return 1;
  }

  getFunctionToDecrementProductQuantity(product: CalculatorProduct): any {
    const step = this.getStep(product);
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextQuantity = product.quantity ? product.quantity - step : 0;
      return this.props.changeProductQuantity({
        id: product.id || 'ee',
        quantity: Math.round((nextQuantity > 0 ? nextQuantity : 0) * 100)/100,
      })
    }
  }

  getFunctionToIncrementProductQuantity(product: CalculatorProduct): any {
    const step = this.getStep(product);
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      return this.props.changeProductQuantity({
        id: product.id || 'ee',
        quantity: Math.round((product.quantity ? product.quantity + step : 0) *100)/ 100,
      })
    }
  }

  getProductName(product: CalculatorProduct): any {
    const dbProduct = this.props.products.find(dbProduct => (dbProduct.type === product.type));
    if (dbProduct) {
      return `${dbProduct.manufacturer}${dbProduct.series ? ` ${dbProduct.series}` : ''}`;
    }
    return product.name;
  }

  toggleProductCheck(product: CalculatorProduct): any {
    return () => {
      this.props.toggleProductCheck({id: product.id});
    };
  }

  render() {
    const { t } = this.props;
    return (
      <Wrapper>
        {!this.props.notEditable && <HashLink to="#shoppingListHeader"><img src={Arrow} alt="arrow"/></HashLink>}
        {!this.props.notEditable && <H1>{this.props.t('shoppingList:header')}</H1>}
        <List>
          <Box sx={{ display: { xs: 'none', sm: this.props.notEditable ? 'block' : 'none', md: 'block' } }}>
            <Header id="shoppingListHeader">
              {!this.props.notEditable
                ?
                <p>
                  <span>{t('projectPage:shoppingListMark')}</span>
                </p>
                :
                <p>
                  <span>Liczba porządkowa</span>
                </p>
              }
              <p>
                <span>{t('projectPage:shoppingListProduct')}</span>
              </p>
              <p>
                <span>{t('projectPage:shoppingListProductCode')}</span>
              </p>
              <p>
                <span>{t('projectPage:shoppingListUnitPrice')}</span>
              </p>
              <p>
                <span>{t('projectPage:shoppingListQuantity')}</span>
              </p>
              <p>
                <span>{t('projectPage:shoppingListTotal')}</span>
              </p>
            </Header>
            {this.props.calculatorProducts.map(
              (product, i) => (
                ///checked na item wylacza box
                <Item checked={product.checked}>
                  <BoxS>
                    {!this.props.notEditable && <Checkbox checked={product.checked} onChange={this.toggleProductCheck(product)} disabled={false}/>}
                    {this.props.notEditable && <span>{i+1}.</span>}
                    <img src={this.getProductImage(product)} alt={product.name}/>
                  </BoxS>
                  <BoxInformation>
                    <p>{this.getProductName(product)}</p>
                    <span>{this.getProductCategory(product)}</span>
                    {/*<Link to={`http://tadom.pl`}>Przejdź do sklepu > </Link>*/}
                  </BoxInformation>
                  <CodeBox>{product.name}</CodeBox>
                  <PriceBox>
                    {/*{product.price}{t('projectPage:plnCurrency')} / {this.getUnit(product)}*/}
                    {formatPrice(product.price)} / {this.getUnit(product)}
                  </PriceBox>
                  {!this.props.notEditable ?
                    <QuantityBox>
                      <InputQuantity
                        quantity={product.quantity}
                        changeValue={this.getFunctionToChangeProductQuantity(product)}
                        decrementQuantity={this.getFunctionToDecrementProductQuantity(product)}
                        incrementQuantity={this.getFunctionToIncrementProductQuantity(product)}
                      />
                    </QuantityBox>
                    : <QuantityBox><p>{product.quantity}</p></QuantityBox>
                  }
                  <PriceBoxSummary>
                    <strong>{formatPrice(product.quantity ? Math.floor(product.price * product.quantity) : 0)} </strong>
                  </PriceBoxSummary>
                </Item>
              )
            )}
          </Box>
          {/*<Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>*/}
          <Box sx={{ display: { xs: 'block', sm: this.props.notEditable ? 'none' : 'block', md: 'none' } }}>
            <Grid container spacing={2}>
            {/*  {this.getMooodboardChoices().map((choice) => (<Grid item lg={2}><MoodBoardChoice {...choice} chooseMoodboard={this.chooseMoodboard}></MoodBoardChoice></Grid>))}*/}
            {/*</Grid>*/}
            {this.props.calculatorProducts.map(
              (product, i) => (
                <Grid item xs={12} className="ShoppingListMobile" sx={{
                  "border-bottom": "1px solid #000",
                  "border-color": "rgba(0, 0, 0, 0.2) transparent",
                  p: 0,
                  m: 0,
                  ml: 2,
                  pt: 3,
                  pb: 3,
                  ...((i+1) === this.props.calculatorProducts.length && {"border-bottom": 0})
                  // "margin-left": "16px",
                  // "padding-left": 0,
                  // "padding-bottom": "32px"
                }}>
                  <Grid container direction='column'>
                    <Box>
                      <Grid container>
                        <Grid item xs={1}>
                          <Item checked={product.checked}>
                            <BoxS>
                              <Checkbox checked={product.checked} onChange={this.toggleProductCheck(product)} disabled={false}/>
                            </BoxS>
                          </Item>
                          {/*<Checkbox checked={product.checked} onChange={this.toggleProductCheck(product)} disabled={false} sx={{color: "#ff4e00ff"}}/>*/}
                        </Grid>
                        <Grid item xs={3}>
                          <BoxImageSmall>
                            <img src={this.getProductImage(product)} alt={product.name}/>
                          </BoxImageSmall>
                        </Grid>
                        <Grid item xs={8}>
                          <BoxInformation>
                            <p>{this.getProductName(product)}</p>
                            <span>{this.getProductCategory(product)}</span>
                            <Box>
                              {formatPrice(product.price)} / {this.getUnit(product)}
                            </Box>
                          </BoxInformation>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid container>
                        <Grid item xs={4} sx={{
                          "align-self": "center",
                        }}>
                          <PriceBoxSummary>
                            <strong>{formatPrice(product.quantity ? Math.floor(product.price * product.quantity) : 0)} </strong>
                          </PriceBoxSummary>
                        </Grid>
                        <Grid item xs={8}>
                          <QuantityBox>
                            <InputQuantity
                              quantity={product.quantity}
                              changeValue={this.getFunctionToChangeProductQuantity(product)}
                              decrementQuantity={this.getFunctionToDecrementProductQuantity(product)}
                              incrementQuantity={this.getFunctionToIncrementProductQuantity(product)}
                            />
                          </QuantityBox>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )
            )}
            </Grid>
          </Box>
        </List>
        <Summary>
          <div>
            {/*<span>-10%</span>*/}
              <p>
                {!this.props.notEditable && t('projectPage:estimatePriceCopy')}
                {/*Skontaktuj się z naszymi partnerami o odbierz 10% zniżki na produkty*/}
                {/*wybrane z Tadom! <strong>Twój KOD: Tadom0sbf01</strong>*/}
              </p>
          </div>
          <div>
            <p>
              <strong>{formatPrice(this.props.totalPrice)} </strong>
              {/*<p>Podane ceny są orientacyjne i nie stanowią oferty handlowej. Szczegółową ofertę znajdziesz na stronie producenta lub jego dystrybutora</p>*/}
            </p>
            <span>{t('price:gross')}</span>
          </div>
        </Summary>
      </Wrapper>
    );
  }
}
// };

function mapStateToProps(state: {calculator: any}, props: any) {
  const { calculator } = state;
  // const id = ownProps.match.params.id;
  // const products = searchResult.searchResultProductsById[id];
  // console.log(id);
  return {
    calculatorProducts: calculator.getCalculatorItems(props.notEditable),
    totalPrice: calculator.getTotalPrice(props.notEditable),
    // products: ownProps.products,
    // id,
    // dataFetched: !!products,
    // params: searchResult.searchResultParamsById[id],
    // products: searchResult.searchResultProductsById[id],
    // visualizationImageUrl: searchResult.searchResultVisualizationImageUrlById[id],
    // fetchInProgress: searchResult.inProgress,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // fetchSavedResult: (id: number | string) => dispatch<any>(fetchSavedResult(id)),
  initializeCalculator: ({products, bathroomSize}: {products: Array<DbProduct>, bathroomSize: string}) => (
    dispatch<any>(initializeCalculator({ products, bathroomSize, editable: true }))
  ),

  updateProductsInCalculator: ({products}: {products: Array<DbProduct>}) => (
    dispatch<any>(updatProductsInCalculator({ products}))
  ),
  changeProductQuantity: ({id, quantity}: {id: string | number, quantity: number}) => (
    dispatch<any>(changeProductQuantity({id, quantity}))
  ),
  toggleProductCheck: ({id}: {id: string | number}) => (
    dispatch<any>(toggleProductCheck({id}))
  ),
});

// ShoppingList.d
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShoppingList));

