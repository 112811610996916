import {
  ADD_PRODUCT_TO_CALCULATOR,
  AddProductToCalculatorAction,
  CALCULATOR_TOGGLE_PRODUCT_CHECK,
  CalculatorActionType,
  CalculatorProduct,
  CHANGE_PRODUCT_QUANTITY,
  ChangeProductQuantityAction,
  INITIALIZE_CALCULATOR,
  ToggleProductCheck, UPDATE_PRODUCTS_IN_CALCULATOR
} from "../types/calculatorTypes";

// export const fetchSavedResultInProgress = (): SearchActionType => {
//   return {
//     type: FETCH_SAVED_RESULT_IN_PROGRESS,
//     // payload: {},
//   }
// };
//
// const fetchSavedResultDone = ({ id, params, products }: {id: number, params: object, products: Array<object>}): SearchResultDoneAction => {
//   return {
//     type: FETCH_SAVED_RESULT_DONE,
//     payload: {
//       id,
//       params,
//       products,
//     }
//   }
// };

export const initializeCalculator = ({ products, bathroomSize, editable = false, bathroomSizeInM2}: {products: Array<DbProduct>, bathroomSize: string, editable?: boolean, bathroomSizeInM2?: number}): CalculatorActionType => (
  {
    type: INITIALIZE_CALCULATOR,
    payload: {
      products,
      bathroomSize,
      editable,
      bathroomSizeInM2,
    }
  }
);

export const updatProductsInCalculator = ({products, bathroomSizeInM2}: {products: DbProduct[], bathroomSizeInM2?: number}) => (
  {
    type: UPDATE_PRODUCTS_IN_CALCULATOR,
    payload: {
      products,
      bathroomSizeInM2
    }
  }
)

export const addProductToCalculator = (payload: Pick<CalculatorProduct, 'price' | 'quantity' | 'name'>): AddProductToCalculatorAction => (
  {
    type: ADD_PRODUCT_TO_CALCULATOR,
    payload,
  }
);

export const changeProductQuantity = ({id, quantity}: {id: number | string, quantity: number }): ChangeProductQuantityAction => (
  {
    type: CHANGE_PRODUCT_QUANTITY,
    payload: {
      id,
      quantity,
    }
  }
)

export const toggleProductCheck = ({id}: {id: number | string }): ToggleProductCheck => (
  {
    type: CALCULATOR_TOGGLE_PRODUCT_CHECK,
    payload: {
      id,
    }
  }
)


