
export const INITIALIZE_CALCULATOR = "INITIALIZE_CALCULATOR";
export const ADD_PRODUCT_TO_CALCULATOR = "ADD_PRODUCT_TO_CALCULATOR";
export const CHANGE_PRODUCT_QUANTITY = "CHANGE_PRODUCT_QUANTITY";
export const CALCULATOR_TOGGLE_PRODUCT_CHECK = "CALCULATOR_TOGGLE_PRODUCT_CHECK";
export const UPDATE_PRODUCTS_IN_CALCULATOR = "UPDATE_PRODUCTS_IN_CALCULATOR";

export interface CalculatorProduct {
  type: string,
  price: number,
  id: number | string
  name: string
  quantity: number
  calculator_item_type?: string // db, constant, user
  lighting_type?: string
  checked: boolean
}

interface Action { }
// interface SerchResultDefaultAction extends Action {
//   type: typeof FETCH_SAVED_RESULT_IN_PROGRESS,
// }
//
export interface InitializeCalculatorAction extends Action {
  type: typeof INITIALIZE_CALCULATOR,
  payload: {
    products: Array<DbProduct>,
    bathroomSize: string,
    editable: boolean,
    bathroomSizeInM2?: number,
  }
}

export interface CalculatorUpdateProductsAction extends Action {
  type: typeof UPDATE_PRODUCTS_IN_CALCULATOR,
  payload: {
    products: Array<DbProduct>,
    bathroomSizeInM2?: number,
  }
}


export interface AddProductToCalculatorAction extends Action {
  type: typeof ADD_PRODUCT_TO_CALCULATOR,
  payload: Pick<CalculatorProduct, 'price' | 'quantity' | 'name'>
}

export interface ChangeProductQuantityAction extends Action {
  type: typeof CHANGE_PRODUCT_QUANTITY,
  payload: {
    id: number | string,
    quantity: number,
  }
}

export interface ToggleProductCheck extends Action {
  type: typeof CALCULATOR_TOGGLE_PRODUCT_CHECK,
  payload: {
    id: number | string,
  }
}

export type CalculatorActionType = InitializeCalculatorAction |
  AddProductToCalculatorAction |
  ChangeProductQuantityAction |
  ToggleProductCheck |
  CalculatorUpdateProductsAction;
