import i18  from '../i18n';
import {adjustPriceToMarket} from "./currencyUtils";
export function formatPrice(price: number): string {
  let currency = 'PLN';
  let language = 'pl-PL';
  if (i18.language === 'pt') {
    currency = 'BRL'
    language = 'pt-PT';
  }
  return new Intl.NumberFormat(language, { style: 'currency', currency }).format(adjustPriceToMarket(price));
}
