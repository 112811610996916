import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pt from  "./pt.json"

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pt,
  en: {
    translation: {
      "Bathroom style": "Bathroom style",
      "MATERIALS AND COLORS": "MATERIALS AND COLORS",
      'style_scale': 'STYLE (SCALE 1-5)',
      light: 'light',
      dark: 'dark',
      warm: 'warm',
      cold: 'cold',
      uniform: 'uniform',
      contrast: 'contrast',
      neutral: 'neutral',
      modern: 'modern',
      eclectic: 'eclectic',
      functional: 'functional',
      unique: 'unique',
      'back to main website': 'Back to main website',
      next: 'Next',
      prev: 'Prev',
      bathroom_parameters: 'Bathroom parameters',
      budget: 'Budget',
      low: 'low',
      medium: 'medium',
      high: 'high',
      size: 'size',
      size_small: 'small',
      size_small_copy: 'under 4 m²',
      size_medium: 'medium',
      size_medium_copy: '4 to 7 m²',
      size_big: 'big',
      size_big_copy: 'over 7 m²',
      equipment: 'equipment',
      bathtub: 'bathtub',
      shower: 'shower',
      bathtub_and_shower: 'bathtub and shower',
      technical: 'technical',
      boiler: 'boiler',
      boiler_desc: '',
      gas_water_heater: 'gas water heater',
      window: 'window',
      washing_machine: 'washing machine',
      washing_machine_desc: 'and/or dryer',
      bevels: 'bevels',
      bevels_desc: 'bathroom in the attic',
      your_character: 'Your character',
      early_bird: 'early bird',
      night_owl: 'night owl',
      king_of_the_dancefloor: 'king of the dancefloor',
      wallflower: 'wallflower',
      clockwork_precision: 'clockwork precision',
      cavalryman_flair: 'cavalryman\'s flair',
      sense: 'sense',
      sensibility: 'sensibility',
      proud_peackock: 'proud peackock',
      mouseburger: 'mouseburger',
      perfect_composure: 'perfect composure',
      volcanic_energy: 'volcanic energy',
      set_price: 'Set price',
      products_set_price: 'Products price',
      change_set: 'Change set',
      this_is_perfect: 'This is perfect',
      thanks_for_use: 'Thanks for using Tadom! Let us know if you like the latest version, what we should improve and how else we can help you in the preparation for bathroom renovation',
      give_feedback: 'Give us a feedback',
      name: 'Name',
      message: 'Feedback',
      required_fields: 'Required fields',
      send: 'Send',
      feedback_sent: 'Thank you for the feedback! This will help us get Tadom in your future.',
      feedback_error: 'Oops ... Something went wrong please try again in a moment',
      'Choose Your Style': 'Choose the style of your bathroom',
      'choose_bathtub_shower_header': 'What should be in your bathroom?'
    },
    productTypes: {
      washbasin_faucet: 'washing faucet',
      wall_lighting: 'wall lighting',
      mirror: 'mirror',
      shower_faucet: 'shower faucet',
      wet_wall: 'wet wall',
      heater: 'heater',
      bathtub_faucet: 'bathtub faucet',
      shower_cubicle: 'shower cubicle',
      floor: 'floor',
      toilet: 'toilet',
      ceil_lighting: 'ceil lighting',
      dry_wall: 'dry wall',
      shower_base: 'shower_base',
      bathtub: 'bathtub',
      washbasin: 'washbasin',
      washbasin_cabinet: 'washbasin cabinet',
      custom: 'custom element',
      labor: 'labor',
      materials: 'materials',
      container: 'container',
      door: 'door',
      electrician: 'electrician with materials',
      plumber: 'plumber with materials',
    },
    shoppingList: {
      congratulations: 'Congratulations',
      header: 'Shopping list / cost estimate',
      moodboardCreation: 'You have just created your unique moodboard for your bathroom.',
    },
    projectPage: {
      productsPrice: 'Products price',
      plnCurrency: 'PLN',
      estimatePriceCopy: 'The given price is indicative and does not constitute a commercial offer. A detailed offer can be found on the website of the producers or their distributors.',
      estimatePriceCopy2: 'Price for products visible on the visualization (does not include labor and other costs defined further.',
      exampleVisualization: 'Sample bathroom interior design',
      anotherMoodboard1: 'Do you want to see other bathroom arrangements?',
      anotherMoodboardLink: 'Complete the survey again,',
      anotherMoodboard2: 'changing Your answers, including bathroom parameters.',
      shoppingListMark: 'Select product',
      shoppingListProduct: 'Product',
      shoppingListProductCode: 'Product code',
      shoppingListUnitPrice: 'Unit price',
      shoppingListQuantity: 'Quantity',
      shoppingListTotal: 'Total price',
      shoppingListPcs: 'pcs.',
      shoppingListGross: 'gross',
      downloadOffer: 'Download offer',
    },
    changeDrawer: {
      changeSettings: 'Change settings',
      materialsAndColors: 'Materials and colors',
      light: 'LIGHT',
      neutral: 'NEUTRAL',
      dark: 'DARK',
      warm: 'WARM',
      cold: 'COLD',
      uniform: 'UNIFORM',
      contrast: 'CONTRAST',
      bathroomParameters: 'Bathroom Parameters',
      budget: 'Budget',
      size: 'Size',
      equipment: 'Equipment',
      bathtub: 'BATHTUB',
      shower: 'SHOWER',
      additionalFeatures: 'Additional Features',
      functionality: 'FUNCTIONALITY',
      design: 'DESIGN',
      modernity: 'MODERNITY',
      change: 'Change',
    },
    "price": {
      "gross": "gross"
    },
    "passwordSimple": {
      introducePassword: 'Enter your password',
      start: 'Start',
      password: 'password',
    }
  },
  pl: {
    translation: {
      "Bathroom style": "Wygląd łazienki",
      "MATERIALS AND COLORS": "MATERIAŁY I KOLORY",
      'style_scale': 'STYL (SKALA 1-5)',
      light: 'jasne',
      dark: 'ciemne',
      warm: 'ciepłe',
      cold: 'zimne',
      neutral: 'neutralne',
      uniform: 'jednolite',
      contrast: 'kontrastowe',
      modern: 'nowoczesny',
      eclectic: 'eklektyczny',
      functional: 'funkcjonalny',
      unique: 'oryginalny',
      'back to main website': 'Wróc do strony głównej',
      next: 'Dalej',
      prev: 'Wróć',
      bathroom_parameters: 'Parametry łazienki',
      budget: 'Budżet',
      low: 'oszczędny',
      medium: 'standardowy',
      high: 'nieograniczony',
      size: 'wielkość',
      size_small: 'mała',
      size_small_copy: 'poniżej 4 m²',
      size_medium: 'średnia',
      size_medium_copy: '4 to 7 m²',
      size_big: 'duża',
      size_big_copy: 'powyżej 7 m²',
      equipment: 'wyposażenie',
      bathtub: 'wanna',
      shower: 'prysznic',
      bathtub_and_shower: 'wanna i prysznic',
      technical: 'elementy techniczne',
      boiler: 'boiler',
      boiler_desc: 'lub podgrzewacz',
      gas_water_heater: 'piecyk gazowy',
      window: 'okno',
      washing_machine: 'pralka',
      washing_machine_desc: 'i/lub suszarka',
      bevels: 'skosy',
      bevels_desc: 'łazienka na poddaszu',
      your_character: 'Ty i Twoje zwyczaje',
      early_bird: 'ranny ptaszek',
      night_owl: 'nocny marek',
      king_of_the_dancefloor: 'król parkietu',
      wallflower: 'podpieracz ścian',
      clockwork_precision: 'szwajcarska precyzja',
      cavalryman_flair: 'ułańska fantazja',
      sense: 'romantyczny artysta',
      sensibility: 'rozważny inżynier',
      proud_peackock: 'dumny paw',
      mouseburger: 'szara myszka',
      perfect_composure: 'oaza spokuju',
      volcanic_energy: 'wulkan energii',
      set_price: 'Cena zestawu',
      products_set_price: 'Cena produktów',
      change_set: 'Zmień zestaw',
      this_is_perfect: 'Ten jest idealny',
      thanks_for_use: 'Dzięki za skorzystanie z Tadom! Daj nam znać czy podoba Ci się najnowsza wersja, co powinniśmy ulepszyć i jak jeszcze możemy pomóc Ci w przygotowaniach do remontu łazienki.',
      give_feedback: 'Zostaw nam swój feedback',
      name: 'Imię',
      message: 'Wiadomość',
      required_fields: 'Pola obowiązkowe',
      send: 'Wyślij',
      feedback_sent: 'Dziękujemy za feedback! Pomoże nam to w przyszłości dostować Tadom do Twoich potrzeb.',
      feedback_error: 'Ups... Coś poszło nie tak proszę spróbować ponownie za cwhilę.',
      'Choose Your Style': 'Wybierz styl swojej łazienki',
      'choose_bathtub_shower_header': 'Co ma się znaleźć w Twojej łazience?'
    },
    productTypes: {
      washbasin_faucet: 'bateria umywalkowa',
      wall_lighting: 'światło ścienne',
      mirror: 'lustro',
      shower_faucet: 'bateria prysznicowa',
      wet_wall: 'płytka ścienna',
      heater: 'grzejnik',
      bathtub_faucet: 'bateria wannowa',
      shower_cubicle: 'prysznic',
      floor: 'płytka podłogowa',
      toilet: 'wc',
      ceil_lighting: 'światło sufitowe',
      dry_wall: 'ściana sucha',
      shower_base: 'brodzik',
      bathtub: 'wanna',
      washbasin: 'umywalka',
      washbasin_cabinet: 'szafka umywalkowa',
      custom: 'element dodatkowy',
      labor: 'Praca wykończeniowa',
      materials: 'Materiały',
      container: 'Kontener na odpady',
      door: 'drzwi',
      electrician: 'elektryk wraz z materiałem',
      plumber: 'hydraulik wraz z materiałem',
    },
    shoppingList: {
      congratulations: 'Brawo',
      header: 'Lista zakupowa / kosztorys',
      moodboardCreation: 'Właśnie stworzyłeś swój unikalny moodboard dla Twojej łazienki.'
    },
    projectPage: {
      productsPrice: 'Cena zestawu',
      plnCurrency: 'zł',
      estimatePriceCopy: 'Podana cena jest orientacyjna i nie stanowi oferty handlowej. Szczegółową ofertę znajdziesz na stronie producentów lub ich dystrybutorów.',
      estimatePriceCopy2: 'Cena za produkty widoczne na wizualizacji(nie zawiera kosztów robocizny i innych zdefiniowanych dalej.',
      exampleVisualization: 'Przykładowa aranżacja wnętrza łazienki',
      anotherMoodboard1: 'Chcesz zobaczyć inne aranżacje łazienki?',
      anotherMoodboardLink: 'Uzupełnij ponowie ankietę,',
      anotherMoodboard2: 'zmieniając swoje odpowiedzi, w tym parametry łazienki.',
      shoppingListMark: 'Zaznacz produkt',
      shoppingListProduct: 'Produkt',
      shoppingListProductCode: 'Kod produktu',
      shoppingListUnitPrice: 'Cena jednostkowa',
      shoppingListQuantity: 'Ilość',
      shoppingListTotal: 'Suma',
      shoppingListPcs: 'szt',
      shoppingListGross: 'brutto',
      downloadOffer: 'Ściągnij ofertę',
      // productsPriceInfo: 'Podana cena jest orientacyjna i nie stanowi oferty handlowej. Szczegółową ofertę znajdziesz na stronie producentów lub ich dystrybutorów.',
      // productsPriceInclude: 'Cena za produkty widoczne na wizualizacji(nie zawiera kosztów robocizny i innych zdefiniowanych dalej.',
    },
    changeDrawer: {
      changeSettings: 'Zmień ustawienia',
      materialsAndColors: 'Materiały i Kolory',
      light: 'JASNY',
      neutral: 'NEUTRALNY',
      dark: 'CIEMNY',
      warm: 'CIEPŁY',
      cold: 'ZIMNY',
      uniform: 'JEDNOLITY',
      contrast: 'KONTRASTOWY',
      bathroomParameters: 'Parametry łazienki',
      budget: 'Budżet',
      size: 'Wielkość',
      equipment: 'Wyposażenie',
      bathtub: 'WANNA',
      shower: 'PRYSZNIC',
      additionalFeatures: 'Dodatkowe Cechy',
      functionality: 'FUNKCJONALNOŚĆ',
      design: 'DESIGN',
      modernity: 'NOWOCZESNOŚĆ',
      change: 'Zmień',
    },
    "price": {
      "gross": "brutto"
    },
    "passwordSimple": {
      introducePassword: 'Wprowadź hasło',
      start: 'Zaczynamy',
      password: 'hasło',
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pl",

    keySeparator: false, // we do not use keys in form messages.welcome
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

window.ii = i18n;
export default i18n;
