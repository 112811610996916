import React from 'react';
import ContainerSetWall from '../ContainerSetWall';

import wall1 from '../../assets/img/wall1_1.png';
import wall2 from '../../assets/img/wall1_2.png';
import wall3 from '../../assets/img/wal1_3.png';

const productPositionByType: {[key: string]: any} = {
  dry_wall: {img:wall1,text:'wall1',price:1234,width:'169px', height:'277px;'},
  wet_wall: {img:wall2,text:'wall1',price:1234,width:'143px', height:'191px'},
  floor: {img:wall3,text:'wall1', price:1234, width:'139px', height:'190px'}
};

interface ShowResultBigSurfaceProducts {
  products: Array<any>;
  toggleProductTypeToKeep?: (productType: string) => any;
  refreshProduct?: (productType: string) => any;
}

const ShowResultBigSurfaceProducts = ({products, toggleProductTypeToKeep, refreshProduct}: ShowResultBigSurfaceProducts) => {

  const bigSurfaceProductsOrder: Array<string> = ['dry_wall', 'wet_wall', 'floor'];
  const productsByType: {[key: string]: {}} = {};
  products.forEach(product => {
    productsByType[product.type] = product;
  });

  const bigSurfaceProducts: Array<any> = bigSurfaceProductsOrder.map(productType => {
    const product: any = productsByType[productType];
    return Object.assign({}, productPositionByType[productType], {
      img: product.image_url,
      price: `${product.price}/m2`,
      text: `${product.manufacturer && product.manufacturer} ${product.series && product.series}`,
      locked: product.locked,
      type: product.type,
    });
  });

  return (
    <ContainerSetWall
      items={bigSurfaceProducts}
      toggleProductTypeToKeep={toggleProductTypeToKeep}
      refreshProduct={refreshProduct}
    />
  );
};

export default ShowResultBigSurfaceProducts;
