import {GO_TO_NEXT_STEP, GO_TO_PREV_STEP, USER_FORM_CLEAR, UserForm, UserFormActionType} from "../types/userFormTypes";

const defaultUserForm: UserForm = {
  styleLightDark: 3,
  styleColdWarm: 3,
  stylePlainContrast: 3,
  styleModern: 3,
  styleEclectic: 3,
  styleFunctional: 3,
  styleOriginal: 3,
  budget: 'low',
  size: 'small',
  shower: true,
  bathtub: false,
  boiler: true,
  geyser: false,
  window: false,
  washingMachine: false,
  attic: false,
  earlyBird: 2,
  danceKing: 2,
  swissPrecision: 2,
  romanticArtist: 2,
  proudAsPeacock: 2,
  oasisOfCalm: 2,
  // Uncomment
  bathtubShowerSet: true,
}

export interface State {
  userForm: UserForm,
  step: number,
  filledSteps: Array<number>,
};


const initialState = {
  step: 1,
  filledSteps: [],
  userForm: Object.assign({}, defaultUserForm)
};

export default function(state: State = initialState, action: UserFormActionType) {
  const nextState = {...state};
  switch (action.type) {
    case GO_TO_NEXT_STEP:
      nextState.userForm = action.payload.userForm;
      nextState.step = nextState.step + 1;
      return nextState;

    case GO_TO_PREV_STEP:
      nextState.userForm = action.payload.userForm;
      nextState.step = nextState.step - 1;
      return nextState;

    case USER_FORM_CLEAR:
      nextState.userForm = Object.assign({}, defaultUserForm);
      return nextState;
    // case:
    default:
      return nextState;
  }
}
