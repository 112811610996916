import { FETCH_RESULTS_IN_PROGRESS, FETCH_RESULTS_DONE } from "../actionTypes";

const initialState: State = {
  products: [],
  inProgress: false,
  getSearchResultId(): number | undefined {
    return this.meta && this.meta.search_result_id;
  },
  getSearchResultUuid(): string| undefined {
    return this.meta && this.meta.search_result_uuid;
  },
  visualizationImageUrl: '',
};

interface State {
  products: Array<object>,
  visualizationImageUrl: string,
  inProgress: boolean,
  meta?: {
    search_result_id: number
    search_result_uuid: string,
  },
  getSearchResultId: () => number | undefined
  getSearchResultUuid: () => string | undefined
};

interface Action {
  payload: object,
  type: string,
}

interface TodoAction extends  Action{
  payload: {
    id: number,
    content?: string,
    params?: any,
    body?: {
      data: any,
      meta: any,
    }
  }
}

export default function(state: State = initialState, action: TodoAction) {
  switch (action.type) {
    case FETCH_RESULTS_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
        dataFetched: false,
      };
    case FETCH_RESULTS_DONE:
      return {
        ...state,
        inProgress: false,
        redirectToShowProducts: true,
        params: action.payload.params,
        products: action.payload.body && action.payload.body.data && action.payload.body.data.products,
        visualizationImageUrl: action.payload.body && action.payload.body.data && action.payload.body.data.visualization_image_url,
        dataFetched: true,
        meta: action.payload.body && action.payload.body.meta,
      };
    default:
      return state;
  }
}
