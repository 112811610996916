import React from 'react';
import { ThemeProvider, makeStyles } from '@mui/styles';
import {createMuiTheme} from '@mui/material/styles';

import { connect } from 'react-redux';

import './App.css';
import {
  Switch,
  BrowserRouter
} from "react-router-dom";

import  styled, { createGlobalStyle } from 'styled-components';

import SearchForm from './components/SearchForm';
import ShowResult from './components/ShowResult';
import ShowSavedResult from './components/ShowSavedResult';

import LoginPage from  './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import ChooseYourStyle from './components/ChooseYourStyle'
import ChooseBathtubShower from './components/ChooseBathtubShower';

import { addTodo } from './redux/actions';
import ShowMoodboardInternal from "./components/ShowMoodboardInternal";
import LoginLayout from "./components/LoignLayout";
import DefaultLayout from "./components/DefaultLayout";
import PdfLayout from  "./components/PdfLayout";
import PdfOffer from "./components/PdfOffer";
import AppLayout from "./components/AppLayout";
import NoLayout from './components/NoLayout';
import ProjectsPage from "./components/ProjectsPage";

const theme = createMuiTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that access to theme
  }
});

const GlobalStyle = createGlobalStyle`
  // @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700,900&display=swap');
  html {
    font-size: 62.5%;
    height: 100%;
  }

  body{
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    // min-width: 100vw;
    // overflow: hidden;
  }
    
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  #root {
    height: 100%; 
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  height: 100%;
`;

function App() {
  const classes = useStyles(); // ❌ If you have this, consider moving it inside a component that wrapped with <ThemeProvider>
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <BrowserRouter>
        <Container>
          <Switch>
            <LoginLayout component={LoginPage} path="/login"/>
            <LoginLayout component={SignUpPage} path="/signup" />
            <NoLayout component={PdfOffer} path="/results/:id/pdf" />
            <DefaultLayout component={ShowSavedResult} path="/results/:id"/>
            <DefaultLayout component={SearchForm} path="/search" />
            <DefaultLayout component={ShowMoodboardInternal} path="/results/:id/internal" />
            <DefaultLayout component={ShowResult} path="/results" />
            <AppLayout component={ProjectsPage} path="/app" />
            <DefaultLayout component={ChooseYourStyle} path="/step2" />
            <DefaultLayout component={ChooseBathtubShower} path="/" />
          </Switch>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  )
}

function mapStateToProps(state: {todos: any}) {
  const {todos} = state;
  return { counter: todos.counter  };
}

const mapDispatchToProps = { addTodo }

// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
