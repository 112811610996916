import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin:0;
        font-size: 10px;
        margin: 2px 4px;
        text-align: right;
    }
`;

/* ContainerSetImg - duze zdjecie po prawej stronie zestawow (strony 14/15/16)
    przyjmuje arg:
    picture - zdjecie
    width - szerokosc zdjecia
    height - wysokosc zdjecia
    alt - alt zdjecia
    text - text pod zdjeciem
*/

const ContainerSetImg = ({ picture, width, height, alt, text} = {}) => {
    return (
        <Wrapper>
            <img src={picture} width={width} height={height} alt={alt}/>
            {text ? <p>{text}</p> :''}
        </Wrapper>
    )
}


export default ContainerSetImg;
