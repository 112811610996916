import {GO_TO_NEXT_STEP, GO_TO_PREV_STEP, USER_FORM_CLEAR, UserForm, UserFormActionType} from "../types/userFormTypes";

// export const fetchSavedResultInProgress = (): SearchActionType => {
//   return {
//     type: FETCH_SAVED_RESULT_IN_PROGRESS,
//     // payload: {},
//   }
// };
//
// const fetchSavedResultDone = ({ id, params, products }: {id: number, params: object, products: Array<object>}): SearchResultDoneAction => {
//   return {
//     type: FETCH_SAVED_RESULT_DONE,
//     payload: {
//       id,
//       params,
//       products,
//     }
//   }
// };

export const goToNextStep = ({ userForm }: {userForm: UserForm}): UserFormActionType => (
  {
    type: GO_TO_NEXT_STEP,
    payload: {
      userForm
    }
  }
);

export const goToPrevStep = ({ userForm }: {userForm: UserForm}): UserFormActionType => (
  {
    type: GO_TO_PREV_STEP,
    payload: {
      userForm
    }
  }
);

export const userFormClear = (): UserFormActionType => (
  {
    type: USER_FORM_CLEAR,
    payload: {},
  }
)
