import React from 'react';
import styled from 'styled-components';

import lockedIcon from './TADOM_LOCKED.png';
import unlockedIcon from './TADOM_UNLOCKED.png';
import rotateIcon from './TADOM_ROTATE.png'

const TooltipBox = styled.div`
  position: absolute;
  border: 1px solid #000;
  right: -6px;
  top: -10px;
  // opacity: 0;
  display: none;
  transition: 0.2s ease;
  z-index: 999990999;

  div {
    width: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-end;
    background-color: #fff;
    p {
      padding-bottom: 0;
      margin-right: 12px;
      width: 100%;
    }
    div {
      width: 31px;
      border-left: 1px solid #000;
      span:hover {
        cursor: pointer;  
      }
      img {
        max-width: 30px;
        // max-height: 19px;
        vertical-align: center;
      }
      
      &:first-of-type {
        margin-right: 3px;  
      } 
      
      &.locked {
        background-color: #FE4E00; 
      }
    }
  }   
  p {
    margin: 0;
    padding: 6px;
    font-size: 12px;
    background: #fff;
    text-align: left;
    // img {
    //   width: 12px;
    //   height: 12px;
    // }
  }

  p:last-child {
    background: #000;
    color: #fff;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  position: relative;

  &:hover ${TooltipBox} {
    // opacity: 1;
    display: block;
  }
`;

/*
Tooltip - przyjmuje children czyli komponent ktory zawiera jakies zdjecie i go obwijamy
description - nazwa danego produktu przy ktorym ma sie wyswietlac
price - cena produktu
*/

const Tooltip = ({ children, description, price, type, locked, toggleProductTypeToKeep, refreshProduct } = {}) => {
  const renderModifyToolbar = locked !== undefined;
  return (
    <Wrapper>
      <TooltipBox>
        <div>
          <p>{description}
          </p>
          {renderModifyToolbar && !!refreshProduct ?
            <div>
              <span onClick={() => refreshProduct(type)}>
                <img src={rotateIcon} alt="rotate"/>
              </span>
            </div>
            : <div></div>
          }
          {renderModifyToolbar ?
            <div className={locked && 'locked'}>
              <span onClick={() => toggleProductTypeToKeep(type)}>
                <img src={locked ? lockedIcon : unlockedIcon} alt="locked"/>
              </span>
            </div>
            : <div></div>
          }
        </div>
        <p>{price} zł</p>
      </TooltipBox>
      {children}
    </Wrapper>
  );
};

export default Tooltip;
