import _ from 'lodash';
import {
  PROJECT_CLEAR_NEW_PROJECT_DATA,
  PROJECT_CREATE_NEW_ERROR,
  PROJECT_CREATE_NEW_START,
  PROJECT_CREATE_NEW_SUCCESS,
  PROJECT_GET_ALL_SUCCESS,
  PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS,
  PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS,
  PROJECT_GET_ONE_ERROR,
  PROJECT_GET_ONE_START,
  PROJECT_GET_ONE_SUCCESS,
  PROJECT_UPDATE_MOODBOARD_NO_RESULT,
  PROJECT_UPDATE_ONE_IN_PROGRESS,
  ProjectActionType
} from "../types/projectTypes";
import {USER_FORM_CLEAR, UserFormActionType} from "../types/userFormTypes";


export interface ProjectState {
  createInProgress: boolean;
  updateMoodboardNoResult: boolean;
  newProjectCreated: boolean;
  newProjectResponse: GetProjectResponse | null;
  projectResponsesById: Record<string, GetProjectResponse>;
  getOneErrorResponse: Record<string, ErrorResponse>;
  // projectResponsesById: Record<string, GetProjectResponse>;
  createErrorResponse: null | ErrorResponse;
  getNewProjectId: () => null | string;
  getOneProjectInProgress: Record<string, boolean>;
  updateOneProjectInProgress: Record<string, boolean>;
  getProjectProducts: (id: string) => DbProduct[] | null;
  getProjectMoodboardParams: (id: string) => DbMoodboardParams | undefined;
  getProjectMoodboardVisualizationImageUrl: (id: string) => string | undefined | null;
  getProjectMoodboardUserQuery: (id: string) => DbMoodboardUserQuery | undefined;
  isRequestForOneProjectInProgress: (id: string) => boolean;
  getProjects: () => Project[],
  getProjectsInProgress: boolean;
  getProjectsError: null | ErrorResponse;
  projects: Project[];
  bathroomVisualizationInProgress: boolean;
  bathroomVisualizations: null | BathroomVisualization[];
  bathroomVisualizationsError: null | ErrorResponse;
  shouldBathroomVisualizationBeFetched: () => boolean;
  getBathroomVisualizations: ({bathtub, shower}: {bathtub: boolean, shower: boolean}) => BathroomVisualization[];
  // getProjectsIn
  // searchResultProductsById: {[id: string]: Array<DbProduct>};
  // searchResultParamsById: {[id: string]: object};
  // searchResultVisualizationImageUrlById: {[id: string]: string};
  // productTypesToKeep: Array<string>;
  // productTypesToKeepInitialized: boolean;
};

const initialState: ProjectState = {
  createInProgress: false,
  updateMoodboardNoResult: false,
  projectResponsesById: {},
  createErrorResponse: null,
  newProjectCreated: false,
  newProjectResponse: null,
  getOneProjectInProgress: {},
  updateOneProjectInProgress: {},
  getOneErrorResponse: {},
  getProjectsInProgress: false,
  projects: [],
  getProjectsError: null,
  bathroomVisualizationInProgress: false,
  bathroomVisualizations: null,
  bathroomVisualizationsError: null,
  getNewProjectId() {
    if (!this.newProjectResponse) {
      return null;
    }
    return this.newProjectResponse.data.id;
  },

  getProjectProducts(id) {
    const projectResponse = this.projectResponsesById[id];
    if (!projectResponse) {
      return null;
    }
    return projectResponse.data.moodboard.products;
  },

  getProjectMoodboardParams(id) {
    return this.projectResponsesById[id]?.data.moodboard.params;
  },

  getProjectMoodboardVisualizationImageUrl(id: string) {
    return this.projectResponsesById[id]?.data.moodboard.visualization_image_url;
  },

  getProjectMoodboardUserQuery(id) {
    return this.projectResponsesById[id]?.data.moodboard.user_query;
  },

  isRequestForOneProjectInProgress(id: string) {
    return this.updateOneProjectInProgress[id] || this.getOneProjectInProgress[id];
  },

  getProjects() {
    return this.projects;
  },

  shouldBathroomVisualizationBeFetched() {
    return this.bathroomVisualizations === null && this.bathroomVisualizationInProgress === false;
  },

  getBathroomVisualizations({bathtub, shower}) {
    if (this.bathroomVisualizations === null) {
      return [];
    }

    const visualizations = this.bathroomVisualizations.filter((visualization) => {
      return visualization.shower === shower && visualization.bathtub === bathtub;
    });
    const visualizationsByBathroomType = _.groupBy(visualizations, 'bathroom_type');
    const visualizationsToReturn: BathroomVisualization[] = [];
    for(let i = 1; i <= 12; i++) {
      const visualization = _.sample(visualizationsByBathroomType[i]);
      if (visualization) {
        visualizationsToReturn.push(visualization);
      }
    }

    return visualizationsToReturn;
  }
};

export default function(state: ProjectState = initialState, action: ProjectActionType | UserFormActionType) {
  switch (action.type) {
    case PROJECT_CREATE_NEW_START:
      return {
        ...state,
        createInProgress: true,
      }
    case PROJECT_CREATE_NEW_SUCCESS:
      return {
        ...state,
        createInProgress: false,
        newProjectCreated: true,
        newProjectResponse: action.payload,
        projectResponsesById: {
          ...state.projectResponsesById,
          [action.payload.data.id]: action.payload,
        },
      }
    case PROJECT_CREATE_NEW_ERROR:
      return {
        ...state,
        createInProgress: false,
        createErrorResponse: action.payload,
      }

    case PROJECT_GET_ONE_START:
      return {
        ...state,
        getOneProjectInProgress: {
          ...state.getOneProjectInProgress,
          [action.payload.id]: true,
        }
      }

    case PROJECT_GET_ONE_SUCCESS:
      delete state.getOneProjectInProgress[action.payload.data.id];
      delete state.getOneErrorResponse[action.payload.data.id];
      delete state.updateOneProjectInProgress[action.payload.data.id];
      return {
        ...state,
        updateMoodboardNoResult: false,
        projectResponsesById: {
          ...state.projectResponsesById,
          [action.payload.data.id]: action.payload,
        },
      }

    case PROJECT_GET_ONE_ERROR:
      delete state.getOneProjectInProgress[action.payload.id];
      return {
        ...state,
        getOneErrorResponse: {
          ...state.getOneErrorResponse,
          [action.payload.id]: action.payload,
        },
      }
    case PROJECT_UPDATE_ONE_IN_PROGRESS:
      return {
        ...state,
        updateOneProjectInProgress: {
          ...state.updateOneProjectInProgress,
          [action.payload.id]: true,
        }
      }

    case PROJECT_GET_ALL_SUCCESS:
      return {
          ...state,
          projects: action.payload.data,
      };

    case PROJECT_CLEAR_NEW_PROJECT_DATA:
    case USER_FORM_CLEAR:
      return {
        ...state,
        newProjectCreated: false,
        newProjectResponse: null,
      }

    case PROJECT_UPDATE_MOODBOARD_NO_RESULT:
      delete state.getOneProjectInProgress[action.payload.id];
      delete state.getOneErrorResponse[action.payload.id];
      delete state.updateOneProjectInProgress[action.payload.id];
      return {
        ...state,
        updateMoodboardNoResult: true,
      }

    case PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS:
      return {
        ...state,
        bathroomVisualizationInProgress: true,
      }

    case PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS:
      return {
        ...state,
        bathroomVisualizationsError: null,
        bathroomVisualizationInProgress: null,
        bathroomVisualizations: action.payload.bathroomVisualizations,
      }
    default:
      return state;
  }
}
