import {
  CHANGE_PRODUCT_TYPES_TO_KEEP,
  FETCH_SAVED_RESULT_DONE,
  FETCH_SAVED_RESULT_IN_PROGRESS,
  SearchActionType,
  TOGGLE_PRODUCT_TYPES_TO_KEEP
} from "../types/serchResultTypes";

export interface State {
  inProgress: boolean;
  searchResultProductsById: {[id: string]: Array<DbProduct>};
  searchResultParamsById: {[id: string]: object};
  searchResultVisualizationImageUrlById: {[id: string]: string};
  productTypesToKeep: Array<string>;
  productTypesToKeepInitialized: boolean;
};

const initialState = {
  searchResultProductsById: {},
  searchResultParamsById: {},
  searchResultVisualizationImageUrlById: {},
  inProgress: false,
  productTypesToKeep: [],
  productTypesToKeepInitialized: false,
};

export default function(state: State = initialState, action: SearchActionType) {
  const nextState = {...state};
  // if (action.type === FETCH_SAVED_RESULT_IN_PROGRESS) {
  //   nextState.inProgress = true;
  //   return nextState;
  // } else if (action.type === FETCH_SAVED_RESULT_DONE) {
  //   nextState.inProgress = false;
  //
  //   nextState.searchResultProductsById[action.payload.id] = action.payload.products;
  //   nextState.searchResultParamsById[action.payload.id] = action.payload.params;
  //   return nextState;
  // }

  // return nextState;
  switch (action.type) {
    case FETCH_SAVED_RESULT_IN_PROGRESS:
      nextState.inProgress = true;
      return nextState;

    case FETCH_SAVED_RESULT_DONE:
      nextState.inProgress = false;
      nextState.searchResultProductsById[action.payload.id] = action.payload.products as Array<DbProduct>;
      nextState.searchResultParamsById[action.payload.id] = action.payload.params;
      nextState.searchResultVisualizationImageUrlById[action.payload.id] = action.payload.visualization_image_url;
      if (!nextState.productTypesToKeepInitialized) {
        nextState.productTypesToKeep = nextState.searchResultProductsById[action.payload.id].map(product => product.type);
        nextState.productTypesToKeepInitialized = true;
      }
      // nextState.se
      return nextState;

    // case FETCH_RESULTS_DONE: {
    //   return {
    //     ...state,
    //     inProgress: false,
    //     redirectToShowProducts: true,
    //     params: action.payload.params,
    //     products: action.payload.body && action.payload.body.data && action.payload.body.data.products,
    //     visualizationImageUrl: action.payload.body && action.payload.body.data && action.payload.body.data.visualization_image_url,
    //     dataFetched: true,
    //     meta: action.payload.body && action.payload.body.meta,
    //   };
    // };

    case TOGGLE_PRODUCT_TYPES_TO_KEEP:
      const nextProductTypesToKeep = [...nextState.productTypesToKeep];
      if (nextProductTypesToKeep.includes(action.payload.productType)) {
        nextProductTypesToKeep.splice(
          nextProductTypesToKeep.indexOf(action.payload.productType),
          1
        );
      } else {
        nextProductTypesToKeep.push(action.payload.productType);
      }
      return {
        ...nextState,
        productTypesToKeep: nextProductTypesToKeep,
      };

    case CHANGE_PRODUCT_TYPES_TO_KEEP:
      return {
        ...nextState,
        productTypesToKeep: action.payload.productTypes,
      }
    default:
      return state;
  }
}
