import React from 'react';
import Tooltip from '../Tooltip';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ type }) => (type === 'set2' && '72px 132px 105px 90px') || (type ==='set3' && '110px 85px 94px 114px 80px') || '100px 132px 120px'};
    grid-template-rows: ${({ type }) => (type === 'set2' && '88px 60px 134px 128px') || (type ==='set3' && '82px 98px 118px 70px') || '90px 78px 134px 110px'};
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    

    .div1 { grid-area: ${({ type }) => (type === 'set2' && '1 / 2 / 2 / 3') || (type ==='set3' && '1 / 1 / 3 / 2') || '1 / 1 / 2 / 2'}; }
    .div2 { grid-area: ${({ type }) => (type === 'set2' && '1 / 3 / 2 / 4') || (type ==='set3' && '1 / 2 / 3 / 3') || '1 / 2 / 2 / 3'}; }
    .div3 { grid-area: ${({ type }) => (type === 'set2' && '1 / 4 / 2 / 5') || (type ==='set3' && '1 / 3 / 3 / 4') || '1 / 3 / 2 / 4'}; }
    .div4 { grid-area: ${({ type }) => (type === 'set2' && '2 / 1 / 4 / 2') || (type ==='set3' && '1 / 4 / 2 / 5') || '2 / 1 / 3 / 2'}; }
    .div5 { grid-area: ${({ type }) => (type === 'set2' && '2 / 2 / 4 / 3') || (type ==='set3' && '1 / 5 / 2 / 6') || '2 / 2 / 3 / 3'}; }
    .div6 { grid-area: ${({ type }) => (type === 'set2' && '2 / 3 / 4 / 4') || (type ==='set3' && '2 / 4 / 3 / 5') || '2 / 3 / 4 / 4'}; }
    .div7 { grid-area: ${({ type }) => (type === 'set2' && '2 / 4 / 3 / 5') || (type ==='set3' && '2 / 5 / 3 / 6') || '3 / 2 / 4 / 3'}; }
    .div8 { grid-area: ${({ type }) => (type === 'set2' && '3 / 4 / 4 / 5') || (type ==='set3' && '3 / 1 / 4 / 2') || '3 / 1 / 4 / 2'}; }
    .div9 { grid-area: ${({ type }) => (type === 'set2' && '4 / 2 / 5 / 3') || (type ==='set3' && '3 / 2 / 5 / 3') || '4 / 1 / 5 / 3'}; }
    .div10 { grid-area:${({ type }) => (type === 'set2' && '4 / 3 / 5 / 4') || (type ==='set3' && '3 / 3 / 4 / 4') || '4 / 3 / 5 / 4'}; }
    .div11 { grid-area:${({ type }) => (type === 'set2' && '4 / 4 / 5 / 5') || (type ==='set3' && '3 / 4 / 4 / 5') || '1'}; }
    .div12 { grid-area:${({ type }) => (type === 'set2' && '0') || (type ==='set3' && '4 / 1 / 5 / 2') || '0'}; }
    .div13 { grid-area:${({ type }) => (type === 'set2' && '0') || (type ==='set3' && '4 / 3 / 5 / 5') || '0'}; }
    
    img {
      object-fit: contain;
    }
`;
/*
    ContainerSetItem - jest to siatka z zestawami, zestawy posiadaja 3 siatki w zaleznosci od strony
    zestaw 1 - nie trzeba podawac typu
    zestaw 2 - trzeba podac type = set2
    zestaw 3 - type = set3
    kolejny argument to items - items sa deklarowane w setpage1,setpage2,setpage 3
*/

const ContainerSetItem = ({ type, items , toggleProductTypeToKeep, refreshProduct} = {}) => {
  return (
    <Wrapper type={type}>
      {items.map((item,index) => (
        <div key={index} className={`div${index+1}`}>
          <Tooltip
            description={item.text}
            price={item.price}
            locked={item.locked}
            type={item.type}
            toggleProductTypeToKeep={toggleProductTypeToKeep}
            refreshProduct={refreshProduct}
          >
            <img width={item.width} height={item.height} src={item.img} alt={item.text}/>
          </Tooltip>
        </div>))}
    </Wrapper>
  )
};

export default ContainerSetItem;
