export const PROJECT_CREATE_NEW_START = "PROJECT_CREATE_NEW_START";
export const PROJECT_CREATE_NEW_SUCCESS = "PROJECT_CREATE_NEW_SUCCESS";
export const PROJECT_CREATE_NEW_ERROR = "PROJECT_CREATE_NEW_ERROR";
export const PROJECT_GET_ONE_START = "PROJECT_GET_ONE_START";
export const PROJECT_GET_ONE_SUCCESS = "PROJECT_GET_ONE_SUCCESS";
export const PROJECT_GET_ONE_ERROR = "PROJECT_GET_ONE_ERROR";
export const PROJECT_UPDATE_ONE_IN_PROGRESS = "PROJECT_UPDATE_ONE_IN_PROGRESS";
export const PROJECT_UPDATE_MOODBOARD_NO_RESULT = "PROJECT_UPDATE_MOODBOARD_NO_RESULT";
export const PROJECT_GET_ALL_START = "PROJECT_GET_ALL_START";
export const PROJECT_GET_ALL_SUCCESS = "PROJECT_GET_ALL_SUCCESS";
export const PROJECT_GET_ALL_ERROR = "PROJECT_GET_ALL_ERROR";
export const PROJECT_CLEAR_NEW_PROJECT_DATA = 'PROJECT_CLEAR_NEW_PROJECT_DATA';
export const PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS = 'PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS';
export const PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS = 'PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS';
export const PROJECT_GET_BATHROOM_VISUALIZATIONS_ERROR = 'PROJECT_GET_BATHROOM_VISUALIZATIONS_ERROR';

interface Action { }

export interface ProjectCreateNewStartAction extends Action {
  type: typeof PROJECT_CREATE_NEW_START,
  payload: {
  }
}

export interface ProjectGetOneStartAction extends Action {
  type: typeof PROJECT_GET_ONE_START,
  payload: {
    id: string
  }
}

export interface ProjectCreateNewSuccessAction extends Action {
  type: typeof PROJECT_CREATE_NEW_SUCCESS
  payload: {
    data: DbMoodboardWithProducts,
  }
  // payload: {
  //   data: DbM
  // }
}

export interface ProjectGetOneSuccessAction extends Action{
  type: typeof PROJECT_GET_ONE_SUCCESS,
  payload: {
    data: DbMoodboardWithProducts,
  }
}

export interface ProjectCreateNewErrorAction extends Action {
  type: typeof PROJECT_CREATE_NEW_ERROR
  payload: {
    status?: number,
    body?: any,
    error?: Error,
  }
}

export interface ProjectGetOneErrorAction extends Action {
  type: typeof PROJECT_GET_ONE_ERROR
  payload: {
    status?: number,
    body?: any,
    error?: Error,
    id: string,
  }
}

export interface ProjectUpdateOneInProgress extends Action{
  type: typeof PROJECT_UPDATE_ONE_IN_PROGRESS,
  payload: {
    id: string,
  }
}

export interface ProjectGetAllInProgress extends  Action{
  type: typeof PROJECT_GET_ALL_START,
  payload: any,
}

export interface ProjectGetAllSuccess extends  Action{
  type: typeof PROJECT_GET_ALL_SUCCESS,
  payload: {
    data: Project[],
  },
}

export interface ProjectGetAllError extends Action {
  type: typeof PROJECT_GET_ALL_ERROR,
  payload: {
    status?: number,
    body?: any,
    error?: Error,
  },
}

export interface ProjectUpdateMoodboardNoResult extends Action {
  type: typeof PROJECT_UPDATE_MOODBOARD_NO_RESULT,
  payload: {
    id: string,
  }
}

export interface ProjectClearNewData  extends Action {
  type: typeof PROJECT_CLEAR_NEW_PROJECT_DATA,
  payload: {
  }
}

export interface ProjectGetBathroomVisualizationInProgress extends Action {
  type: typeof PROJECT_GET_BATHROOM_VISUALIZATIONS_IN_PROGRESS,
  payload: {

  }
}

export interface ProjectGetBathroomVisualizationSuccess extends Action {
  type: typeof PROJECT_GET_BATHROOM_VISUALIZATIONS_SUCCESS,
  payload: {
    bathroomVisualizations: BathroomVisualization[],
  }
}

export interface ProjectGetBathroomVisualizationError extends Action {
  type: typeof PROJECT_GET_BATHROOM_VISUALIZATIONS_ERROR
  payload: {

  }
}

export type ProjectActionType = ProjectCreateNewStartAction
  | ProjectCreateNewSuccessAction
  | ProjectCreateNewErrorAction
  | ProjectGetOneStartAction
  | ProjectGetOneSuccessAction
  | ProjectGetOneErrorAction
  | ProjectUpdateOneInProgress
  | ProjectGetAllInProgress
  | ProjectGetAllSuccess
  | ProjectGetAllError
  | ProjectUpdateMoodboardNoResult
  | ProjectClearNewData
  | ProjectGetBathroomVisualizationInProgress
  | ProjectGetBathroomVisualizationSuccess
  | ProjectGetBathroomVisualizationError;
