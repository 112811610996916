import React from 'react';
import ContainerSetItem from '../ContainerSetItem';

import wall1 from '../../assets/img/wall1_1.png';
import wall2 from '../../assets/img/wall1_2.png';
import wall3 from '../../assets/img/wal1_3.png';
import img1 from '../../assets/img/set1_1.png';
import img2 from '../../assets/img/set1_2.png';
import img3 from '../../assets/img/set1_3.png';
import img4 from '../../assets/img/set1_4.png';
import img5 from '../../assets/img/set1_5.png';
import img6 from '../../assets/img/set1_6.png';
import img8 from '../../assets/img/set1_7.png';
import img7 from '../../assets/img/set1_8.png';
import img9 from '../../assets/img/set1_9.png';
import img10 from '../../assets/img/set1_10.png';

const productPositionByType: {[key: string]: any} = {
  washbasin_faucet: {img: img1, text: 'img1', price: 1230, width: '73px', height: '73px'},
  bathtub_faucet: {img:img2, text:'img1', price:1230, width:'89px', height:'89px'},
  ceil_lighting: {img:img3, text:'img1', price:1230, width:'89px', height:'89px'},
  wall_lighting: {img:img4, text:'img1', price:1230, width:'90px', height:'75px'},
  washbasin: {img:img5, text:'img1', price:1230, width:'132px', height:'76px'},
  heater: {img:img6, text:'img1', price:1230, width:'104px', height:'191px'},
  mirror: {img:img7, text:'img1', price:1230, width:'132px', height:'132px'},
  washbasin_cabinet: {img:img8, text:'img1', price:1230, width:'84px', height:'134px'},
  bathtub: {img:img9, text:'img1', price:1230, width:'233px', height:'100px'},
  toilet: {img:img10, text:'img1', price:1230, width:'94px', height:'109px'},
  dry_wall: {img:wall1,text:'wall1',price:1234,width:'169px', height:'277px;'},
  wet_wall: {img:wall2,text:'wall1',price:1234,width:'143px', height:'191px'},
  floor: {img:wall3,text:'wall1', price:1234, width:'139px', height:'190px'}
};

interface ShowResultBathtubNewParams {
  products: Array<any>;
  toggleProductTypeToKeep?: (a: string) => any;
  refreshProduct?: (a: string) => any;
}


const ShowResultBathtubNew = ({products, toggleProductTypeToKeep, refreshProduct}: ShowResultBathtubNewParams) => {
  const notBigSurfaceProductsOrder: Array<string> = ['washbasin_faucet', 'bathtub_faucet', 'ceil_lighting', 'wall_lighting',
    'washbasin', 'heater', 'mirror', 'washbasin_cabinet', 'bathtub', 'toilet'
  ];

  const productsByType: {[key: string]: {}} = {};
  products.forEach(product => {
    productsByType[product.type] = product;
  });

  let notBigSurfaceProducts: Array<any> = notBigSurfaceProductsOrder.map(productType => {
    const product: any = productsByType[productType];
    if (!product) {
      return null;
    }
    return Object.assign({}, productPositionByType[productType], {
      img: product.image_url,
      price: product.price,
      text: `${product.manufacturer && product.manufacturer} ${product.series && product.series}`,
      locked: product.locked,
      type: product.type,
    });
  });

  notBigSurfaceProducts = notBigSurfaceProducts.filter(product => !!product);
  return (
    <ContainerSetItem
      items={notBigSurfaceProducts}
      type=""
      toggleProductTypeToKeep={toggleProductTypeToKeep}
      refreshProduct={refreshProduct}
    />
  );
};

export default ShowResultBathtubNew;
